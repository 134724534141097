<template>
    <div>
        <!-- 头部导航 -->
        <nav-header></nav-header>
        <div v-if="rotationList.length > 0" class="swiper"
            :style="{ 'background-image': 'url(' + 'https://api.hi-wooya.com/storage/' + rotationList[0]['img'] + ')' }">
            <!-- 轮播图中间内容 -->
            <div class="swiper-content">欢迎使用无涯服务支持</div>
            <div class="swiper-btn">
                <input class="btn-input" v-model="search_value" type="text" placeholder="请输入搜索内容">
                <div @click="openpages('/Search', search_value)" class="btn"><i class="iconfont icon-sousuo1 i-size"></i>
                </div>
            </div>
        </div>
        <div class="content-box">
            <div class="title">
                首页 > 资料下载
            </div>
            <div class="box2">
                请按产品分类或具体产品型号，查找您所需要的信息
            </div>
            <div class="box3">
                <div class="box3-title">
                    按产品分类选择：
                </div>
                <!-- 第一级 -->
                <select class="box3-content" @change="selChange">
                    <option selected>请选择</option>
                    <option v-for="item in productList" :key="item.title" :value="item.id">{{ item.title }}
                    </option>
                </select>
                <!-- 第二级 -->
                <select class="box3-content" @change="selChange2">
                    <option selected>请选择</option>
                    <option v-for="item in productList2" :key="item.title" :value="item.id">{{ item.title }}
                    </option>
                </select>
                <!-- 第三级 -->
                <select class="box3-content" @change="selChange3">
                    <option selected>请选择</option>
                    <option v-for="item in productList3" :key="item.title" :value="item.id">{{ item.model }}
                    </option>
                </select>

                <div style="margin-left: 280px;" class="box3-title">
                    按产品型号搜索：
                </div>
                <div style="display: flex;">
                    <input type="text" v-model="search_value" style="border: 1px solid #dcdcdc;" class="btn-input2"
                        placeholder="请输入产品型号">
                    <div @click="openpages('/Search', search_value)" class="btn2"><i
                            class="iconfont icon-sousuo1 i-size"></i></div>
                </div>
            </div>
            <!-- 相关产品内容 -->
            <div v-if="dataDownloadList.length >= 1">
                <div class="box2">
                    相关产品
                </div>
                <div style="display: flex;">
                    <img :src="'https://api.hi-wooya.com/storage/' + detailInfo.img_cover" class="img">
                    <div class=" ">
                        <div class="product-title">
                            {{ detailInfo.title }} {{ detailInfo.classify_title }}
                        </div>
                        <div class="desc">
                            {{ detailInfo.desc }}
                        </div>
                    </div>
                </div>
            </div>


            <div class="box2">
                您可能要下载的资料：
            </div>
            <!-- 全部内容 -->
            <div v-if="dataDownloadList.length < 1" class="download-box">
                <div v-for="(item, index) in allDataDownloadList" :key="index" class="download"
                    :style="{ backgroundColor: isEven(index) ? '' : '#FCFCFC' }">
                    <div style="display: flex;align-items: center;flex: 1;">
                        <img class="download-img" v-if="item.title.endsWith('f')" src="../assets/img/Pdf.png" alt="">
                        <img class="download-img" v-else src="../assets/img/Zip.png">
                        <div>{{ item.title }}</div>
                    </div>
                    <div style="color: #7b7b7b;flex: 1;"> {{ deteFormat(item.create_time) }}</div>
                    <div style="color: #7b7b7b" class="downclick" @click="download(item.download_url)">
                        <i class="iconfont icon-xiazai"></i>
                        点击下载
                    </div>
                </div>
            </div>
            <!-- 相关产品内容 -->
            <div v-if="dataDownloadList.length >= 1" class="download-box">
                <div v-for="(item, index) in dataDownloadList" :key="index" class="download"
                    :style="{ backgroundColor: isEven(index) ? '' : '#FCFCFC' }">
                    <div style="display: flex;align-items: center;flex: 1;">
                        <img class="download-img" v-if="item.title.endsWith('f')" src="../assets/img/Pdf.png" alt="">
                        <img class="download-img" v-else src="../assets/img/Zip.png">
                        <div>{{ item.title }}</div>
                    </div>
                    <div style="color: #7b7b7b;flex: 1;"> {{ deteFormat(item.create_time) }}</div>
                    <div style="color: #7b7b7b" class="downclick" @click="download(item.download_url)">
                        <i class="iconfont icon-xiazai"></i>
                        点击下载
                    </div>
                </div>
            </div>
        </div>
        <!-- 页脚 -->
        <nav-footer></nav-footer>
    </div>
</template>

<script>

export default {
    data() {
        return {
            // 轮播图
            rotationList: [],
            // 下载数据分类列表
            dataDownloadList: [],
            allDataDownloadList: [],
            // 产品分类
            productList: [],
            productList2: [],
            productList3: [],
            // 搜索
            model: '',
            // 具体产品
            detailInfo: [],
            search_value: ''
        }
    },
    created() {
        this.getswiper()
        this.getproductclassification()
        this.getAllDownLoad()
    },
    methods: {
        //下载内容
        download(e) {
            window.open('https://api.hi-wooya.com/storage/' + e, '_self')
        },
        //判断双数
        isEven(index) {
            return index % 2 === 0;
        },
        //跳转页面
        openpages(path, content) {
            this.$js.openpages(path, content);
        },
        // 获取轮播图
        async getswiper() {
            let res = await this.$apiFun.rotationList({
                classify_id: 7
            });
            this.rotationList = res.result;
        },
        //更改选择框
        selChange(e) {
            this.getSubProductClassifyList(e.target.value)
        },
        selChange2(e) {
            this.getproduct(e.target.value)
        },
        selChange3(e) {
            this.getDownLoad(e.target.value)
        },
        async getproductclassification() {
            //获取产品分类
            let res = await this.$apiFun.productClassifyList({});
            this.productList = res.result;
        },
        //得到子类
        async getSubProductClassifyList(e) {
            //获取产品分类
            let res = await this.$apiFun.productClassifyList({ parent: e });
            this.productList2 = res.result;
        },
        async getproduct(classify_id) {
            //获取产品
            let res = await this.$apiFun.productList({
                classify_id: classify_id
            });
            this.productList3 = res.result.data;
        },
        // 获取下载数据分类列表
        async getDownLoad(id) {
            let res = await this.$apiFun.dataDownloadList({
                classify_id: id
            });
            this.dataDownloadList = res.result;
            let res1 = await this.$apiFun.productDetail({
                id: id
            });
            this.detailInfo = res1.result;
        },
        async getAllDownLoad() {
            let res = await this.$apiFun.allDataDownloadList({});
            this.allDataDownloadList = res.result;
        },
        //裁剪日期
        deteFormat(value) {
            return value.substring(0, 10);
        }
    }
}
</script>
<style scoped>
.swiper {
    width: 100%;
    height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.swiper-content {
    font-size: 32px;
    font-weight: bold;
    color: white;
    margin: 0 auto;
}

.swiper-btn {
    margin: 0 auto;
    display: flex;
}

.btn-input {
    width: 300px;
    height: 34px;
    padding: 0 0 0 10px;
    border: none;
    outline: none;
}

.i-size {
    font-size: 20px;
}

.btn-input2 {
    width: 300px;
    height: 25px;
    padding: 0 0 0 10px;
    border: none;
    outline: none;
}

.btn {
    border: none;
    height: 34px;
    width: 39px;
    color: white;
    background-color: #90c31d;
    cursor: pointer;
    text-align: center;
    line-height: 34px;
}

.btn2 {
    border: none;
    height: 27px;
    width: 32px;
    color: white;
    background-color: #90c31d;
    cursor: pointer;
    text-align: center;
    line-height: 27px;
    font-size: 17px;
}

.content-box {
    width: 1600px;
    margin: 80px auto;
}

.title {
    font-size: 15px;
    color: #7b7b7b;
}

.box2 {
    /* width: 100%; */
    margin-top: 40px;
    font-size: 19px;
    padding: 10px 0 10px 10px;
    font-weight: bold;
    background-color: #dcdcdc;
}

.box3 {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.box3-title {
    font-size: 18px;
    font-weight: bold;
}

.box3-content {
    width: 130px;
    border: 1px solid #dcdcdc;
}

.box3-content:focus {
    outline: none;
    border: 2px solid #012f55;
}

.download-box {
    width: 100%;
    height: 300px;
    margin: 40px 0 80px 0;
}

.download {
    width: 100%;
    height: 55px;
    line-height: 40px;
    flex-direction: row;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.downclick:hover {
    color: #90c31d;
}

.download-img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.img {
    width: 180px;
    height: 180px;
}

.product-title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
}

.desc {
    font-size: 18px;
    color: #7b7b7b;
    margin-top: 20px;
}</style>