<template>
  <div class="bg" style="background-color: #F8F8F8;">
    <!-- 头部导航 -->
    <nav-header></nav-header>
    <!-- 轮播图 -->
    <div v-if="rotationList.length > 0" class="swiper"
      :style="{ 'background-image': 'url(' + 'https://api.hi-wooya.com/storage/' + rotationList[0]['img'] + ')' }">
      <div class="swiper-title">
        新闻中心
      </div>
      <div class="swiper-content">
        汇集新品发布、无涯新闻、行业资讯、常见问题等多方动态
      </div>
      <div class="swiper-content">
        释放自己，想你所想，你简单，我快乐
      </div>
      <div class="newsClassifyList-box">
        <div class="content-box">
          <div style="display: flex;">
            <div class="newsClassifyList" :class="{ 'newsClassifyList-hover': navid == item.id }"
              @click="subItemClick(item.id, item.title)" v-for="item in newsClassifyList" :key="item.id">
              {{ item.title }}
            </div>
          </div>
          <div class="nav-box">
            <a href="/">首页</a>>新闻中心>{{ this.navname }}
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻列表 -->
    <div class="content-box2">
      <div @mouseleave="changeid2()" @mouseenter="changeid(item.id)" class="newslist-box" v-for="item in displayedBoxes"
        :key="item.id" @click="openpages('/NewsDetail', item.id, navname)">
        <div style="overflow: hidden;">
          <img class="news-img" :src="'https://api.hi-wooya.com/storage/' + item.img">
        </div>
        <div>
          <div class="news-title">
            {{ item.title }}
          </div>
          <div class="news-content">
            {{ richTextFormat(item.content) }}
          </div>
          <!-- <div @click="openpages('/NewsDetail', item.id)" class="news-btn">
            查看详情
          </div> -->
          <div class="border" :class="{ 'border2': tsid == 1 && navid2 == item.id }"></div>
        </div>
      </div>
    </div>

    <!-- 分页 -->
    <div class="pagination-box">
      <button class="pagination" @click="goToPreviousPage" :disabled="currentPage === 1">
        上一页
      </button>
      <span v-for="page in pages" :key="page">
        <button @mouseleave="changepage2()" @mouseenter="changepage(page)" @click="goToPage(page)" class="pagination2"
          :class="{ 'pagination-active': page === currentPage, 'pagination2-hover': page === pages2 }">{{ page
          }}</button>
      </span>
      <button class="pagination" @click="goToNextPage" :disabled="currentPage === totalPages">
        下一页
      </button>
    </div>
    <!-- 页脚 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navid: 229,
      navname: '行业资讯',
      // 底部边框id
      tsid: 0,
      // 轮播图
      rotationList: [],
      //新闻列表
      news_list: [],
      //新闻分类列表
      newsClassifyList: [],
      // 分页内容
      // 当前页数
      currentPage: 1,
      // 一页展示几个
      itemsPerPage: 12,
      pages2: 0,
      form: {
        list_rows: 99, //上个程序员遗留问题，这个是上个程序员的分页内容，在我这版实际的作用是提供后端所有新闻列表
      },
    }
  },
  created() {
    this.getswiper()
    this.navid = this.$route.query.id || 229;
    this.navname = this.$route.query.name || '行业资讯'
    this.getNewsList(this.$route.query.id)
    this.getclassifylist()
  },
  methods: {
    // 控制边框变化
    changeid(id) {
      this.navid2 = id
      this.tsid = 1
    },
    changeid2() {
      this.tsid = 0
    },
    openpages(path, id, type) {
      this.$js.openpages(path, id, type);
    },
    //切换新闻分类
    subItemClick(id, name) {
      this.navid = id
      this.navname = name
      this.getNewsList(id)
    },
    //转换富文本
    richTextFormat(value) {
      value = value.replace(/<\/?.+?>/g, '')
      value = value.replace(/\s+/g, '')
      if (value.length > 100) {
        return value.slice(0, 100) + "...";
      }
      return value;
    },
    // 获取轮播图
    async getswiper() {
      let res = await this.$apiFun.rotationList({
        classify_id: 5
      });
      this.rotationList = res.result;
    },
    // 获取新闻分类
    async getclassifylist() {
      let res = await this.$apiFun.newsClassifyList({
        classify_id: 5
      });
      this.newsClassifyList = res.result;
    },
    // 获取新闻列表
    async getNewsList(classify_id = 229) {
      this.form.classify_id = classify_id
      let res = await this.$apiFun.newsList(this.form);
      this.news_list = res.result.data;
      this.currentPage = 1
    },
    // 页数加
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    // 页数减
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    changepage(page) {
      this.pages2 = page
    },
    changepage2() {
      this.pages2 = 0
    },
  },
  computed: {
    // 分页内容
    totalPages() {
      return Math.ceil(this.news_list.length / this.itemsPerPage);
    },
    displayedBoxes() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.news_list.slice(startIndex, endIndex);
    },
    pages() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  watch: {
    '$route.query.id'(newValue, oldValue) {
      this.subItemClick(newValue)
      this.navname = this.$route.query.name
    }
  },
}
</script>

<style scoped>
a {
  color: white;
}

.swiper {
  position: relative;
  width: 100%;
  height: 442px;
  /* padding-left: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.swiper-title {
  margin-left: 300px;
  width: fit-content;
  font-size: 32px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

.swiper-content {
  margin-left: 300px;
  width: fit-content;
  font-size: 15px;
  color: white;
  margin-bottom: 10px;
}

.newsClassifyList-box {
  background-color: rgba(0, 0, 0, 0.337);
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
}

.content-box {
  width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}



.newsClassifyList {
  height: 100%;
  color: white;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  padding: 0 25px;
  cursor: pointer;
}

.nav-box {
  color: white;
  font-size: 18px;
  line-height: 60px;
  height: 100%;
  padding-right: 10px;
}

.newsClassifyList-hover {
  background-color: #90c31d;
}

.newsClassifyList:hover {
  background-color: #90c31d;
}

/* 大于1700时样式 */
@media screen and (min-width: 1700px) {
  .content-box2 {
    width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    /* gap: 30px; */
  }

  .newslist-box {
    position: relative;
    width: 377.5px;
    height: 370px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    background-color: white;
    cursor: pointer;
    margin-right: 30px;
    margin-bottom: 30px;
  }

  .newslist-box:nth-child(4n) {
    margin-right: 0;
  }

  .news-img {
    width: 377.5px;
    height: 210px;
    transition: all 0.3s;
  }

  .news-img:hover {
    transform: scale(1.1);
  }

  .news-title {
    margin: 20px 0 20px 20px;
    font-size: 15px;
    font-weight: bold;
  }

  .news-title:hover {
    color: #90c31d;
  }

  .news-content {
    font-size: 15px;
    width: 350px;
    margin: 20px 0 20px 20px;
    color: #7b7b7b;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .news-content:hover {
    color: #90c31d;
  }

  .border {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 0px;
    height: 0;
    border-bottom: 2px solid #90c31d;
    transition: 0.2s all linear;
  }

  .border2 {
    width: 100%;
  }

  .news-btn {
    width: 150px;
    height: 40px;
    background-color: #012F55;
    color: white;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    cursor: pointer;
    margin: 40px 0 0 20px;
  }

  .pagination-box {
    width: fit-content;
    margin: 20px auto;
  }
}

/* 小于1700时样式 */
@media screen and (max-width: 1700px) {

  .content-box2 {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    /* gap: 22.5px; */
  }

  .newslist-box {
    position: relative;
    width: 283.125px;
    height: 277.5px;
    margin-top: 22.5px;
    display: flex;
    flex-direction: column;
    background-color: white;
    cursor: pointer;
    margin-right: 22.5px;
    margin-bottom: 22.5px;
  }

  .newslist-box:nth-child(4n) {
    margin-right: 0;
  }

  .news-img {
    width: 283.125px;
    height: 157.5px;
    transition: all 0.3s;
  }

  .news-img:hover {
    transform: scale(1.1);
  }

  .news-title {
    margin: 15px 0 15px 15px;
    font-size: 11.25px;
    font-weight: bold;
  }

  .news-title:hover {
    color: #90c31d;
  }

  .news-content {
    font-size: 11.25px;
    width: 262.5px;
    margin: 15px 0 15px 15px;
    color: #7b7b7b;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .news-content:hover {
    color: #90c31d;
  }

  .border {
    position: absolute;
    bottom: -0.75px;
    left: 0px;
    width: 0px;
    height: 0;
    border-bottom: 1.5px solid #90c31d;
    transition: 0.2s all linear;
  }

  .border2 {
    width: 100%;
  }


  .pagination-box {
    width: fit-content;
    margin: 15px auto;
  }
}

@media screen and (max-width: 1200px) {
  .swiper {
    width: 1200px;
    background-position: center;
  }

  .bg {
    width: 1200px;
  }
}
</style>