import router from '../../router/index'


//设置个对象，就不用一个个暴露了，直接暴露对象
let method = {};


// 页面跳转
method.openpages = (path,id,type,name) => {
    router.push({
        path,
        query:{
            id:id,
            type:type,
            name:name
        },
    });
}


//暴露出这个对象
export default method;

