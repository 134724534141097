<template>
  <div>
    <!-- 头部导航 -->
    <nav-header></nav-header>
    <!-- 轮播图 -->
    <div v-if="rotationList.length > 0" class="swiper"
      :style="{ 'background-image': 'url(' + 'https://api.hi-wooya.com/storage/' + rotationList[0]['img'] + ')' }">
      <!-- 轮播图中间内容 -->
      <div class="swiper-content">欢迎使用无涯服务支持</div>
      <div class="swiper-btn">
        <input class="btn-input" v-model="search_value" type="text" placeholder="请输入搜索内容">
        <div @click="openpages('/Search', search_value)" class="btn"><i class="iconfont icon-sousuo1 i-size"></i></div>
      </div>
      <div class="nav-box">
        <div class="content-box3">
          <div style="display: flex;">
            <div class="nav" :class="{ 'nav-hover': navid == item.id }" @click="changeId(item.id, item.title)"
              v-for="item in nav_list" :key="item.id">
              {{ item.title }}
            </div>
          </div>
          <div class="nav-box3">
            <a href="/">首页</a>>服务支持>{{ this.navname }}
          </div>
        </div>
      </div>
    </div>
    <!-- 资料下载内容 -->
    <div v-if="navid == 1">
      <div class="content-box">
        <div class="box2">
          请按产品分类或具体产品型号，查找您所需要的信息
        </div>
        <div class="box3">
          <div class="box3-title">
            按产品分类选择：
          </div>
          <!-- 第一级 -->
          <select class="box3-content" @change="selChange">
            <option selected>请选择</option>
            <option v-for="item in productList" :key="item.title" :value="item.id">{{ item.title }}
            </option>
          </select>
          <!-- 第二级 -->
          <select class="box3-content" @change="selChange2">
            <option selected>请选择</option>
            <option v-for="item in productList2" :key="item.title" :value="item.id">{{ item.title }}
            </option>
          </select>
          <!-- 第三级 -->
          <select class="box3-content" @change="selChange3">
            <option selected>请选择</option>
            <option v-for="item in productList3" :key="item.title" :value="item.id">{{ item.model }}
            </option>
          </select>

          <div style="margin-left: 280px;" class="box3-title">
            按产品型号搜索：
          </div>
          <div style="display: flex;">
            <input type="text" v-model="search_value" style="border: 1px solid #dcdcdc;" class="btn-input2"
              placeholder="请输入产品型号">
            <div @click="openpages('/Search', search_value)" class="btn2"><i class="iconfont icon-sousuo1 i-size"></i>
            </div>
          </div>
        </div>
        <!-- 相关产品内容 -->
        <div v-if="dataDownloadList.length >= 1">
          <div class="box2">
            相关产品
          </div>
          <div style="display: flex;">
            <img :src="'https://api.hi-wooya.com/storage/' + detailInfo.img_cover" class="img">
            <div class=" ">
              <div class="product-title">
                {{ detailInfo.title }} {{ detailInfo.classify_title }}
              </div>
              <div class="desc">
                {{ detailInfo.desc }}
              </div>
            </div>
          </div>
        </div>


        <div class="box2">
          您可能要下载的资料：
        </div>
        <!-- 全部内容 -->
        <div v-if="dataDownloadList.length < 1" class="download-box">
          <div v-for="(item, index) in allDataDownloadList" :key="index" class="download"
            :style="{ backgroundColor: isEven(index) ? '' : '#FCFCFC' }">
            <div style="display: flex;align-items: center;flex: 1;flex-shrink: 0;">
              <img class="download-img" v-if="item.title.endsWith('f')" src="../assets/img/Pdf.png" alt="">
              <img class="download-img" v-else src="../assets/img/Zip.png">
              <div class="hover" @click="download(item.download_url)">{{ item.title }}</div>
            </div>
            <div style="color: #7b7b7b;flex: 1;"> {{ deteFormat(item.create_time) }}</div>
            <div class="downclick" @click="download(item.download_url)">
              <i class="iconfont icon-xiazai"></i>
              点击下载
            </div>
          </div>
        </div>
        <!-- 相关产品内容 -->
        <div v-if="dataDownloadList.length >= 1" class="download-box">
          <div v-for="(item, index) in dataDownloadList" :key="index" class="download"
            :style="{ backgroundColor: isEven(index) ? '' : '#FCFCFC' }">
            <div style="display: flex;align-items: center;flex: 1;">
              <img class="download-img" v-if="item.title.endsWith('f')" src="../assets/img/Pdf.png" alt="">
              <img class="download-img" v-else src="../assets/img/Zip.png">
              <div class="hover" @click="download(item.download_url)">{{ item.title }}</div>
            </div>
            <div style="color: #7b7b7b;flex: 0.8;"> {{ deteFormat(item.create_time) }}</div>
            <div class="downclick" @click="download(item.download_url)">
              <i class="iconfont icon-xiazai"></i>
              点击下载
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案内容 -->
    <div v-if="navid == 2" class="bg">
      <div class="nav-box2">
        <div :class="{ 'box2-content-hover': navid2 == item.id }" @click="subItemClick(item.id, item.title)"
          class="box2-content" v-for="item in solutionClassifyList" :key="item.id">
          {{ item.title }}
        </div>
      </div>
      <!-- 列表 -->
      <div class="content-box2">
        <div @mouseleave="changeid2()" @mouseenter="changeid3(item.id)" class="solutionlist-box"
          v-for="item in displayedBoxes" :key="item.id" @click="openpages('/SolutionDetail', item.id, navname2)">
          <div style="overflow: hidden;">
            <img class="solution-img" :src="'https://api.hi-wooya.com/storage/' + item.img">
          </div>
          <div>
            <div class="solution-title">
              {{ item.title }}
            </div>
            <div class="solution-content">
              {{ richTextFormat(item.content) }}
            </div>
            <div class="border" :class="{ 'border2': tsid == 1 && navid4 == item.id }"></div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination-box">
        <button class="pagination" @click="goToPreviousPage" :disabled="currentPage === 1">
          上一页
        </button>
        <span v-for="page in pages" :key="page">
          <button @mouseleave="changepage2()" @mouseenter="changepage(page)" @click="goToPage(page)" class="pagination2"
            :class="{ 'pagination-active': page === currentPage, 'pagination2-hover': page === pages2 }">{{ page
            }}</button>
        </span>
        <button class="pagination" @click="goToNextPage" :disabled="currentPage === totalPages">
          下一页
        </button>
      </div>
    </div>
    <!-- 技术支持内容 -->
    <div v-if="navid == 3">
      <div class="content-box">
        <div>
          <div class="title2">技术支持服务时间</div>
          <div class="content2">周一至周五：上午9：00 ~ 12:00，下午13：30 ~ 18：30 （不包含国家法定假日）</div>
        </div>
        <div>
          <div class="title2">技术支持方式</div>
          <div class="content2">您好，感谢您选购我司产品！<br>
            如果您在使用产品过程中，需要我司提供技术支持服务，请加销售经理V信：18576787029（小匹夫），由他给您安排工程，进行V信一对一技术支持。<br>
            邮箱：support@hi-wooya.com</div>
        </div>
        <div>
          <div class="title2">技术支持范围</div>
          <div class="content2">1、了解产品的软、硬件资源提供情况咨询；<br>
            2、产品的软、硬件手册使用过程中遇到的问题；<br>
            3、下载和烧写更新系统过程中遇到的问题；<br>
            4、产品用户的资料丢失、更新后重新获取；<br>
            5、产品的故障判断及售后维修服务。</div>
        </div>

        <div>
          <div class="title2">技术讨论范围（非必解决问题）</div>
          <div class="content2">1、源码如何理解和修改，电路板的自行设计制作或修改；<br>
            2、如何编译和移植操作系统；<br>
            3、用户在自行修改以及开发中遇到的软硬件问题。</div>
        </div>
      </div>
    </div>
    <!-- 售后服务内容 -->
    <div v-if="navid == 4">
      <div class="nav-box2">
        <div :class="{ 'box2-content-hover': navid3 == 1 }" @click="serviceChange()" class="box2-content">
          售后服务
        </div>
        <div :class="{ 'box2-content-hover': navid3 == 2 }" @click="serviceChange2()" class="box2-content">
          返修申请
        </div>
      </div>
      <div v-if="navid3 == 1">
        <div class="content-box">
          <div>
            <div class="title2">凡是通过无涯直接购买或经无涯授权的正规代理商处购买的无涯产品，均可享受以下权益：</div>
            <div class="content2">1、产品本身2年免费保修服务（配件除外）；<br>
              2、保修期满后出现产品异常，无涯提供有偿维修服务，可与无涯取得联系，收费视具体情况而定。如遇损坏程度严重等其他不可控因素导致无法维修的，公司不再提供维修服务；<br>
              3、如您购买的产品需要维修或检测，请提前备份机器内的相关数据。无涯不对因数据丢失所造成的损失负责。</div>
          </div>
          <div>
            <div class="title2">注：以下情况不属于免费维修范围，可提供有偿维修：</div>
            <div class="content2">1、超出保修期的产品；<br>
              2、非保元件：CPU、内存芯片、Flash；<br>
              3、由于使用不当，出现诸如PCB烧毁、破裂等物理损伤的产品；<br>
              4、由于人为疏忽或错误使用、未按说明书规定使用而造成的产品损坏等；<br>
              5、拆装或更换组件、器件而造成无法复原的产品；<br>
              6、在将故障件返回无涯技术服务部的过程中由于包装或运输操作不当造成损坏的产品。</div>
          </div>

          <div>
            <div class="title2">维修周期：</div>
            <div class="content2">
              收到返修产品后，我们将即日安排工程师进行检测，我们将在短时间内维修或更换并寄回。一般的故障维修周期为5个工作日（自我司收到物品之日起，不计运输过程时间），由于特殊故障导致无法短期内维修的产品，我们会与用户另行沟通并确认维修周期。
            </div>
          </div>

          <div>
            <div class="title2">维修费用：</div>
            <div class="content2">
              在免费保修期内的产品，由于产品质量问题引起的故障，不收任何维修费用；不属于免费保修范围内的故障或损坏，在检测确认问题后，我们将与客户沟通并确认维修费用，我们仅收取元器件材料费，不收取维修服务费；超过保修期限的产品，根据实际损坏的程度来确定收取的元器件材料费和维修服务费。
            </div>
          </div>

          <div>
            <div class="title2">运输费用：</div>
            <div class="content2">产品正常保修时，用户寄回的运费由用户承担，维修后寄回给用户的费用由我司承担。非正常保修产品来回运费均有用户承担。</div>
          </div>
        </div>
      </div>
      <div v-if="navid3 == 2">
        <div class="content-box">
          <div>
            <div class="title2">尊敬的无涯物联用户：</div>
            <div class="content2">
              您好！如果您的产品出现不良现象，经与无涯售后技术人员沟通无法解决时，请您在本页面（下载返修申清单）填写相关资料进行产品报修，然后将机器、保修卡或者购机票据一起邮寄到无涯售后服务部进行维修处理。<br><br>
              注意事项<br>
              1. 请选择正规的快递公司并购买相应的产品保险，返回无涯厂家维修时的快递运费由用户承担支付，产品维修后返回给用户时的快递运费由无涯厂家承担支付。<br>
              2. 请务必将所打印的产品报修页随产品一起发回无涯售后服务部。<br>
              3.
              在收到您的产品后，我们将对您的产品进行检测，检测完成后如发现将产生非免费保修范围内的费用时（产品已过免费保修期或人为原因等造成产品不良的），我们将会以电话的形式通知到您，在征得您的同意并确认收到您的相关款项后，我们将把您的产品转入售后维修流程。免费保修范围内所更换下的物料或免费保修范围外客户未全额支付的物料归无涯所有。
            </div>
          </div>
          <div class="content2">
            <a style="color: #90c31d;"
              href="https://api.hi-wooya.com/storage/default/fx.doc"><strong>&gt;&gt;下载返修申清单</strong></a>
          </div>
          <div class="content2">
            <div>
              收货信息
            </div>
            <div>
              收货地址： 四川省成都市龙泉驿区车城东六路366号综合楼5楼
            </div>
            <div>
              收货人： 无涯物联售后服务部 廖玉霞
            </div>
            <div>
              邮编：610100
            </div>
            <div>
              电话：15775958794
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品定制部分 -->
    <div v-if="navid == 5">
      <div class="content-box">
        <div>
          <div class="title2">尊敬的无涯物联用户：</div>
          <div class="content2">
            无涯基于多年的行业深耕，在基于ARM/MIPS内核处理器的嵌入式软、硬件开发上，积累了丰富的产品技术和项目开发经验。在推出稳定可靠、高性价比的ARM/MIPS核心板及配套产品的同时，也针对客户个性化的需求，提供专业，高效的定制服务。
            <br>无涯基于自身的技术和项目优势，为客户提供核心板，底板，以及整板的定制服务。无涯的定制服务，从前期对客户的需求整理、可行性分析，到后期的方案实施、样机调试，再到最后产品优化、批量生产，整个过程都由研发人员直接参与并对口客户相关人员，做到项目每个细节都无缝衔接。
          </div>
        </div>
        <div class="content2">
          <div>
            需求咨询
          </div>
          <div>
            电话：18576787029 028-62485172
          </div>
          <div>
            邮箱：wanliyong@hi-wooya.com
          </div>
        </div>
      </div>
    </div>
    <!-- 软件定制部分 -->
    <div v-if="navid == 6">
      <div class="content-box">
        <div>
          <div class="title2">尊敬的无涯物联用户：</div>
          <div class="content2">
            无涯基于多年的行业深耕，在基于ARM/MIPS内核处理器的嵌入式软、硬件开发上，积累了丰富的产品技术和项目开发经验。在推出稳定可靠、高性价比的ARM/MIPS核心板及配套产品的同时，也针对客户个性化的需求，提供专业，高效的定制服务。
            <br>无涯基于自身的技术和项目优势，为客户提供核心板，底板，以及整板的定制服务。无涯的定制服务，从前期对客户的需求整理、可行性分析，到后期的方案实施、样机调试，再到最后产品优化、批量生产，整个过程都由研发人员直接参与并对口客户相关人员，做到项目每个细节都无缝衔接。
          </div>
        </div>
        <div class="content2">
          <div>
            需求咨询
          </div>
          <div>
            电话：18576787029 028-62485172
          </div>
          <div>
            邮箱：wanliyong@hi-wooya.com
          </div>
        </div>
      </div>
    </div>
    <!-- 贴片加工部分 -->
    <div v-if="navid == 7">
      <div class="content-box">
        <div>
          <div class="title2">尊敬的无涯物联用户：</div>
          <div class="content2">
            无涯智能化SMT工厂致力于给客户提供从PCB制作、元器件采购、贴片加工到组装测试的一站式PCBA生产服务。无涯工厂位于四川成都龙泉驿区，以先进的生产设备和管理系统、严格的品质管控流程、完善的供应链体系、强力的工程支撑等优势，帮助客户提升生产效率、缩短产品交期、保证生产品质，服务于国内众多工业控制、电力通讯、新能源、汽车电子、医疗电子、智能家居及安防等各行业客户。
          </div>
        </div>
        <div class="content2">
          <div>
            需求咨询
          </div>
          <div>
            电话：18576787029 028-62485172
          </div>
          <div>
            邮箱：wanliyong@hi-wooya.com
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <nav-footer></nav-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //搜索内容
      search_value: '',
      // 轮播图
      rotationList: [],
      // 小图片内容
      // sub_menu: [{
      //   img: 'https://api.hi-wooya.com/storage/default/faq1.png',
      //   title: '知识问答',
      //   router: '/Shop',
      //   active: 8,
      // }, {
      //   img: 'https://api.hi-wooya.com/storage/default/faq2.png',
      //   title: '应用案例',
      //   router: '/SolutionCenter',
      //   active: 4,
      // }, {
      //   img: 'https://api.hi-wooya.com/storage/default/faq3.png',
      //   title: '资料下载',
      //   router: '/Download',
      //   active: '',
      // }, {
      //   img: 'https://api.hi-wooya.com/storage/default/faq4.png',
      //   title: '产品定制',
      //   router: '/PCBA',
      //   active: '',
      // }, {
      //   img: 'https://api.hi-wooya.com/storage/default/faq5.png',
      //   title: '贴片加工',
      //   router: '/PCBA',
      //   active: '',
      //   query_data: '3'
      // }, {
      //   img: 'https://api.hi-wooya.com/storage/default/faq6.png',
      //   title: '代理商申请',
      //   router: '/Shop',
      //   active: 8,
      // }]
      // 导航id
      navid: 1,
      navname: '资料下载',
      // 导航
      nav_list: [{
        id: 1,
        title: '资料下载'
      }, {
        id: 2,
        title: '解决方案'
      }, {
        id: 3,
        title: '技术支持'
      }, {
        id: 4,
        title: '售后服务'
      }, {
        id: 5,
        title: '产品定制'
      }, {
        id: 6,
        title: '软件定制'
      },
      {
        id: 7,
        title: '贴片加工'
      }],
      // 下载数据分类列表
      dataDownloadList: [],
      allDataDownloadList: [],
      // 产品分类
      productList: [],
      productList2: [],
      productList3: [],
      // 搜索
      model: '',
      // 具体产品
      detailInfo: [],
      //解决方案列表
      solution_list: [],
      // 解决方案分类列表
      solutionClassifyList: [],
      // 解决方案id
      navid2: 211,
      navid4: 211,
      navname2: '',
      // 底部边框id
      tsid: 0,
      // 分页内容
      // 当前页数
      currentPage: 1,
      // 一页展示几个
      itemsPerPage: 12,
      pages2: 0,
      form: {
        list_rows: 99, //上个程序员遗留问题，这个是上个程序员的分页内容，在我这版实际的作用是提供后端所有解决方案列表
      },
      // 售后服务二级分类
      navid3: 1,
    }
  },
  created() {
    this.navid = this.$route.query.id || 1;
    this.navname = this.$route.query.name || '资料下载'
    this.getswiper()
    this.getproductclassification()
    this.getAllDownLoad()
    this.getclassifylist()
    this.getsolutionList(211)

  },
  computed: {

    // 分页内容
    totalPages() {
      return Math.ceil(this.solution_list.length / this.itemsPerPage);
    },
    displayedBoxes() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.solution_list.slice(startIndex, endIndex);
    },
    pages() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    //下载内容
    download(e) {
      window.open('https://api.hi-wooya.com/storage/' + e, '_self')
    },
    //跳转页面
    openpages(path, id, type) {
      this.$js.openpages(path, id, type);
    },
    // 获取轮播图
    async getswiper() {
      let res = await this.$apiFun.rotationList({
        classify_id: 7
      });
      this.rotationList = res.result;
    },
    //判断双数
    isEven(index) {
      return index % 2 === 0;
    },
    //更改选择框
    selChange(e) {
      this.getSubProductClassifyList(e.target.value)
    },
    selChange2(e) {
      this.getproduct(e.target.value)
    },
    selChange3(e) {
      this.getDownLoad(e.target.value)
    },
    async getproductclassification() {
      //获取产品分类
      let res = await this.$apiFun.productClassifyList({});
      this.productList = res.result;
    },
    //得到子类
    async getSubProductClassifyList(e) {
      //获取产品分类
      let res = await this.$apiFun.productClassifyList({ parent: e });
      this.productList2 = res.result;
    },
    async getproduct(classify_id) {
      //获取产品
      let res = await this.$apiFun.productList({
        classify_id: classify_id
      });
      this.productList3 = res.result.data;
    },
    // 获取下载数据分类列表
    async getDownLoad(id) {
      let res = await this.$apiFun.dataDownloadList({
        classify_id: id
      });
      this.dataDownloadList = res.result;
      let res1 = await this.$apiFun.productDetail({
        id: id
      });
      this.detailInfo = res1.result;
    },
    async getAllDownLoad() {
      let res = await this.$apiFun.allDataDownloadList({});
      this.allDataDownloadList = res.result;
    },
    //裁剪日期
    deteFormat(value) {
      return value.substring(0, 10);
    },
    // 获取解决方案轮播图
    async getswiper2() {
      let res = await this.$apiFun.rotationList({
        classify_id: 4
      });
      this.rotationList = res.result;
    },
    // 获取解决方案分类
    async getclassifylist() {
      let res = await this.$apiFun.schemeClassifyList({});
      this.solutionClassifyList = res.result;
      this.navname2 = this.solutionClassifyList[0].title
    },
    // 获取解决方案列表
    async getsolutionList(classify_id = 211) {
      this.form.classify_id = classify_id
      let res = await this.$apiFun.schemeList(this.form);
      this.solution_list = res.result.data;
      this.currentPage = 1
    },
    //切换解决方案分类
    subItemClick(id, title) {
      this.navid2 = id
      this.navname2 = title
      this.getsolutionList(id)
    },
    changeId(id, name) {
      this.navid = id
      this.navname = name
      if (id == 2) {
        this.navid2 = 211
        this.getswiper2()
      } else {
        this.getswiper()
      }
    },
    // 底部border变化
    changeid3(id) {
      this.navid4 = id
      this.tsid = 1
    },
    changeid2() {
      this.tsid = 0
    },
    //转换富文本
    richTextFormat(value) {
      value = value.replace(/<\/?.+?>/g, '')
      value = value.replace(/\s+/g, '')
      if (value.length > 100) {
        return value.slice(0, 100) + "...";
      }
      return value;
    },
    // 页数加
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    // 页数减
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    changepage(page) {
      this.pages2 = page
    },
    changepage2() {
      this.pages2 = 0
    },
    // 售后服务改变id
    serviceChange() {
      this.navid3 = 1
    },
    serviceChange2() {
      this.navid3 = 2
    },
  },
  watch: {
    '$route.query.id'(newValue, oldValue) {
      this.navid = newValue
      this.navname = this.$route.query.name
    }
  },
};
</script>

<style scoped>
.hover:hover {
  color: #90c31d;
}
.bg {
  background-color: #f5f5f5;
  /* height: 100%; */
}
.download {
  color: #7b7b7b;
}

a {
  color: white;
}

.swiper {
  position: relative;
  width: 100%;
  height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.swiper-content {
  width: 350px;
  font-size: 32px;
  font-weight: bold;
  color: white;
  margin: 0 auto;
  text-align: center;
}

.swiper-btn {
  width: 350px;
  margin: 0 auto;
  height: 34px;
  display: flex;
}

.btn-input {
  width: 300px;
  height: 34px;
  padding: 0 0 0 10px;
  border: none;
  outline: none;
}

.btn {
  border: none;
  height: 34px;
  width: 39px;
  color: white;
  background-color: #90c31d;
  cursor: pointer;
  text-align: center;
  line-height: 34px;
}

.i-size {
  font-size: 20px;
}

.nav-box {
  background-color: rgba(0, 0, 0, 0.337);
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
}

.content-box3 {
  display: flex;
  width: 1600px;
  margin: 0 auto;
  justify-content: space-between;
}

.nav {
  height: 60px;
  color: white;
  font-size: 18px;
  line-height: 60px;
  padding: 0 25px;
  cursor: pointer;
}

.nav-box3 {
  color: white !important;
  font-size: 18px;
  line-height: 60px;
  height: 100%;
  padding-right: 10px;
}

.nav-hover {
  background-color: #90c31d;
}

.nav:hover {
  background-color: #90c31d;
}

/* 大于1700时样式 */
@media screen and (min-width: 1700px) {
  .nav-box2 {
    width: 1600px;
    height: 80px;
    display: flex;
    margin: 0 auto;
  }

  .box2-content {
    height: 100%;
    line-height: 80px;
    padding: 0px 25px;
    cursor: pointer;
    box-sizing: border-box;
  }

  .box2-content:hover {
    color: #90c31d;
    border-bottom: 2px solid #90c31d;
  }

  .box2-content-hover {
    color: #90c31d;
    border-bottom: 2px solid #90c31d;
  }

  .content-box2 {
    width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    /* gap: 30px; */
  }

  .solutionlist-box {
    position: relative;
    width: 377.5px;
    height: 370px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    background-color: white;
    cursor: pointer;
    margin-right: 30px;
    margin-bottom: 30px;
  }

  .solutionlist-box:nth-child(4n) {
    margin-right: 0;
  }


  .solution-img {
    width: 377.5px;
    height: 210px;
    transition: all 0.3s;
  }

  .solution-img:hover {
    transform: scale(1.1);
  }

  .solution-title {
    margin: 20px 0 20px 20px;
    font-size: 15px;
    font-weight: bold;
  }

  .solution-title:hover {
    color: #90c31d;
  }

  .solution-content {
    font-size: 15px;
    width: 350px;
    margin: 20px 0 20px 20px;
    color: #7b7b7b;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .solution-content:hover {
    color: #90c31d;
  }

  .border {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 0px;
    height: 0;
    border-bottom: 2px solid #90c31d;
    transition: 0.2s all linear;
  }

  .border2 {
    width: 100%;
  }


  .pagination-box {
    background-color: #F8F8F8;
    width: fit-content;
    margin: 20px auto;
    margin-bottom: 0px;
    padding-bottom: 20px;
  }

  .btn-input2 {
    width: 300px;
    height: 25px;
    padding: 0 0 0 10px;
    border: none;
    outline: none;
  }

  .btn {
    border: none;
    height: 34px;
    width: 39px;
    color: white;
    background-color: #90c31d;
    cursor: pointer;
    text-align: center;
    line-height: 34px;
  }

  .btn2 {
    border: none;
    height: 27px;
    width: 32px;
    color: white;
    background-color: #90c31d;
    cursor: pointer;
    text-align: center;
    line-height: 27px;
    font-size: 17px;
  }

  .content-box {
    width: 1600px;
    margin: 80px auto;
  }


  .box2 {
    /* width: 100%; */
    margin-top: 40px;
    font-size: 19px;
    padding: 10px 0 10px 10px;
    font-weight: bold;
    background-color: #dcdcdc;
  }

  .box3 {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }

  .box3-title {
    font-size: 18px;
    font-weight: bold;
  }

  .box3-content {
    width: 130px;
    border: 1px solid #dcdcdc;
  }

  .box3-content:focus {
    outline: none;
    border: 2px solid #012f55;
  }

  .download-box {
    width: 100%;
    height: 300px;
    margin: 40px 0 80px 0;
  }

  .download {
    width: 100%;
    height: 55px;
    line-height: 40px;
    flex-direction: row;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .downclick:hover {
    color: #90c31d;
  }

  .download-img {
    width: 20px;
    height: 20px;
    /* margin-right: 10px; */
  }

  .img {
    width: 180px;
    height: 180px;
  }

  .product-title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }

  .desc {
    font-size: 18px;
    color: #7b7b7b;
    margin-top: 20px;
  }

  .title2 {
    font-size: 18px;
    font-weight: bold;
  }

  .content2 {
    width: 1000px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #7b7b7b;
  }
}

/* 小于1700时样式 */
@media screen and (max-width: 1700px) {

  .hover {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 558.2px;
  }

  .nav-box2 {
    width: 1200px;
    height: 60px;
    display: flex;
    margin: 0 auto;
  }

  .box2-content {
    height: 100%;
    line-height: 60px;
    padding: 0px 18.75px;
    cursor: pointer;
    box-sizing: border-box;
  }

  .box2-content:hover {
    color: #90c31d;
    border-bottom: 2px solid #90c31d;
  }

  .box2-content-hover {
    color: #90c31d;
    border-bottom: 2px solid #90c31d;
  }

  .content-box2 {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    /* gap: 22.5px; */
  }

  .solutionlist-box {
    position: relative;
    width: 283.125px;
    height: 277.5px;
    margin-top: 22.5px;
    display: flex;
    flex-direction: column;
    background-color: white;
    cursor: pointer;
    margin-right: 22.5px;
    margin-bottom: 22.5px;
  }

  .solutionlist-box:nth-child(4n) {
    margin-right: 0;
  }

  .solution-img {
    width: 283.125px;
    height: 157.5px;
    transition: all 0.3s;
  }

  .solution-img:hover {
    transform: scale(1.1);
  }

  .solution-title {
    margin: 15px 0 15px 15px;
    font-size: 11.25px;
    font-weight: bold;
  }

  .solution-title:hover {
    color: #90c31d;
  }

  .solution-content {
    font-size: 11.25px;
    width: 262.5px;
    margin: 15px 0 15px 15px;
    color: #7b7b7b;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .solution-content:hover {
    color: #90c31d;
  }

  .border {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 0px;
    height: 0;
    border-bottom: 2px solid #90c31d;
    transition: 0.2s all linear;
  }

  .border2 {
    width: 100%;
  }


  .pagination-box {
    background-color: #F8F8F8;
    width: fit-content;
    margin: 15px auto;
    margin-bottom: 0px;
    padding-bottom: 15px;
  }

  .btn-input2 {
    width: 225px;
    height: 18.75px;
    padding: 0 0 0 7.5px;
    border: none;
    outline: none;
  }


  .btn2 {
    border: none;
    height: 20.25px;
    width: 24px;
    color: white;
    background-color: #90c31d;
    cursor: pointer;
    text-align: center;
    line-height: 20.25px;
    font-size: 12.75px;
  }

  .content-box {
    width: 1200px;
    margin: 60px auto;
  }


  .box2 {
    /* width: 100%; */
    margin-top: 30px;
    font-size: 14.25px;
    padding: 7.5px 0 7.5px 7.5px;
    font-weight: bold;
    background-color: #dcdcdc;
  }

  .box3 {
    width: 100%;
    display: flex;
    gap: 15px;
    margin-top: 15px;
  }

  .box3-title {
    font-size: 13.5px;
    font-weight: bold;
  }

  .box3-content {
    width: 97.5px;
    border: 1px solid #dcdcdc;
  }

  .box3-content:focus {
    outline: none;
    border: 2px solid #012f55;
  }

  .download-box {
    width: 100%;
    height: 225px;
    margin: 30px 0 60px 0;
  }

  .download {
    width: 100%;
    height: 41.25px;
    line-height: 30px;
    flex-direction: row;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .downclick:hover {
    color: #90c31d;
  }

  .download-img {
    width: 15px;
    height: 15px;
    /* margin-right: 7.5px; */
  }

  .img {
    width: 135px;
    height: 135px;
  }

  .product-title {
    font-size: 13.5px;
    font-weight: bold;
    margin-top: 15px;
  }

  .desc {
    font-size: 13.5px;
    color: #7b7b7b;
    margin-top: 15px;
  }

  .title2 {
    font-size: 13.5px;
    font-weight: bold;
  }

  .content2 {
    width: 750px;
    margin-top: 7.5px;
    margin-bottom: 15px;
    font-size: 13.5px;
    color: #7b7b7b;
  }
}

@media screen and (max-width: 1200px) {
  .swiper {
    width: 1200px;
  }
  .bg {
    width: 1200px;
}
}
</style>
