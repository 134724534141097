<template>
  <div>
    <!-- 头部导航 -->
    <nav-header></nav-header>
    <!-- 顶部导航 -->
    <div class="nav">
      <div v-for="item in topList" :key="item.id">
        <div v-if="topId == item.id">
          <!-- 导航背景 -->
          <div class="bgimg" :style="{ 'background-image': 'url(' + item.img + ')' }">
            <!-- 标题 -->
            <div class="nav-title" :class="{ 'black': topId != 1 && topId != 2, 'nav-title2': topId === 3 }">{{
        item.title
      }}
            </div>
            <!-- 内容 -->
            <div :class="{ 'black': topId != 1 && topId != 2, 'nav-content3': topId === 3 }" class="nav-content1">
              {{ item.content1 }}</div>
            <div :class="{ 'black': topId != 1 && topId != 2, 'nav-content4': topId === 3 }" class="nav-content2">
              {{ item.content2 }}</div>
            <!-- 选择内容栏 -->
            <div class="select-bottom">
              <div class="select-box">
                <div style="display: flex;">
                  <div :class="{ 'select-content-active': topId == item.id }" @click="changeid(item.id, item.name)"
                    class="select-content" v-for="(item, index) in selectList" :key="index">{{ item.name }}</div>
                </div>
                <div class="nav-box">
                  <a href="/">首页</a>>PCBA服务>{{ navname }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 电子产品设计部分 -->
    <div v-if="topId == 1" class="design">
      <!-- 第一模块 -->
      <div class="design-box">
        <div class="content-box">
          <div style="display: flex;justify-content: space-between;">
            <!-- 左边部分 -->
            <div class="box1-left">
              <!-- 左边标题 -->
              <div class="box1-title">
                行业经验丰富
              </div>
              <!-- 左边内容 -->
              <div class="box1-content">
                四川无涯智能科技有限公司8年物联网行业经验以及在电力、消防 、环保 、智慧农业、智慧工业和智慧共享等行业的深耕积累，可以提供靠谱的设计方案设计经验。
              </div>
              <!-- 左边图片部分 -->
              <div class="img-box">
                <!-- 左边小图片 -->
                <div class="img-left">
                  <div class="img-left-img" v-for="item in industry_list" :key="item.id">
                    <div>
                      <img class="img-left-content" :src="item.img">
                    </div>
                    <div class="img-left-content2">{{ item.title }}</div>
                  </div>
                </div>
                <!-- 右边图片 -->
                <div class="img-right">
                  <div class="img-right-title">赋能物联网行业</div>
                  <div class="img-right-content">连接万物 成就你我</div>
                </div>
              </div>
            </div>
            <!-- 右边部分 -->
            <div class="box1-right">
              <img style="width: 100%;height: 100%;" src="https://api.hi-wooya.com/storage/default/20220804011841.jpg">
            </div>
          </div>
        </div>
      </div>
      <!-- 第二模块 -->
      <div class="design2-box">
        <div class="content-box">
          <div class="flex">
            <!-- 左边部分 -->
            <div class="design2-left">
              <img class="img-content1"
                src="https://api.hi-wooya.com/storage/default/pcba/ce6875a4-4f5d-4995-8764-fda6023f88e2.png">
            </div>
            <!-- 右边部分 -->
            <div class="design2-right">
              <!-- 标题 -->
              <div class="box1-title">技术实力雄厚</div>
              <!-- 内容 -->
              <div class="box1-content">研发团队配置：嵌入式软硬件开发人员十人以上，资深工程师5人以上</div>
              <div class="box2-content-box">
                <div class="box2-title">嵌入式软件/硬件设计</div>
                <div class="box2-content">具备各主流平台、系统的设计、多层板设计、各类射频设计等开发能力</div>
              </div>
              <div class="box2-content-box">
                <div class="box2-title">产品结构设计</div>
                <div class="box2-content">根据客户需求，可提供产品外壳设计</div>
              </div>
              <div class="box2-content-box">
                <div class="box2-title">嵌入式应用开发</div>
                <div class="box2-content">优秀的研发队伍，依托行业经验，迅速响应，满足客制化需求</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第三模块 -->
      <div class="design3-box">
        <div class="content-box">
          <div class="flex">
            <!-- 左边部分 -->
            <div class="design3-left">
              <div class="box1-title">流程体系</div>
              <div class="box1-content">研发流程体系 ：产品开发遵循IPD流程体系， 可根据客户项目实际需求灵活裁剪， 保障高效交付</div>
            </div>
            <!-- 右边部分 -->
            <div class="design3-right">
              <img class="design3-img" src="https://api.hi-wooya.com/storage/default/pcba/process.9d41cfc3.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 电子物料部分 -->
    <div v-if="topId == 2" class="material">
      <!-- 第一模块 -->
      <div class="material-box1">
        <div class="content-box" style="overflow: hidden;">
          <div class="material-box1-content">
            <div class="material-title">合作原厂及代理</div>
            <!-- 滚动插件 -->
            <div class="meterial-scroll">
              <vue-seamless-scroll :data="scrollList1" :class-option="defaultOption1">
                <div class="scroll-box">
                  <div class="scroll1-box" v-for="(item, index) in scrollList1" :key="index">
                    <img :src="item">
                  </div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
      <!-- 第二模块 -->
      <div class="material-box2">
        <div class="content-box">
          <div class="material-title">无涯代采 省心更放心</div>
          <!-- 图片内容 -->
          <div class="material-box2-content">
            <div class="box2-img" v-for="(item, index) in purchaseList" :key="item.id"
              :style="{ background: 'url(' + item.img + ')' }" @mouseenter="mouseEnter(index, 'purchaseList')"
              @mouseleave="mouseLeave(index, 'purchaseList')">
              <!-- 白色底边 -->
              <div class=" box2-img-content"
                :style="item.ishover ? { background: '#fff', color: '#90c31d' } : { background: 'rgba(255, 255, 255, 0.7)' }">
                <div class="box2-img-title1">
                  {{ item.title1 }}
                </div>
                <div class="box2-img-title2" :style="item.ishover ? { color: '#90c31d' } : { color: '#7b7b7b' }">
                  {{ item.ishover ? item.title2_hover : item.title2 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第三模块 -->
      <div class="material-box3">
        <div class="content-box">
          <div style="display: flex;">
            <!-- 左边部分 -->
            <div class="box3-left">
              <div style="font-size: 28px;">
                原料品质管控
              </div>
              <div style="margin-top: 20px;">
                品质控制QC：严格的IQC来料检验
              </div>
              <div style="margin-top: 20px;">
                库房管理：恒温恒湿、防静电管控
              </div>
              <div style="margin-top: 20px;">
                供应商稽查机制：正规的供应商准入及年度稽核机制
              </div>
            </div>
            <!-- 右边部分 -->
            <div class="box3-right">
              <vue-seamless-scroll :data="scrollList2" :class-option="defaultOption1">
                <div class="scroll-box">
                  <div class="scroll2-box" v-for="(item, index) in scrollList2" :key="index">
                    <img class="img" :src="item.img">
                    <div class="box3-right-content">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
      <!-- 第四模块 -->
      <div class="material-box4">
        <div class="content-box">
          <div class="material-title">元器件选型服务优势</div>
          <div class="material-box4-content">
            <div v-for="item in service_list" :key="item.id" class="material-box4-box">
              <img class="material-box4-img" :src="item.img">
              <div class="material-box4-title">
                {{ item.title }}
              </div>
              <div class="material-box4-title2">
                {{ item.subtitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PCBA加工部分 -->
    <div v-if="topId == 3" class="PCBA">
      <div class="content-box">
        <!-- 第一部分 -->
        <div class="PCBA-box1">
          <div class="material-title">PCBA加工设备</div>
          <div class="PCBA-box1-content">
            <div class="PCBA-box1-left">
              <div class="PCBA-box1-title">国外先进设备，满足高精度生产</div>
              <div class="PCBA-box1-content2">设备针对中高端质量要求的产品批量生产而量身定制，突破行业技术，可贴01005，BGA间距0.2MM、QFN、CSP、CON等元器件。</div>
            </div>
            <div class="PCBA-box1-right">
              <img style="width: 100%;height: 100%;" src="../assets/img/PCBAbox3.jpg">
            </div>
          </div>
        </div>
        <!-- 第二部分 -->
        <div>
          <div class="material-title">PCBA加工能力</div>
          <!-- 内容部分 -->
          <div class="PCBA-box2-content">
            <div class="PCBA-box2-box">
              <div class="PCBAtitle PCBA-box2-left">项目</div>
              <div class="PCBAtitle PCBA-box2-right">参数说明</div>
            </div>
            <div class="PCBA-box2-box">
              <div class="PCBA-box2-left">设备和产能</div>
              <div class="PCBA-box2-right">
                <div>SMT</div>
              </div>
            </div>
            <div class="PCBA-box2-box">
              <div class="PCBA-box2-left">电路板尺寸</div>
              <div class="PCBA-box2-right">
                <div>•最小板尺寸：50mm x50mm</div>
              </div>
            </div>
            <div class="PCBA-box2-box">
              <div class="PCBA-box2-left">PCBA焊接类型</div>
              <div class="PCBA-box2-right">
                <div>提供表面贴片（SMT），插件后焊（THT）或两项都有的PCBA焊接服务</div>
              </div>
            </div>
            <div class="PCBA-box2-box">
              <div class="PCBA-box2-left">可贴PCB板类型</div>
              <div class="PCBA-box2-right">
                <div>PCB硬板（FR-4，金属基板），PCB软板（FPC），软硬结合PCB</div>
              </div>
            </div>
            <div class="PCBA-box2-box">
              <div class="PCBA-box2-left">锡膏/锡线/锡条</div>
              <div class="PCBA-box2-right">
                <div>支持有铅、无铅（RoHS合规）的贴片工艺服务。</div>
                <div>求进行选择，默认千柱。</div>
              </div>
            </div>
            <div class="PCBA-box2-box">
              <div class="PCBA-box2-left">钢网</div>
              <div class="PCBA-box2-right">
                <div>•被动元件：擅长贴装英制01005（0.4mm * 0.2mm），0201这样小的元件</div>
                <div>•BGA等高精IC：可以通过X-ray来检测Min 0.25mm间距的BGA元件。</div>
              </div>
            </div>
            <div class="PCBA-box2-box">
              <div class="PCBA-box2-left">元件包装</div>
              <div class="PCBA-box2-right">
                <div>SMT元件我们接受卷盘，切割带，管材和托盘等可上机包装。 后焊元件接受散装。</div>
              </div>
            </div>
            <div class="PCBA-box2-box">
              <div class="PCBA-box2-left" style="line-height: 160px;">过程控制</div>
              <div class="PCBA-box2-right">
                <div>• IQC：进料检查； • IPQC：产中巡检； • 目视QC：常规质量检查；</div>
                <div>• AOI：检查锡膏，贴片元件的焊接效果，少件或元件极性；</div>
                <div>• X-Ray：检查BGA，QFN等高精密隐藏PAD的元件；</div>
                <div>•功能测试：按照客户的测试程序和步骤，测试功能及性能，以确保符合要求。</div>
                <div>•老化测试：按照客户的测试程序和步骤，测试功能及性能，以确保符合要求。</div>
              </div>
            </div>
            <div class="PCBA-box2-box">
              <div class="PCBA-box2-left">硬件维修</div>
              <div class="PCBA-box2-right">
                <div>硬件问题定位分析、硬件维修器件更换（BGA等）</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第三模块 -->
      <div class="PCBA-box2">
        <div class="material-title">PCBA加工流程</div>
        <!-- 内容 -->
        <div class="content-box">
          <div class="PCBA-box2-box1">
            <img src="https://api.hi-wooya.com/storage/default/pcba/0e16f77b-1890-4ea7-a41f-84e5bb89383c.png">
            <img src="https://api.hi-wooya.com/storage/default/pcba/6358478e-de01-47a5-a1c3-91175fc085e6.png">
            <img src="https://api.hi-wooya.com/storage/default/pcba/52d04777-6762-433f-931b-48bc72eed7d9.png">
            <img src="https://api.hi-wooya.com/storage/default/pcba/ce86ca21-98f9-4cfa-aef1-8938546b86d3.png">
            <img src="https://api.hi-wooya.com/storage/default/pcba/f3015c09-91cd-4c5a-ba7e-3feed4855c49.png">
            <img src="https://api.hi-wooya.com/storage/default/pcba/2c644e8e-0651-4e50-80a9-bff4618d83ee.png">
            <img src="https://api.hi-wooya.com/storage/default/pcba/2fce8c47-2c09-49cd-8b17-85548306a02f.png">
            <img src="https://api.hi-wooya.com/storage/default/pcba/af21e1e7-8a6d-4ca3-8791-3aad14b5b4d9.png">
            <img src="https://api.hi-wooya.com/storage/default/pcba/3d2dd9f5-2a08-4734-9dd4-9c8a8e232ec7.png">
          </div>
          <div class="PCBA-box2-box2">
            <vue-seamless-scroll :data="technological_process_list" :class-option="defaultOption2" class="scroll-box2"
              :hover=true>
              <div class="PCBA-box2-box3">
                <div @mouseenter="mouseEnter(index, 'technological_process_list')"
                  @mouseleave="mouseLeave(index, 'technological_process_list')"
                  v-for="(item, index) in technological_process_list" :key="item.zindex" class="box2-pcba-content"
                  :style="[{ 'z-index': item.zindex }, item.ishover ? { 'background': '#90c31d' } : {
        'background-image': 'url(' + item.img + ')', 'background-position': 'center center',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
      }]">
                  <div class="" v-if="item.ishover">
                    <div class="PCBA-title">
                      {{ item.desc.title }}
                    </div>
                    <div class="PCBA-content" v-for="item1 in item.desc.content" :key="item1">
                      {{ item1 }}
                    </div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <!-- 第四模块 -->
      <div class="PCBA-box4">
        <div class="material-title" style="color: white;">PCBA加工常见问题</div>
        <!-- 内容 -->
        <div class="content-box">
          <div class="PCBA-box4-content">
            <div class="PCBA-box4-box" v-for="item in pcba_faq_list" :key="item.id">
              <!-- 图片部分 -->
              <div class="PCBA-box4-left">
                <img :src="item.img" class="img-pcba">
                <div>{{ item.title }}</div>
              </div>
              <!-- 文字部分 -->
              <div class="PCBA-box4-right">
                <div class="PCBA4-content" style="border-bottom: 2px solid #f5f5f5;">
                  {{ item.faq1 }}
                </div>
                <div class="PCBA4-content">
                  {{ item.faq2 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-white"></div>
    </div>
    <!-- 组装测试部分 -->
    <div v-if="topId == 4" class="test">
      <div class="content-box">
        <!-- 第一模块 -->
        <div class="test1-box">
          <!-- 左边部分 -->
          <div class="design2-left3">
            <img class="img-content2"
              src="https://api.hi-wooya.com/storage/default/pcba/c56de031-7a8b-41c4-98c8-23b3e9d38b74.png">
          </div>
          <!-- 右边部分 -->
          <div class="design2-right2">
            <!-- 标题 -->
            <div class="box3-title">产品组装</div>
            <!-- 内容 -->
            <div class="box1-content">多条组装线，相关产测记录及时同步</div>
          </div>
        </div>
        <!-- 第二模块 -->
        <div class="test1-box">
          <!-- 左边部分 -->
          <div class="design2-right">
            <!-- 标题 -->
            <div class="box3-title">产品测试</div>
            <!-- 内容 -->
            <div class="box1-content">测试工序可覆盖ICT、RF、FCT性能和可靠测试等全方位测试<br>具备RF、2.4G、蜂窝网等射频测试能力<br>具备自动化产品测试能力</div>
          </div>
          <!-- 右边部分 -->
          <div class="design2-left2">
            <img class="img-content2"
              src="https://api.hi-wooya.com/storage/default/pcba/0a120da5-f9f9-4011-9d5c-acb534f809c4.png">
          </div>
        </div>
      </div>
      <!-- 第三模块 -->
      <div class="test3-box">
        <div class="content-box">
          <!-- 标题 -->
          <div class="material-title" style="color: white;">性能和可靠性测试</div>
          <!-- 内容 -->
          <div class="test3-content">
            <div>
              性能测试：包括高低温测试、老化测试、盐雾测试
            </div>
            <div class="">
              可靠性测试：拉力试验 跌落试验 振动试验 耐压测试 盐雾测试等
            </div>
          </div>
          <!-- 滚动图 -->
          <div class="test3-scroll">
            <vue-seamless-scroll :data="testing_list" :class-option="defaultOption2">
              <div class="test3-scroll-content">
                <img class="test3-img" :src="item.img" v-for="item in testing_list" :key="'testing-' + item.id">
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <!-- 第四模块 -->
      <div class="test4-box">
        <div class="content-box">
          <!-- 标题 -->
          <div class="material-title">测试设备参数</div>
          <!-- 内容部分 -->
          <div class="test4-content">
            <!-- 左边 -->
            <div class="test4-left">
              <div class="test4-left-title">检测设备</div>
              <div v-for="(item, index) in test4List" :key="index" class="test4-left-content">{{ item.name }}</div>
            </div>
            <!-- 右边 -->
            <div class="test4-right">
              <div class="test4-left-title">SPI/AOI/X-RAY0</div>
              <div v-for="(item, index) in test4List2" :key="index" class="test4-left-content">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <nav-footer></nav-footer>
  </div>
</template>


<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  data() {
    return {
      topId: 1,
      navname: '电子产品设计',
      //图片高度
      bannerHeight: 0,
      //屏幕高度
      screenWidth: 0,
      // 选中栏内容
      selectList: [
        {
          id: 1,
          name: "电子产品设计"
        },
        {
          id: 2,
          name: "电子物料代购"
        },
        {
          id: 3,
          name: "PCBA加工"
        },
        {
          id: 4,
          name: "组装测试"
        }
      ],
      // 顶部导航
      topList: [
        {
          id: 1,
          img:
            "https://api.hi-wooya.com/storage/default/pcba/banner1.431e12e1.jpg",
          title: "一站式的服务",
          content1:
            "提供从电子方案设计、PCB、Layout、嵌入式软件开发到样品制作调试等服务",
          content2: "提供云平台私有部署定制、各主流云平台对接服务"
        },
        {
          id: 2,
          img:
            "https://api.hi-wooya.com/storage/default/pcba/banner2.5d4eb29f.jpg",
          title: "品质可靠",
          content1: "国内外知名芯片原厂合作，物料经过严格标准检测入厂",
          content2: "同时做好品质监控降低可能风险"
        },
        {
          id: 3,
          img:
            "https://api.hi-wooya.com/storage/default/pcba/banner3.df97ec9d.jpg",
          title: "PCBA加工",
          content1: "配备先进的设备、专业的PCBA加工工艺，",
          content2: "提供专业的PCBA一站式服务"
        },
        {
          id: 4,
          img:
            "https://api.hi-wooya.com/storage/default/pcba/banner4.00151832.jpg",
          title: "组装测试",
          content1: "建有多条组装测试线、为确保整机组装产品质量，",
          content2: "车间配备了全套的质量控制设备"
        }
      ],
      // 电子产品设计第一模块左边图片
      industry_list: [{
        id: 1,
        img: 'https://api.hi-wooya.com/storage/default/pcba/d5bd3e5a-1f1c-4801-a5d5-1c2adbf435cb.png',
        title: '电力行业'
      }, {
        id: 2,
        img: 'https://api.hi-wooya.com/storage/default/pcba/e165dd06-cf5c-4ff6-90fc-a9902955f264.png',
        title: '消防行业'
      }, {
        id: 3,
        img: 'https://api.hi-wooya.com/storage/default/pcba/25f7a563-5bdf-4f80-aa97-f55c95423d5e.png',
        title: '环保行业'
      }, {
        id: 4,
        img: 'https://api.hi-wooya.com/storage/default/pcba/6e7bad18-0868-4f2f-8ca1-0d93e893c0ed.png',
        title: '智慧农业'
      }, {
        id: 5,
        img: 'https://api.hi-wooya.com/storage/default/pcba/66a40619-8595-4c8b-bae0-65df23a5a821.png',
        title: '智慧工业'
      }, {
        id: 6,
        img: 'https://api.hi-wooya.com/storage/default/pcba/bd6857de-c035-4eac-a92f-ec73f505a365.png',
        title: '共享行业'
      }],
      // 电子物料代购第一模块无限轮播图
      scrollList1: [
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon1.5a271233.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon2.9bd9b856.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon3.3aaf18d4.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon4.28a8df2d.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon5.eb1e8f0c.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon6.47b894e4.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon7.ed2ad8e1.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon8.01b9f9ff.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon9.dfdfefcf.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon10.c50c23bb.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon11.65eaf707.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon12.1a490aae.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon13.9d373fe4.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon14.5bda2f83.png',
        'https://api.hi-wooya.com/storage/default/pcba/cooperativeIcon15.73aae1fe.png',
      ],
      // 电子物料代购第二模块图
      purchaseList: [{
        id: 1,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/cooperativeImg1.217e487a.png',
        title1: '丰富的供应商',
        title2: '大批量采购价格更实惠',
        title2_hover: ' 拥有长期合作的供应商，可以实现产品的SMT加工元器件大批量采购，为您降低成本 ',
      }, {
        id: 2,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/cooperativeImg2.90db48c6.png',
        title1: '十年经验工程团队',
        title2: '元器件品质把控',
        title2_hover: ' 拥有一批十八年经验的SMT加工工程团队，熟悉加工SMT过程中元器件对品质的影响，可为客户提供有效解决方案 ',
      }, {
        id: 3,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/cooperativeImg3.32bfc20e.png',
        title1: '优秀的采购团队',
        title2: '精心挑选，贴心服务',
        title2_hover: ' 优秀的采购团队均具备5年以上SMT加工行业工作经验，确保熟悉各大供应商产品特点及流程，为您挑选更适合的产品 ',
      }, {
        id: 4,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/cooperativeImg4.5310ffa5.png',
        title1: '九道工序层层检测',
        title2: '为品质无惧繁琐',
        title2_hover: ' 经过多年积累，熟悉各项元器件对产品加工品质的影响，并建立了严格的检验检测制度，做好SMT加工品质及损耗等风险控制，为您减少损失 ',
      },],
      // 电子物料代购第三模块无限轮播图
      scrollList2: [{
        id: 1,
        img: 'https://api.hi-wooya.com/storage/default/pcba/controlImg1.6f29dfd6.png',
        title: 'LCR电桥测试'
      },
      {
        id: 2,
        img: 'https://api.hi-wooya.com/storage/default/pcba/controlImg2.2c987657.png',
        title: '点料机'
      },
      {
        id: 3,
        img: 'https://api.hi-wooya.com/storage/default/pcba/controlImg3.246244a1.png',
        title: '静电测试'
      },
      {
        id: 4,
        img: 'https://api.hi-wooya.com/storage/default/pcba/controlImg4.2fc0072d.png',
        title: '电子显微镜'
      },
      {
        id: 5,
        img: 'https://api.hi-wooya.com/storage/default/pcba/controlImg5.7697232c.png',
        title: '高低温测试箱'
      },
      {
        id: 6,
        img: 'https://api.hi-wooya.com/storage/default/pcba/controlImg6.b50df887.png',
        title: '烤箱'
      },
      {
        id: 7,
        img: 'https://api.hi-wooya.com/storage/default/pcba/controlImg7.5cda8a0c.png',
        title: '干燥箱'
      },
      {
        id: 8,
        img: 'https://api.hi-wooya.com/storage/default/pcba/controlImg8.b51a2479.png',
        title: '温湿度试验'
      },
      ],
      // PCBA第三模块图
      service_list: [{
        id: 1,
        img: 'https://api.hi-wooya.com/storage/default/pcba/3a98c16a-d3e3-4c31-b443-1d8a0b7f8858.png',
        title: '快速响应机制',
        subtitle: '常备阻容、电感磁珠连接器、常规研发采购，两周内齐套'
      }, {
        id: 2,
        img: 'https://api.hi-wooya.com/storage/default/pcba/a32d4c33-b1d6-46ed-9c4c-aa11245b1013.png',
        title: '破解研发样品难题',
        subtitle: '专业供应链团队支持，研发采购也能享受VIP客户服务'
      }, {
        id: 3,
        img: 'https://api.hi-wooya.com/storage/default/pcba/d6068483-d373-4b31-886b-37d6afa1a8d7.png',
        title: '专业元器件认证工程师',
        subtitle: '完善的IQC检验体系，协助完善客户BOM准确性'
      }, {
        id: 4,
        img: 'https://api.hi-wooya.com/storage/default/pcba/187346c7-3f6c-487d-b824-091ab9583d96.png',
        title: '常用物料仓库',
        subtitle: '全系列Yageo、Murata、Avx、Kemet阻容，温湿度管控，先进先出机制'
      }, {
        id: 5,
        img: 'https://api.hi-wooya.com/storage/default/pcba/dd11f030-aa3c-4acb-a477-96951e64100d.png',
        title: '严格的供应商准入制度',
        subtitle: '严格的供应商审核机制，年度供应商稽核机制'
      }, {
        id: 6,
        img: 'https://api.hi-wooya.com/storage/default/pcba/edc0a9e0-49cd-4048-bc79-80ad42dbcb5b.png',
        title: '物料到货，即刻安排',
        subtitle: '上贴片焊接产线，加速产品研发进程'
      }],
      // PCBA第四模块图无限轮播图
      technological_process_list: [{
        zindex: 10,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/flowAnthor1.aaeb4ac8.png',
        desc: {
          title: '上板机',
          content: ['1、上板时间：6s/pcs', '2、步进间隔：10/20/30/40 mm',
            '3、基板尺寸：W250*L330mm(Max) W50*L50mm(Min)'
          ],
        }
      }, {
        zindex: 9,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/flowAnthor2.ccdcf5d9.png',
        desc: {
          title: '印刷机',
          content: ['1、印刷周期：13s/pcs', '2、精度： ±0.025mm', '3、基板尺寸：W310*L400mm(Max) W50*L50mm(Min)'],
        }
      },
      {
        zindex: 8,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/flowAnthor3.da79b4f2.png',
        desc: {
          title: '高速贴片机',
          content: ['1、元件尺寸：01005-6*6*3（mm）', '2、速度：42000 cph', '3、精度：±40μm',
            '4、基板尺寸：W300*L510mm(Max) W50*L50mm(Min)'
          ],
        }
      }, {
        zindex: 7,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/flowAnthor4.06710b1a.png',
        desc: {
          title: '多功能贴片机',
          content: ['1、元件尺寸：0402-150*25*30（对角152mm）', '2、速度：18000 cph', '3、精度：±30μm',
            '4、基板尺寸：W300*L510mm(Max) W50*L50mm(Min)'
          ],
        }
      }, {
        zindex: 6,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/flowAnthor5.ae1049c0.png',
        desc: {
          title: '回流焊',
          content: ['1、温区配置：10温区，20个加热模块', '2、温控精度：±1℃',
            '3、基板宽度：Min50mm-Max300mm（同时）Min50mm-Max550mm（单轨）'
          ],
        }
      }, {
        zindex: 5,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/flowAnthor6.2828ec44.png',
        desc: {
          title: '光学检测仪',
          content: ['1、相机分辨率：15μm', '3、基板尺寸：W330*L480(Max) W25*L25mm(Min)'],
        }
      }, {
        zindex: 4,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/flowAnthor7.aae81239.png',
        desc: {
          title: '插件线体',
          content: ['1、工位数量：8个', '2、可插装器件种类：3-5种/人', '3、基板宽度：50mm-350mm'],
        }
      }, {
        zindex: 3,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/flowAnthor8.51f79d7e.png',
        desc: {
          title: '波峰焊',
          content: ['1、链速：500-1800mm/min', '2、温控精度：±2°C', '4、基板宽度：50mm-350mm'],
        }
      }, {
        zindex: 2,
        ishover: false,
        img: 'https://api.hi-wooya.com/storage/default/pcba/flowAnthor9.9484774c.png',
        desc: {
          title: '装焊线体',
          content: ['1、工位数量：10个', '2、传输速度可控'],
        }
      },
      ],
      // PCBA第四模块
      pcba_faq_list: [{
        id: 1,
        img: 'https://api.hi-wooya.com/storage/default/pcba/boder1.25dd2678.png',
        title: '订单问题',
        faq1: 'Smt贴片加工厂结单率低该怎么解决？',
        faq2: 'Smt加工首单需要注意什么问题？',
      }, {
        id: 2,
        img: 'https://api.hi-wooya.com/storage/default/pcba/9fd4ccc7-93c9-4a5c-94ee-2b5af6e785b7.png',
        title: '技术问题',
        faq1: 'Smt贴片加工厂结单率低该怎么解决？',
        faq2: '为什么要选择无涯做SMT加工？',
      }, {
        id: 3,
        img: 'https://api.hi-wooya.com/storage/default/pcba/ba3333b1-3cdd-4dcc-b588-8d22bd0bd7dc.png',
        title: '工艺问题',
        faq1: '智能上料检测仪有哪些好处？',
        faq2: '为什么要选择无涯做SMT加工？',
      }, {
        id: 4,
        img: 'https://api.hi-wooya.com/storage/default/pcba/9c34c2e7-0fb8-4e09-a66e-19c31bd3ca85.png',
        title: '价格问题',
        faq1: '智能上料检测仪有哪些好处？',
        faq2: '为什么要选择无涯做SMT加工？',
      }],
      // 产品测试第三模块轮播图
      testing_list: [{
        id: 1,
        img: 'https://api.hi-wooya.com/storage/default/pcba/reliabilityTest1.f4b05848.png',
      }, {
        id: 2,
        img: 'https://api.hi-wooya.com/storage/default/pcba/reliabilityTest2.069263e8.png',
      }, {
        id: 3,
        img: 'https://api.hi-wooya.com/storage/default/pcba/reliabilityTest3.670ab41d.png',
      }, {
        id: 4,
        img: 'https://api.hi-wooya.com/storage/default/pcba/reliabilityTest4.8172f573.png',
      }, {
        id: 5,
        img: 'https://api.hi-wooya.com/storage/default/pcba/reliabilityTest5.1ca058ad.png',
      }, {
        id: 6,
        img: 'https://api.hi-wooya.com/storage/default/pcba/reliabilityTest6.619ccfd8.png',
      }, {
        id: 7,
        img: 'https://api.hi-wooya.com/storage/default/pcba/reliabilityTest7.a433b6fe.png',
      }, {
        id: 8,
        img: 'https://api.hi-wooya.com/storage/default/pcba/reliabilityTest8.7e88dfa8.png',
      }],
      // 产品测试左边列表
      test4List: [
        { name: "SPI可检测项目" },
        { name: "SPI主要检测最小检测元件" },
        { name: "SPI可检测精度" },
        { name: "SPI可检测重复性精度" },
        { name: "AOI可检测最小零件测试" },
        { name: "AOI检测主要覆盖类型" },
        { name: "X-RAY图像增强器分辨率" },
        { name: "X-RAY放大率" },
        { name: "X-RAY检测X光管聚焦" },
      ],
      // 产品测试右边列表
      test4List2: [
        { name: "体积、面积、高度、XY偏移、形状" },
        { name: "01005（英制）" },
        { name: "XY方向＜10um；高度=0.37um" },
        { name: "高度<1um（4sigma）；面积 /体积＜＜1%(4sigma)" },
        { name: "8um:01005chip & 0.3pitch IC" },
        { name: "偏移、少锡、短路、多锡、缺件、歪斜、立碑、侧立、翻件、 破损、极性虚焊、空焊、溢胶、锡洞、引脚未出等外观不良" },
        { name: "X-75/110lp/cm" },
        { name: "X-420x" },
        { name: "X-RAY检测X5um光管聚焦" },
      ],
    };
  },
  created() {
    this.changeid(this.$route.query.id)
    this.navname = this.$route.query.name || '电子产品设计'
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1700) {
      this.bannerHeight = 440;
    } else {
      this.bannerHeight = 499.5;
    }
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (440 / 1920) * this.screenWidth;
      if (this.bannerHeight < 273.5) {
        this.bannerHeight = 273.5;
      }
    };
  },
  methods: {
    changeid(id, name) {
      this.topId = id ? id : 1,
        this.navname = name
    },
    mouseEnter(index, arr) { //移进显示)
      this[arr][index]['ishover'] = true
    },
    mouseLeave(index, arr) { //移出不显示
      this[arr][index]['ishover'] = false
    },
  },
  watch: {
    '$route.query.id'(newValue, oldValue) {
      this.topId = newValue
      this.navname = this.$route.query.name
    }
  },
  components: {
    vueSeamlessScroll
  },
  computed: {
    defaultOption1() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
    defaultOption2() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  }
};
</script>

<style scoped>
a {
  color: white;
}

.select-box {
  display: flex;
  width: 1600px;
  margin: 0 auto;
  justify-content: space-between;
}

.nav {
  width: 100%;
  height: 442px;
}

.bgimg {
  position: relative;
  width: 100%;
  height: 442px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.nav-title {
  position: absolute;
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
  top: 155px;
  left: 240px;
}

.nav-content1 {
  position: absolute;
  color: #ffffff;
  font-size: 15px;
  top: 220px;
  left: 240px;
}

.nav-content2 {
  position: absolute;
  color: #ffffff;
  font-size: 15px;
  top: 250px;
  left: 240px;
}

.select-bottom {
  background-color: rgba(0, 0, 0, 0.337);
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
}

.nav-box {
  color: white !important;
  font-size: 18px;
  line-height: 60px;
  height: 100%;
  padding-right: 10px;
}

/* 大于1700时样式 */
@media screen and (min-width: 1700px) {

  .select-content:hover {
    background-color: #90c31d;
  }

  .select-content {
    font-weight: bold;
    color: white;
    font-size: 18px;
    line-height: 60px;
    padding: 0 20px 0 20px;
    cursor: pointer;
  }



  .select-content-active {
    background-color: #90c31d;
  }

  .img-content1 {
    width: 657px;
    height: 411px;
  }

  .img-content2 {
    width: 679px;
    height: 439px;
  }

  .img {
    width: 165px;
    height: 165px;
    vertical-align: middle;
  }







  .nav-title2 {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 155px;
    /* right: 240px; */
    left: 1200px;
  }



  .nav-content3 {
    position: absolute;
    font-size: 15px;
    top: 220px;
    left: 1200px;
  }

  .nav-content4 {
    position: absolute;
    font-size: 15px;
    top: 250px;
    left: 1200px;
  }

  .black {
    color: black;
  }

  .content-box {
    width: 1600px;
    margin: 0 auto;
  }






  .select-content-active {
    background-color: #90c31d;
  }

  .design {
    width: 100%;
    /* height: 100%; */
  }

  .design-box {
    width: 100%;
    height: 570px;
    background-color: #f5f5f5;
  }

  .box1-left {
    width: 70%;
    height: 570px;
  }

  .box1-right {
    width: 25%;
    height: 570px;
  }

  .box1-content {
    margin-top: 40px;
    /* width: 580px; */
    width: 95%;
    font-size: 18px;
    color: #7b7b7b
  }

  .img-box {
    /* width: 885px; */
    width: 95%;
    height: 200px;
    margin-top: 70px;
    display: flex;
  }

  .img-left {
    flex: 1;
    background-color: white;
    width: 600px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .img-right {
    width: 285px;
    height: 100%;
    background-image: url(https://api.hi-wooya.com/storage/default/pcba/background.9bf304a0.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .img-left-img {
    display: flex;
    justify-content: center;
    margin-left: 125px;
    align-items: center;
  }

  .img-left-content {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .img-left-content2 {
    line-height: 30px;
  }

  .img-right-title {
    font-size: 28px;
    color: white;
    margin-left: 45px;
    margin-top: 50px;
  }

  .img-right-content {
    font-size: 18px;
    color: white;
    margin-left: 45px;
    margin-top: 20px;
  }

  .design2-box {
    width: 100%;
    height: 535px;
  }

  .design2-left2 {
    width: 682px;
    height: 440px;
    /* height: 535px; */
    display: flex;
    align-items: center;
  }

  .design2-left3 {
    width: 679px;
    height: 439px;
    /* width: 50%; */
    display: flex;
    align-items: center;
  }

  .design2-left {
    width: 50%;
    display: flex;
    align-items: center;
  }

  .design2-right2 {
    width: 682px;
    height: 100%;
  }

  .design2-right {
    height: 535px;
    /* width: 50%; */
  }

  .box2-content-box {
    margin-top: 5%;
  }

  .box2-title {
    color: #333333;
    font-size: 15px;
  }

  .box2-content {
    color: #7b7b7b;
    font-size: 13px;
  }

  .design3-box {
    width: 100%;
    height: 500px;
  }

  .design3-left {
    width: 40%;
    height: 500px;
  }

  .design3-right {
    width: 60%;
    height: 500px;
    display: flex;
    align-items: center;
  }

  .design3-img {
    width: 100%;
    height: auto;
  }

  .material {
    width: 100%;
  }

  .material-box1-content {
    width: 100%;
    height: 550px;
    background-image: url(https://api.hi-wooya.com/storage/default/pcba/word.82ed86a5.png);
    margin: 75px auto;
  }

  .box1-title {
    margin-top: 80px;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }


  .box3-title {
    margin-top: 80px;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    /* margin-left: 1px; */
  }

  .meterial-scroll {
    width: 1000px;
    height: 450px;
    margin: 10px auto;
  }

  .scroll-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .scroll-box2 {
    width: 1600px;
    /* height: 286px; */
    overflow: hidden;
  }

  .scroll1-box {
    width: 20%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scroll2-box {
    width: 40%;
    height: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 15px;
    background-color: white;
    margin: 20px;
  }

  .material-box2 {
    width: 100%;
    height: 880px;
    background-color: #f5f5f5;
  }

  .material-title {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .material-box2-content {
    margin-top: 80px;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .box2-img {
    width: 615px;
    height: 265px;
    display: flex;
    align-items: flex-end;
  }

  .box2-img-content {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
    transition: all 0.3s;
  }

  .box2-img-title1 {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
  }

  .box2-img-title2 {
    font-size: 18px;
    margin-top: 10px;
    color: #7b7b7b;
  }

  .material-box3 {
    width: 100%;
    height: 740px;
    overflow: hidden;
    background-image: url(https://api.hi-wooya.com/storage/default/pcba/controlImg.760e532d.jpg);
  }

  .box3-left {
    font-size: 18px;
    color: white;
    width: 50%;
    /* height: 100%; */
    margin-left: 250px;
    margin-top: 230px;
  }

  .box3-right {
    /* height: 740px; */
    height: 100%;
    width: 50%;
  }

  .box3-right-content {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }

  .material-box4 {
    width: 100%;
    height: 700px;
  }

  .material-box4-content {
    width: 70%;
    margin: 80px auto;
    height: 420px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .material-box4-box {
    width: 35%;
    position: relative;
  }

  .material-box4-img {
    position: absolute;
    top: 10px;
  }

  .material-box4-title {
    position: absolute;
    top: 12px;
    left: 60px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }

  .material-box4-title2 {
    position: absolute;
    top: 55px;
    font-size: 16px;
    color: #7b7b7b;
  }

  .PCBA-box1 {
    width: 100%;
    /* height: 650px; */
  }

  .PCBA-box1-content {
    width: 100%;
    height: 380px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
  }

  .PCBA-box1-left {
    width: 50%;
    height: 100%;
  }

  .PCBA-box1-right {
    /* width: 40%; */
    width: 700px;
    height: 100%;
    background-color: #90c31d;
  }

  .PCBA-box1-title {
    font-size: 32px;
    font-weight: bold;
    margin-top: 60px;
    margin-left: 50px;
    color: #333333;
  }

  .PCBA-box1-content2 {
    font-size: 18px;
    color: #7b7b7b;
    margin-left: 50px;
    margin-top: 30px;
  }

  .PCBA-box2-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 80px;
  }

  .PCBAtitle {
    font-size: 32px;
    font-weight: bold;
  }

  .PCBA-box2-box {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .PCBA-box2-box2 {
    width: 100%;
    height: 266px;
    margin-top: 100px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
  }

  .PCBA-box2-box3 {
    width: 100%;
    height: 286px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
  }

  .PCBA-box2-left {
    width: 10%;
    /* height: 100%; */
    text-align: center;
    padding: 20px 0 20px 0;
    background-color: #CED8E4;
  }

  .PCBA-box2-right {
    width: 80%;
    text-align: center;
    padding: 20px 0 20px 0;
    background-color: #f5f5f5;
  }

  .PCBA-box2 {
    width: 100%;
    height: 770px;
    background-color: #f5f5f5;
  }

  .PCBA-box2-box1 {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 30px;
  }

  .box2-pcba-content {
    width: 233px;
    height: 266px;
    margin-left: -20px;
    flex-shrink: 0;
    /* margin-right: 20px; */
  }

  .PCBA-title {
    color: white;
    font-size: 26px;
    margin-left: 40px;
    margin-top: 20px;
  }

  .PCBA-content {
    font-size: 16px;
    color: white;
    margin-left: 30px;
    margin-top: 10px;
  }

  .PCBA-box4 {
    width: 100%;
    background: url(https://api.hi-wooya.com/storage/default/pcba/problemBack.b595d52f.png);
    background-size: 100% 100%;
    height: 580px;

  }

  .PCBA-box4-content {
    width: 80%;
    height: 800px;
    margin: auto;
    margin-top: 20px;
    box-shadow: 0 0px 15px rgba(14, 22, 45, 0.23);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
  }

  .PCBA-box4-box {
    width: 90%;
    height: 125px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #f5f5f5;
  }

  .PCBA-box4-box:hover {
    border-radius: 8px;
    box-shadow: 0 0px 15px rgba(14, 22, 45, 0.23);
  }

  .PCBA-box4-left {
    width: 15%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 2px solid #f5f5f5;
    font-size: 20px;
    color: #333333;
    text-align: center;
  }

  .PCBA-box4-right {
    width: 80%;
    height: 100%;
  }

  .img-pcba {
    margin: 0 auto;
    width: 60px;
    height: 50px;
  }

  .PCBA4-content {
    height: 40px;
    font-size: 20px;
    color: #7b7b7b;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .test1-box {
    width: 100%;
    height: 535px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .test3-box {
    width: 100%;
    height: 670px;
    background: url(https://api.hi-wooya.com/storage/default/pcba/reliabilityTestBack.d0ae7cd3.png);
    padding-top: 80px;
  }

  .test3-content {
    font-size: 18px;
    color: white;
    text-align: center;
  }

  .test3-scroll {
    width: 100%;
    height: 230px;
    margin-top: 180px;
    overflow: hidden;
  }

  .test3-scroll-content {
    display: flex;
    gap: 50px;
    justify-content: flex-start;
  }

  .test3-img {
    width: 310px;
    height: 230px;
  }

  .test4-box {
    width: 100%;
    height: 750px;
    margin-bottom: 45px;
  }

  .test4-content {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
  }

  .test4-left {
    width: 35%;
    display: flex;
    flex-direction: column;
  }

  .test4-right {
    width: 35%;
    display: flex;
    flex-direction: column;
  }

  .test4-left-title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    color: #333333;
    font-weight: bold;
    background-color: #CED8E4;
  }

  .test4-left-content {
    width: 100%;
    height: 24px;
    padding: 20px 0 20px 0;
    text-align: center;
    font-size: 16px;
    color: #333333;
  }

  .box-white {
    height: 430px;
    width: 100%;
  }
}

/* 小于1700时的样式 */

@media screen and (max-width: 1700px) {


  .img {
    width: 123.75px;
    height: 123.75px;
    vertical-align: middle;
  }


  .design2-left2 {
    width: 511.5px;
    height: 330px;
    /* height: 535px; */
    display: flex;
    align-items: center;
  }



  .nav-title2 {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    top: 116.25px;
    left: 900px;
  }


  .nav-content3 {
    position: absolute;
    font-size: 11.25px;
    top: 165px;
    left: 900px;
  }

  .nav-content4 {
    position: absolute;
    font-size: 11.25px;
    top: 187.5px;
    left: 900px;
  }

  .black {
    color: black;
  }

  .content-box {
    width: 1200px;
    margin: 0 auto;
  }


  .select-content-active {
    background-color: #90c31d;
  }

  .design {
    width: 100%;
  }

  .design-box {
    width: 100%;
    height: 427.25px;
    background-color: #f5f5f5;
  }

  .box1-left {
    width: 70%;
    height: 427.25px;
  }

  .box1-right {
    width: 25%;
    height: 427.25px;
  }

  .box1-content {
    margin-top: 30px;
    /* width: 435px; */
    width: 95%;
    font-size: 13.5px;
    color: #7b7b7b
  }

  .img-box {
    /* width: 663.75px; */
    width: 95%;
    height: 150px;
    margin-top: 52.5px;
    display: flex;
  }

  .img-left {
    flex: 1;
    background-color: white;
    width: 450px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .img-right {
    width: 213.75px;
    height: 100%;
    background-image: url(https://api.hi-wooya.com/storage/default/pcba/background.9bf304a0.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .img-left-img {
    display: flex;
    justify-content: center;
    margin-left: 93.75px;
    align-items: center;
  }

  .img-left-content {
    width: 22.5px;
    height: 22.5px;
    line-height: 22.5px;
  }

  .img-left-content2 {
    line-height: 22.5px;
  }

  .img-right-title {
    font-size: 21px;
    color: white;
    margin-left: 33.75px;
    margin-top: 37.5px;
  }

  .img-right-content {
    font-size: 13.5px;
    color: white;
    margin-left: 33.75px;
    margin-top: 15px;
  }

  .design2-box {
    width: 100%;
    height: 401.25px;
  }

  .design2-left3 {
    width: 509.25px;
    height: 329.25px;
    /* width: 50%; */
    display: flex;
    align-items: center;
  }

  .design2-left {
    width: 50%;
    height: 401.25px;
    display: flex;
    align-items: center;
  }

  .design2-right {
    height: 401.25px;
    width: 50%;
  }

  .box2-content-box {
    margin-top: 5%;
  }

  .box2-title {
    color: #333333;
    font-size: 11.25px;
  }

  .box2-content {
    color: #7b7b7b;
    font-size: 13px;
  }

  .design3-box {
    width: 100%;
    height: 375px;
  }

  .design3-left {
    width: 40%;
    height: 375px;
  }

  .design3-right {
    width: 60%;
    height: 375px;
    display: flex;
    align-items: center;
  }

  .design3-img {
    width: 100%;
    height: auto;
  }

  .material {
    width: 100%;
  }

  .material-box1-content {
    width: 100%;
    height: 412.5px;
    background-image: url(https://api.hi-wooya.com/storage/default/pcba/word.82ed86a5.png);
    margin: 56.25px auto;
  }

  .box1-title {
    margin-top: 60px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }

  .box3-title {
    margin-top: 60px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }

  .meterial-scroll {
    width: 750px;
    height: 337.5px;
    margin: 7.5px auto;
  }

  .scroll-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .scroll-box2 {
    width: 1200px;
    height: 214.5px;
    overflow: hidden;
  }

  .scroll1-box {
    width: 20%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scroll2-box {
    width: 40%;
    height: 150px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 11.25px;
    background-color: white;
    margin: 15px;
  }

  .material-box2 {
    width: 100%;
    height: 660px;
    background-color: #f5f5f5;
  }

  .material-title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 15px;
  }

  .material-box2-content {
    margin-top: 60px;
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  .box2-img {
    width: 461.25px;
    height: 198.75px;
    display: flex;
    align-items: flex-end;
  }

  .box2-img-content {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 37.5px;
    padding-right: 37.5px;
    box-sizing: border-box;
    transition: all 0.3s;
  }

  .box2-img-title1 {
    font-weight: bold;
    font-size: 13.5px;
    color: #333333;
  }

  .box2-img-title2 {
    font-size: 13.5px;
    margin-top: 7.5px;
    color: #7b7b7b;
  }

  .material-box3 {
    width: 100%;
    height: 555px;
    overflow: hidden;
    background-image: url(https://api.hi-wooya.com/storage/default/pcba/controlImg.760e532d.jpg);
  }

  .box3-left {
    font-size: 13.5px;
    color: white;
    width: 50%;
    /* height: 100%; */
    margin-left: 187.5px;
    margin-top: 172.5px;
  }

  .box3-right {
    /* height: 740px; */
    height: 100%;
    width: 50%;
  }

  .box3-right-content {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #333333;
  }

  .material-box4 {
    width: 100%;
    height: 525px;
  }

  .design2-right2 {
    width: 511.5px;
    height: 100%;
  }

  .material-box4-content {
    width: 70%;
    margin: 60px auto;
    height: 315px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .material-box4-box {
    width: 35%;
    position: relative;
  }

  .material-box4-img {
    position: absolute;
    top: 7.5px;
  }

  .material-box4-title {
    position: absolute;
    top: 9px;
    left: 45px;
    font-size: 13.5px;
    font-weight: bold;
    color: #333333;
  }

  .material-box4-title2 {
    position: absolute;
    top: 41.25px;
    font-size: 12px;
    color: #7b7b7b;
  }

  .PCBA-box1 {
    width: 100%;
    /* height: 487.5px; */
  }

  .PCBA-box1-content {
    width: 100%;
    height: 285px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
  }

  .PCBA-box1-left {
    width: 50%;
    height: 100%;
  }

  .PCBA-box1-right {
    /* width: 40%; */
    width: 525px;
    height: 100%;
  }

  .PCBA-box1-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 45px;
    margin-left: 37.5px;
    color: #333333;
  }

  .PCBA-box1-content2 {
    font-size: 13.5px;
    color: #7b7b7b;
    margin-left: 37.5px;
    margin-top: 22.5px;
  }

  .PCBA-box2-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }

  .PCBAtitle {
    font-size: 24px;
    font-weight: bold;
  }

  .PCBA-box2-box {
    width: 100%;
    margin-bottom: 3.75px;
    display: flex;
    justify-content: center;
  }

  .PCBA-box2-box2 {
    width: 100%;
    height: 199.5px;
    margin-top: 75px;
    margin-bottom: 3.75px;
    display: flex;
    justify-content: flex-start;
  }

  .PCBA-box2-box3 {
    width: 100%;
    height: 214.5px;
    margin-bottom: 3.75px;
    display: flex;
    justify-content: flex-start;
  }

  .PCBA-box2-left {
    width: 10%;
    text-align: center;
    padding: 15px 0 15px 0;
    background-color: #CED8E4;
  }

  .PCBA-box2-right {
    width: 80%;
    text-align: center;
    padding: 15px 0 15px 0;
    background-color: #f5f5f5;
  }

  .PCBA-box2 {
    width: 100%;
    height: 577.5px;
    background-color: #f5f5f5;
  }

  .PCBA-box2-box1 {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 22.5px;
  }

  .box2-pcba-content {
    width: 167.25px;
    height: 199.5px;
    margin-left: -15px;
    flex-shrink: 0;
  }

  .PCBA-title {
    color: white;
    font-size: 19.5px;
    margin-left: 30px;
    margin-top: 15px;
  }

  .PCBA-content {
    font-size: 12px;
    color: white;
    margin-left: 22.5px;
    margin-top: 7.5px;
  }

  .PCBA-box4 {
    width: 100%;
    background: url(https://api.hi-wooya.com/storage/default/pcba/problemBack.b595d52f.png);
    background-size: 100% 100%;
    height: 435px;

  }

  .PCBA-box4-content {
    width: 80%;
    height: 600px;
    margin: auto;
    margin-top: 15px;
    box-shadow: 0 0px 11.25px rgba(14, 22, 45, 0.23);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
  }

  .PCBA-box4-box {
    width: 90%;
    height: 93.75px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #f5f5f5;
  }

  .PCBA-box4-box:hover {
    border-radius: 6px;
    box-shadow: 0 0px 11.25px rgba(14, 22, 45, 0.23);
  }

  .PCBA-box4-left {
    width: 15%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 2px solid #f5f5f5;
    font-size: 15px;
    color: #333333;
    text-align: center;
  }

  .PCBA-box4-right {
    width: 80%;
    height: 100%;
  }

  .img-pcba {
    margin: 0 auto;
    width: 45px;
    height: 37.5px;
  }

  .PCBA4-content {
    height: 30px;
    font-size: 15px;
    color: #7b7b7b;
    margin-left: 30px;
    margin-top: 15px;
    margin-bottom: 7.5px;
  }

  .test1-box {
    width: 100%;
    height: 401.25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .test3-box {
    width: 100%;
    height: 502.5px;
    background: url(https://api.hi-wooya.com/storage/default/pcba/reliabilityTestBack.d0ae7cd3.png);
    padding-top: 60px;
  }

  .test3-content {
    font-size: 13.5px;
    color: white;
    text-align: center;
  }

  .test3-scroll {
    width: 100%;
    height: 172.5px;
    margin-top: 135px;
    overflow: hidden;
  }

  .test3-scroll-content {
    display: flex;
    gap: 37.5px;
    justify-content: flex-start;
  }

  .test3-img {
    width: 232.5px;
    height: 172.5px;
  }

  .test4-box {
    width: 100%;
    height: 562.5px;
    margin-bottom: 33.75px;
  }

  .test4-content {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 37.5px;
  }

  .test4-left {
    width: 35%;
    display: flex;
    flex-direction: column;
  }

  .test4-right {
    width: 35%;
    display: flex;
    flex-direction: column;
  }

  .test4-left-title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    background-color: #CED8E4;
  }


  .test4-left-content {
    width: 100%;
    height: 18px;
    padding: 15px 0 15px 0;
    text-align: center;
    font-size: 12px;
    color: #333333;
  }



  .select-content:hover {
    background-color: #90c31d;
  }

  .select-content {
    font-weight: bold;
    color: white;
    font-size: 18px;
    line-height: 60px;
    padding: 0 20px 0 20px;
    cursor: pointer;
  }

  .box-white {
    height: 322.5px;
    width: 100%;
  }

  .img-content2 {
    width: 509.25px;
    height: 329.25px;
  }

  .img-content1 {
    width: 492.75px;
    height: 308.25px;
  }
}

@media screen and (max-width: 1200px) {
  .nav {
    width: 1200px;
  }
  .design-box {
    width: 1200px;
  }
  .material-box2 {
    width: 1200px;
  }
  .material-box3 {
    width: 1200px;
  }
  .material-box4 {
    width: 1200px;
  }
  .PCBA-box1{
    width: 1200px;
  }
  .PCBA-box2{
    width: 1200px;
  }
  .PCBA-box4{
    width: 1200px;
  }
  .PCBA-box2-box1 {
    width: 1200px;
    overflow: hidden;
  }
  .PCBA-box2-box2 {
    width: 1200px;
  }
  .test1-box {
    width: 1200px;
  }
  .test3-box {
    width: 1200px;
  }
  .test4-box {
    width: 1200px;
  }

}
</style>