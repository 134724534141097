<template>
    <div>
        <!-- 头部导航 -->
        <nav-header></nav-header>
        <!-- 顶部图片 -->
        <div class="banner-img">
            <div class="swiper">
                <!-- 轮播图中间内容 -->
                <div class="swiper-content">欢迎使用无涯服务支持</div>
                <div class="swiper-btn">
                    <input @keyup.enter="searchAll()" class="btn-input" v-model="search_value" type="text"
                        placeholder="请输入搜索内容">
                    <div @click="searchAll()" class="btn"><i class="iconfont icon-sousuo1 i-size"></i></div>
                </div>
            </div>
        </div>
        <div class="content-box">
            <div class="box1">
                为您找到相关搜索结果：资料下载{{ search_result_product.length }}条 解决方案{{ search_result_scheme.length }}条
            </div>
            <!-- 资料下载内容 -->
            <div v-if="search_result_product.length > 0">
                <div class="title">
                    资料下载
                </div>
                <div>
                    <div v-for="(item, index) in search_result_product" :key="index" class="box2">
                        <div @click="openpages('ProductDetail', item.id)">
                            <span>{{ item.title }}</span>
                        </div>
                        <div style="color: #7b7b7b;">{{ item.create_time }}</div>
                    </div>
                </div>
            </div>
            <!-- 解决方案内容 -->
            <div v-if="search_result_scheme.length > 0">
                <div class="title">
                    解决方案
                </div>
                <div>
                    <div v-for="(item, index) in search_result_scheme" :key="index" class="box2">
                        <div @click="openpages('SolutionCenter', item.id)">
                            <span>{{ item.title }}</span>
                        </div>
                        <div style="color: #7b7b7b;">{{ item.create_time }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 页脚 -->
        <nav-footer></nav-footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search_value: '',
            search_result_product: [], //搜索结果 资料下载
            search_result_scheme: [], //搜索结果 解决方案
        }
    },
    created() {
        this.search_value = this.$route.query.id
        this.searchAll()
    },
    methods: {
        //跳转页面
        openpages(path, id) {
            this.$js.openpages(path, id);
        },
        // 搜索功能
        async searchAll() {
            if (!this.search_value) {
                return
            }
            // 全局搜索
            let res = await this.$apiFun.searchAll({
                search_value: this.search_value
            });
            this.search_result_scheme = res.result.scheme
            this.search_result_product = res.result.product
        },
    }
}
</script>

<style scoped>
.swiper {
    width: 100%;
    height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url(https://api.hi-wooya.com/storage/default/20220725094942.jpg);
    gap: 30px;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.swiper-content {
    font-size: 32px;
    font-weight: bold;
    color: white;
    margin: 0 auto;
}

.swiper-btn {
    margin: 0 auto;
    display: flex;
}

.btn-input {
    width: 300px;
    height: 34px;
    padding: 0 0 0 10px;
    border: none;
    outline: none;
}

.btn {
    border: none;
    height: 34px;
    width: 39px;
    color: white;
    background-color: #90c31d;
    cursor: pointer;
    text-align: center;
    line-height: 34px;
}

.i-size {
    font-size: 20px;
}

/* 大于1700时样式 */
@media screen and (min-width: 1700px) {
    .content-box {
    width: 1600px;
    margin: 80px auto;
}

.box1 {
    font-size: 18px;
}

.title {
    /* width: 100%; */
    margin-top: 40px;
    font-size: 19px;
    padding: 10px 0 10px 10px;
    font-weight: bold;
    background-color: #dcdcdc;
}

.box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    cursor: pointer;
    color: #012f55;
}
}

/* 小于1700时样式 */
@media screen and (max-width: 1700px) {
    .content-box {
    width: 1200px;
    margin: 60px auto;
}

.box1 {
    font-size: 13.5px;
}

.title {
    /* width: 100%; */
    margin-top: 30px;
    font-size: 14.25px;
    padding: 7.5px 0 7.5px 7.5px;
    font-weight: bold;
    background-color: #dcdcdc;
}

.box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    cursor: pointer;
    color: #012f55;
}
}


</style>