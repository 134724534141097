<template>
    <div>
        <!-- 头部导航 -->
        <nav-header></nav-header>
        <!-- 轮播图 -->
        <div v-if="rotationList.length > 0" class="swiper"
            :style="{ 'background-image': 'url(' + 'https://api.hi-wooya.com/storage/' + rotationList[0]['img'] + ')' }">
            <div class="swiper-title">
                解决方案
            </div>
            <div class="swiper-content">
                提供智慧交通、智能移动、智能连接、智慧牧场、智能工业等多行业解决方案
            </div>
            <div class="swiper-content">
                用联网的软件和硬件技术，助力客户实现物联网化升级
            </div>
            <div class="newsClassifyList-box">
                <div class="nav-box">
                    <a href="/">首页</a>>解决方案>{{ this.navname }}>{{ title }}
                </div>
            </div>
        </div>
        <!-- 详情 -->
        <div class="solution-content">
            <div class="title">
                {{ title }}
            </div>
            <div class="content">
                {{ deteFormat(create_time) }}
            </div>
            <!-- 新闻内容 -->
            <div class="richtext" v-html="content"></div>
        </div>

        <!-- 页脚 -->
        <nav-footer></nav-footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 轮播图
            rotationList: [],
            //内容详情
            title: '',
            content: '',
            create_time: '',
        }
    },
    created() {
        this.navname = this.$route.query.type
        this.getswiper()
        this.getsolution(this.$route.query.id)
    },
    methods: {
        // 获取轮播图
        async getswiper() {
            let res = await this.$apiFun.rotationList({
                classify_id: 4
            });
            this.rotationList = res.result;
        },
        // 获取新闻列表
        async getsolution(id) {
            let res = await this.$apiFun.schemeDetail({ id });
            let {
                title,
                content,
                create_time
            } = res.result
            this.title = title
            this.content = content
            this.create_time = create_time
        },
        deteFormat(value) {
            return value.substring(0, 10);
        }
    },
    computed: {

    },
}
</script>

<style scoped>
a {
    color: white;
}

.newsClassifyList-box {
    background-color: rgba(0, 0, 0, 0.337);
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 60px;
    display: flex;
}

.nav-box {
    color: white;
    font-size: 18px;
    line-height: 60px;
    padding: 0 20px 0 20px;
    position: absolute;
    height: 100%;
    right: 130px;
    bottom: 0px;
}

.swiper {
    position: relative;
    width: 100%;
    height: 442px;
    /* padding-left: 300px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.swiper-title {
    margin-left: 300px;
    width: fit-content;
    font-size: 32px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
}

.swiper-content {
    margin-left: 300px;
    width: fit-content;
    font-size: 15px;
    color: white;
    margin-bottom: 10px;
}

/* 大于1700时样式 */
@media screen and (min-width: 1700px) {
    .solution-content {
        width: 1600px;
        margin: 80px auto;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
    }

    .content {
        color: #7b7b7b;
        height: 20px;
        line-height: 20px;
        font-size: 15px;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: #dee2e6;
        text-align: center;
        margin-top: 50px;
        padding-bottom: 35px;
    }

    .richtext:deep(.ql-align-center) {
        text-align: center;
    }

    .richtext:deep(h1) {
        font-size: 20px;
    }

    .richtext:deep(h2) {
        font-size: 18px;
    }

    .richtext:deep(h3) {
        font-size: 16px;
        font-weight: normal;
    }

    .richtext:deep(h4) {
        font-size: 16px;
        font-weight: normal;
    }

    .richtext:deep(h5) {
        font-size: 16px;
        font-weight: normal;
    }

    .richtext:deep(h6) {
        font-size: 16px;
        font-weight: normal;
    }

    .richtext:deep(.ql-syntax) {
        font-size: 16px;
        color: white;
        background-color: black;
    }

    .richtext:deep(.ql-indent-1) {
        padding-left: 0px !important;
    }

    .richtext:deep(p) {
        white-space: pre-wrap;
    }
}

/* 小于1700时样式 */
@media screen and (max-width: 1700px) {
    .solution-content {
        width: 1200px;
        margin: 60px auto;
    }

    .title {
        font-size: 22.5px;
        font-weight: bold;
        text-align: center;
    }

    .content {
        color: #7b7b7b;
        height: 15px;
        line-height: 15px;
        font-size: 11.25px;
        border-bottom-width: 1.5px;
        border-bottom-style: solid;
        border-bottom-color: #dee2e6;
        text-align: center;
        margin-top: 37.5px;
        padding-bottom: 26.25px;
    }

    .richtext:deep(.ql-align-center) {
        text-align: center;
    }

    .richtext:deep(h1) {
        font-size: 15px;
    }

    .richtext:deep(h2) {
        font-size: 13.5px;
    }

    .richtext:deep(h3) {
        font-size: 12px;
    }

    .richtext:deep(h4) {
        font-size: 12px;
    }

    .richtext:deep(h5) {
        font-size: 12px;
    }

    .richtext:deep(h6) {
        font-size: 12px;
    }

    .richtext:deep(p) {
        font-size: 12px;
        white-space: pre-wrap;
    }

    .richtext:deep(.ql-syntax) {
        font-size: 12px;
        background-color: black;
        color: white;
    }

    .richtext:deep(.ql-indent-1) {
        padding-left: 0px !important;
    }
}
@media screen and (max-width: 1200px) {
  .swiper {
    width: 1200px;
  }
}
</style>