<template>
  <div>
    <div class="footer">
      <div class="footer-box">
        <!-- 左 -->
        <div class="footer-left">
          <!-- LOGO -->
          <div class="left-box1">
            <a href="http://WWW.hi-wooya.com/" title="无涯智能官网--无涯智能集研发、生产、销售于一体，主营无线联网通讯模块和设备">
              <img class="logo-img" src="../assets/img/Logo.png" alt="无涯智能官网--无涯智能集研发、生产、销售于一体，主营无线联网通讯模块和设备"
                border="0" />
            </a>
          </div>
          <!-- 文字 -->
          <div class="left-box2">
            <div class="box2-son1">
              {{ this.companyInfo.desc }}
            </div>
            <div class="box2-son2">
              电话：{{ this.companyInfo.tel_phone }} <br>
              邮箱：{{ this.companyInfo.email }}
            </div>
          </div>
        </div>
        <!-- 右 -->
        <div class="footer-right">
          <div class="right-box" v-for="(item, index) in List" :key="index">
            <div class="title">{{ item.name }}</div>
            <div class="content"
              v-for="(item, index) in item.children" :key="index">
              <span style="cursor: pointer;" @click="openpages(item.path, item.id, item.type, item.name)">{{ item.name }}</span></div>
          </div>
        </div>
      </div>
      <div class="footer-box2">
        <div class="down">版权所有©{{ this.companyInfo.name }}( {{ this.companyInfo.record_no }})</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-footer",
  data() {
    return {
      List: [
        {
          name: "PCBA服务",
          children: [
            {
              name: "电子产品设计",
              path: "/PCBA",
              id: 1
            },
            {
              name: "电子物料代购",
              path: "/PCBA",
              id: 2
            },
            {
              name: "PCBA加工",
              path: "/PCBA",
              id: 3
            },
            {
              name: "组装测试",
              path: "/PCBA",
              id: 4
            }
          ]
        },
        {
          name: "服务支持",
          children: [
            {
              name: "资料下载",
              path: "/ServiceSupport",
              id: 1
            },
            {
              name: "解决方案",
              path: "/ServiceSupport",
              id: 2
            },
            {
              name: "技术支持",
              path: "/ServiceSupport",
              id: 3
            },
            {
              name: "售后服务",
              path: "/ServiceSupport",
              id: 4
            },
            {
              name: "代理商申请",
              path: "/ContactUs",
              id: 8
            },
          ]
        },
        {
          name: "新闻中心",
          children: [
            {
              name: "行业资讯",
              path: "/NewsCenter",
              id: 229
            },
            {
              name: "常见问题",
              path: "/NewsCenter",
              id: 231
            },
            {
              name: "新品发布",
              path: "/NewsCenter",
              id: 230
            },
            {
              name: "公司新闻",
              path: "/NewsCenter",
              id: 228
            }
          ]
        },
        {
          name: "联系我们",
          children: [
            {
              name: "关于无涯",
              path: "/ContactUs",
              id: 1,
              type: 'wooya_real_scene'
            },
            {
              name: "关于品质",
              path: "/ContactUs",
              id: 2,
              type: 'quality'
            },
            {
              name: "联系我们",
              path: "/ContactUs",
              id: 3,
              type: 'contactUs'
            },
            {
              name: "企业资质",
              path: "/ContactUs",
              id: 4,
              type: 'qualifications'
            },
            {
              name: "无涯团队",
              path: "/ContactUs",
              id: 5,
              type: 'team'
            },
            {
              name: "加入无涯",
              path: "/ContactUs",
              id: 6,
              type: ''
            }
          ]
        }
      ],
      companyInfo: [],
    };
  },
  created() {
    this.getcompany()
  },
  methods: {
    //跳转页面
    openpages(path, id, type, name) {
      this.$js.openpages(path, id, type, name);
    },
    async getcompany() {
      //获取公司信息
      let res = await this.$apiFun.companyInfo({});
      this.companyInfo = res.result;
    },
  }
};
</script>

<style scoped>
.footer {
  width: 100%;
  height: 380px;
  background-color: #ededed;
}

.down {
  text-align: center;
  margin: auto;
  height: 56px;
  font-size: 18px;
  color: #7b7b7b;
  line-height: 56px;
}

.footer-box2 {
  height: 56px;
  width: 100%;
  background-color: #e8e8e8;
}

.footer-box {
  margin: 0 auto;
  height: 100%;
  width: 1600px;
  display: flex;
}

.footer-left {
  height: 100%;
  width: 40.25%;
}

.footer-right {
  height: 100%;
  width: 59.75%;
  display: flex;
}

.logo-img {
  width: 183px;
  height: 80px;
  margin-top: 20px;
}

.left-box1 {
  width: 100%;
  height: 29.84%;
}

.left-box2 {
  width: 100%;
  height: 70.16%;
}

.box2-son1 {
  width: 423px;
  font-size: 18px;
  color: #7b7b7b;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box2-son2 {
  width: 360px;
  height: 90px;
  margin-top: 85px;
  font-size: 18px;
  color: #7b7b7b;
  line-height: 24px;
}

.right-box {
  width: 27.2%;
  height: 100%;
}

.title {
  font-weight: bold;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #333333;
}

.content {
  font-size: 18px;
  margin-bottom: 10px;
  color: #7b7b7b;
}

@media screen and (max-width: 1700px) {
  .footer {
    position: relative;
    width: 100%;
    height: 285px;
    background-color: #ededed;
  }

  .down {
    /* width: 375px; */
    margin: 0 auto;
    height: 42px;
    font-size: 13.5px;
    line-height: 42px;
    color: #7b7b7b;
    /* background-color: e8e8e8; */
  }

  .footer-box2 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 42px;
    width: 100%;
    background-color: #e8e8e8;
  }

  .footer-box {
    margin: 0 auto;
    height: 100%;
    width: 1200px;
    display: flex;
  }

  .footer-left {
    height: 100%;
    width: 40.25%;
  }

  .footer-right {
    height: 100%;
    width: 59.75%;
    display: flex;
  }

  .logo-img {
    width: 137.25px;
    height: 60px;
    margin-top: 15px;
  }

  .left-box1 {
    width: 100%;
    height: 29.84%;
  }

  .left-box2 {
    width: 100%;
    height: 70.16%;
  }

  .box2-son1 {
    width: 310px;
    /* height: 78.75px; */
    font-size: 13.5px;
    color: #7b7b7b;
    line-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .box2-son2 {
    width: 285px;
    height: 67.5px;
    margin-top: 58.75px;
    font-size: 13.5px;
    color: #7b7b7b;
    line-height: 18px;
  }

  .right-box {
    width: 27.2%;
    height: 100%;
  }

  .title {
    font-size: 13.5px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #333333;
  }

  .content {
    font-size: 13.5px;
    margin-bottom: 6px;
    color: #7b7b7b;
    cursor: pointer;
  }
}
@media screen and (max-width: 1200px) {
  .footer {
    position: relative;
    width: 1200px;
    height: 285px;
    background-color: #ededed;
  }
}
</style>