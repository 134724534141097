<template>
  <div>
    <!-- 头部导航 -->
    <nav-header></nav-header>
    <!-- 轮播图 -->
    <div class="swiper-box">
      <div style="width: 100%;">
        <el-carousel :interval="3000" arrow="hover" :height="bannerHeight + 'px'">
          <el-carousel-item v-for="item in recommend_list" :key="item.id">
            <a :href="item.link">
              <img class="swiper1" :src="'https://api.hi-wooya.com/storage/' + item.img" />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="content video_content" style="background-color: #f5f5f5;">
      <!-- 视频区域 -->
      <div class="content-video">
        <div class="content-video-son">
          <!-- 左边内容 -->
          <div>
            <video class="content-video-left" poster="../assets/img/Video.jpg" controls
              src="../assets/img/Index.mp4"></video>
          </div>
          <!-- 右边内容 -->
          <div class="content-video-right">
            <div class="video-text1">{{ this.companyInfo.name }}</div>
            <div class="video-text2">{{ this.companyInfo.desc }}</div>
            <div class="video-text3">
              <div class="text-son">
                <img class="text-son-img" src="../assets/img/Agree.png" />
                <div class="text-son-text1">通讯终端</div>
                <div class="text-son-text2">TERMINAL</div>
              </div>
              <div class="text-son">
                <img class="text-son-img" src="../assets/img/Agree.png" />
                <div class="text-son-text1">通讯模块</div>
                <div class="text-son-text2">MODULE</div>
              </div>
              <div class="text-son">
                <img class="text-son-img" src="../assets/img/Agree.png" />
                <div class="text-son-text1">ODM/OEM</div>
                <div class="text-son-text2">Oreder Service</div>
              </div>
            </div>
            <div class="video-text4">地址：{{ this.companyInfo.address }}</div>
            <div class="video-text5">电话：{{ this.companyInfo.tel_phone }} &nbsp; Email：{{ this.companyInfo.email }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下方内容部分 -->
    <div class="content">
      <!-- 热卖专区 -->
      <div class="content-sale">
        <!-- 标题部分 -->
        <div class="content-title">
          <div class="title1">SELL LIKE HOT CAKES</div>
          <div class="title2">热卖专区</div>
          <div class="title3">一</div>
        </div>
        <!-- 热门商品 -->
        <div class="content-sale-goods" v-if="productList1.length > 0">
          <!-- 左边部分 -->
          <div class="sale-goods-left">
            <div class="goods-box1">
              <a :href="productList1[0]['link']">
                <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + productList1[0]['img']" />
              </a>
            </div>
            <div class="goods-box2">
              <a :href="productList1[3]['link']">
                <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + productList1[3]['img']" />
              </a>
            </div>
          </div>
          <!-- 右边部分 -->
          <div class="sale-goods-right" v-if="productList2.length > 0">
            <div class="goods-box3">
              <a :href="productList1[1]['link']">
                <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + productList1[1]['img']" />
              </a>
            </div>
            <div class="goods-box4">
              <a :href="productList1[2]['link']">
                <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + productList1[2]['img']" />
              </a>
            </div>
            <div class="goods-box4">
              <a :href="productList2[0]['link']">
                <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + productList2[0]['img']" />
              </a>
            </div>
            <div class="goods-box4">
              <a :href="productList2[1]['link']">
                <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + productList2[1]['img']" />
              </a>
            </div>
            <div class="goods-box4">
              <a :href="productList2[2]['link']">
                <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + productList2[2]['img']" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案一 -->
    <div style="background-color: #f5f5f5;width:100%">
      <div class="content">
        <div class="content-solution1">
          <!-- 标题部分 -->
          <div class="content-title">
            <div class="title1">TOTAL SOLUTION</div>
            <div class="title2">解决方案</div>
            <div class="title3">一</div>
          </div>
          <!-- 内容部分 -->
          <div class="solution1-content" v-if="solutionList.length > 0">
            <!-- 左边部分 -->
            <div class="solution1-left">
              <a :href="solutionList[0]['link']">
                <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + solutionList[0]['cover']" alt />
                <div class="solution1-box">
                  <p class="solution1-box-title">{{ solutionList[0]['title'] }}</p>
                  <p class="solution1-box-content">{{ solutionList[0]['sub_title'] }}</p>
                </div>
              </a>
            </div>
            <!-- 右边部分 -->
            <div class="solution1-right">
              <div class="solution1-box1">
                <a :href="solutionList[1]['link']">
                  <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + solutionList[1]['cover']" alt />
                  <div class="solution1-top-box">
                    <p class="solution1-box-title2">{{ solutionList[1]['title'] }}</p>
                    <p class="solution1-box-content2">{{ solutionList[1]['sub_title'] }}</p>
                  </div>
                </a>
              </div>
              <div style="display: flex;flex-wrap: nowrap;">
                <div class="solution1-box2">
                  <a :href="solutionList[2]['link']">
                    <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + solutionList[2]['cover']"
                      alt />
                    <div class="solution1-down-box">
                      <p class="solution1-box-title3">{{ solutionList[2]['title'] }}</p>
                      <p class="solution1-box-content3">{{ solutionList[2]['sub_title'] }}</p>
                    </div>
                  </a>
                </div>
                <div class="solution1-box3">
                  <a :href="solutionList[3]['link']">
                    <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + solutionList[3]['cover']"
                      alt />
                    <div class="solution1-down-box">
                      <p class="solution1-box-title3">{{ solutionList[3]['title'] }}</p>
                      <p class="solution1-box-content3">{{ solutionList[3]['sub_title'] }}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 常见问题 -->
        <div class="content-solution2">
          <!-- 标题部分 -->
          <div class="content-title">
            <div class="title1">FAQ</div>
            <div class="title2">常见问题</div>
            <div class="title3">一</div>
          </div>
          <div class="solution2-content">
            <div class="solution2-box" v-for="item in successList" :key="item.id">
              <a :href="item.link">
                <div class="solution2-img">
                  <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + item['cover']" />
                </div>
                <div class="solution2-text">
                  <div class="solution2-text-title">{{ item['title'] }}</div>
                  <div class="solution2-text-content">{{ item['sub_title'] }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- 新闻中心 -->
        <div class="content-solution3">
          <!-- 标题部分 -->
          <div class="content-title">
            <div class="title1">NEWS CENTER</div>
            <div class="title2">新闻中心</div>
            <div class="title3">一</div>
          </div>
          <div class="solution3-content">
            <!-- 内容左边 -->
            <div class="solution3-left">
              <img class="content-img" :src="'https://api.hi-wooya.com/storage/' + cover" />
            </div>
            <!-- 内容右边 -->
            <div class="solution3-right">
              <div class="solution3-box" v-for="item in newList"
                :class="cover_id == item.id ? 'solution3-box-hover' : ''" :key="item.id"
                @mouseover="mouseEnter(item.id, item.cover)" @mouseleave="mouseLeave()" @click="skipLink(item.link)">
                <!-- 序号 -->
                <div class="solution3-order" :class="cover_id == item.id ? 'solution3-order-hover' : ''">{{ item.order
                  }}
                </div>
                <div class="box-right">
                  <!-- 标题 -->
                  <div class="solution3-title">{{ item.title }}</div>
                  <!-- 内容 -->
                  <div class="solution3-content2">{{ item.sub_title }}</div>
                  <!-- 时间 -->
                  <div class="solution3-time">{{ item.update_time }}</div>
                  <!-- 箭头 -->
                  <div v-if="cover_id === item.id" class="solution3-right2">
                    <img src="../assets/img/Right2.png" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyInfo: [],
      //轮播图高度
      bannerHeight: 0,
      //屏幕高度
      screenWidth: 0,
      recommend_list: [],
      // 结局方案
      solutionList: [],
      //成功案例
      successList: [],
      // 产品图
      productList1: [],
      productList2: [],
      // 新闻中心左侧图片
      cover: {},
      newList: [],
      // 控制箭头显示
      cover_id: 1
    };
  },
  async created() {
    this.getswpier();
    this.getsolution();
    this.getsuccess();
    this.getnews();
    this.getproduct();
    this.getcompany()
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1700) {
      this.bannerHeight = 666;
    } else {
      this.bannerHeight = 499.5;
    }
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (666 / 1920) * this.screenWidth;
      if (this.bannerHeight < 437.5) {
        this.bannerHeight = 437.5;
      }
    };
  },
  methods: {
    async getcompany() {
      //获取公司信息
      let res = await this.$apiFun.companyInfo({});
      this.companyInfo = res.result;
    },
    async getswpier() {
      //获取首页轮播图
      let res = await this.$apiFun.getRecommendListByClassify({
        classify_id: 1
      });
      this.recommend_list = res.result;
    },
    async getproduct() {
      //获取产品图
      let res1 = await this.$apiFun.getRecommendListByClassify({
        classify_id: 2
      });
      this.productList1 = res1.result;
      let res2 = await this.$apiFun.getRecommendListByClassify({
        classify_id: 3
      });
      this.productList2 = res2.result;
    },
    async getsolution() {
      //获取首页轮播图
      // 获取首页解决方案列表，首页推荐成功案例
      let res = await this.$apiFun.homeRecommends({ type: 1 });
      this.solutionList = res.result;
    },
    async getsuccess() {
      // 获取首页解决方案列表，首页推荐成功案例
      let res = await this.$apiFun.homeRecommends({ type: 2 });
      this.successList = res.result;
    },
    async getnews() {
      // 获取新闻
      let res = await this.$apiFun.recommendNewsList({});
      this.newList = res.result;
      this.cover = this.newList[0]["cover"];
      for (let index in this.newList) {
        this.newList[index]["order"] = "0" + this.newList[index]["id"];
      }
    },

    skipLink(link) {
      window.open(link, "_self");
    },

    mouseEnter(id, cover) {
      //移进显示
      this.cover_id = id;
      this.cover = cover;
    },
    mouseLeave() {
      //移出不显示
      // this.cover_id = 4;
    }
  }
};
</script>

<style scoped>
/* 大于1700px时的样式 */
@media screen and (min-width: 1700px) {
  .swiper1 {
    width: 100%;
    height: 100%;
  }

  .title1 {
    letter-spacing: 3px;
    font-size: 20px;
    color: #7b7b7b;
  }

  .title2 {
    font-size: 40px;
    color: #333333;
  }

  .title3 {
    letter-spacing: 0px;
    line-height: 40px;
    font-size: 40px;
    color: #333333;
  }

  .content {
    margin: 0 auto;
    width: 1600px;
  }

  .content-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content-video {
    width: 100%;
    height: 837px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-video-son {
    width: 1600px;
    height: 645px;
    display: flex;
  }

  .content-video-left {
    height: 645px;
    width: 1022px;
    object-fit: cover;
    opacity: 0.92;
  }

  .goods-box1:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .goods-box2:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .goods-box3:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .goods-box4:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .solution1-left:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .content-video-right {
    position: relative;
    width: 667px;
    height: 100%;
    background-color: white;
  }

  .video-text1 {
    position: absolute;
    font-size: 36px;
    top: 110px;
    left: 40px;
    color: #333333;
  }

  .video-text2 {
    position: absolute;
    font-size: 18px;
    width: 512.3px;
    letter-spacing: 0.9px;
    top: 180px;
    left: 40px;
    color: #7b7b7b;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .video-text3 {
    position: absolute;
    top: 310px;
    left: 40px;
    color: #7b7b7b;
    display: flex;
  }

  .text-son {
    margin-top: 30px;
    position: relative;
    width: 170px;
    height: 40px;
  }

  .text-son-img {
    position: absolute;
    left: 0px;
    width: 33px;
    height: 33px;
  }

  .text-son-text1 {
    position: absolute;
    /* font-size: ; */
    top: -8px;
    left: 50px;
    font-size: 25px;
    color: #333333;
  }

  .text-son-text2 {
    position: absolute;
    top: 20px;
    left: 50px;
    font-size: 12px;
    color: #7b7b7b;
  }

  .video-text4 {
    position: absolute;
    font-size: 18px;
    bottom: 133px;
    left: 40px;
    color: #7b7b7b;
  }

  .video-text5 {
    position: absolute;
    font-size: 18px;
    bottom: 92px;
    left: 40px;
    color: #7b7b7b;
  }

  .content-title {
    text-align: center;
    padding-top: 24px;
    width: 100%;
    height: 171px;

  }

  .content-sale {
    width: 100%;
    height: 1196px;
  }

  .content-sale-goods {
    width: 100%;
    height: 905px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
  }

  .sale-goods-left {
    height: 100%;
    width: 394px;
    display: flex;
    flex-wrap: wrap;
  }

  .sale-goods-right {
    width: 1198px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .goods-box1 {
    width: 394px;
    height: 689px;
  }

  .goods-box2 {
    width: 394px;
    height: 216px;
  }

  .goods-box3 {
    width: 796px;
    height: 448.5px;
  }

  .goods-box4 {
    width: 394px;
    height: 448.5px;
  }
  .video_content{
    width: 100%;
  }

  .content-solution1 {
    background-color: #f5f5f5;
    width: 100%;
    height: 791px;
  }

  .solution1-content {
    width: 100%;
    height: 500px;
    display: flex;
  }

  .solution1-left {
    position: relative;
    width: 535px;
    height: 500px;
  }

  .solution1-box {
    position: absolute;
    text-align: left;
    bottom: 0;
    height: 146px;
    width: 534px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .solution1-box-title {
    padding: 0 35px 0 30px;
    position: absolute;
    top: 8px;
    font-size: 22px;
    color: #fff;
    letter-spacing: 0;
    font-weight: 400;
    width: 466px;
    overflow: hidden;
    height: 30px;
    width: 534px;
  }

  .solution1-box-content {
    padding: 0 35px 0 30px;
    position: absolute;
    top: 54px;
    line-height: 22px;
    font-size: 14px;
    color: #fff;
    opacity: 0.9;
    letter-spacing: 0;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 484px;
  }

  .solution1-top-box {
    position: absolute;
    top: 70px;
    width: 440px;
    margin-left: 40px;
  }

  .solution1-box-title2 {
    text-align: left;
    font-size: 22px;
    color: #fff;
    height: 29px;
    overflow: hidden;
  }

  .solution1-box-content2 {
    text-align: left;
    margin-top: 18px;
    font-size: 14px;
    color: #f8f8f8;
    opacity: 0.9;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 400;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .solution1-down-box {
    position: absolute;
    top: 41px;
    padding: 0 36px;
  }

  .solution1-box-title3 {
    text-align: left;
    font-size: 22px;
    color: #333333;
    font-weight: 400;
    height: 29px;
    overflow: hidden;
  }

  .solution1-box-content3 {
    margin-top: 18px;
    font-size: 14px;
    color: #333333;
    opacity: 0.8;
    text-align: justify;
    line-height: 20px;
    font-weight: 400;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .solution1-right {
    width: 1066px;
    height: 500px;
    display: flex;
    flex-wrap: wrap;
  }

  .solution1-box1 {
    position: relative;
    width: 1066px;
    height: 250px;
  }

  .solution1-box1:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .solution1-box2:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .solution1-box3:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .solution1-box2 {
    position: relative;
    width: 533px;
    height: 250px;
  }

  .solution1-box3 {
    position: relative;
    width: 533px;
    height: 250px;
  }

  .content-solution2 {
    background-color: #f5f5f5;
    width: 100%;
    height: 818px;
  }

  .content-solution3 {
    background-color: #f5f5f5;
    width: 100%;
    height: 921px;
  }

  .solution2-content {
    width: 100%;
    height: 527px;
    display: flex;
    justify-content: space-between;
  }

  .solution2-box {
    width: 526px;
    height: 520px;
    background-color: white;
  }

  .solution2-box:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -5px, 0);
    transition: transform 0.3s ease;
  }

  .solution2-img {
    height: 393px;
    width: 100%;
  }

  .solution2-text {
    margin-left: 30px;
  }

  .solution2-text-title {
    margin: 15px 0 15px 0;
    font-size: 22px;
    color: #333333;
    /* margin-bottom: 10px; */
  }

  .solution2-text-content {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22spx;
    color: #7b7b7b;
  }

  .solution3-content {
    display: flex;
    width: 100%;
    height: 630px;
  }

  .solution3-left {
    width: 1064px;
    height: 630px;
  }

  .solution3-right {
    width: 536px;
    height: 630px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  .solution3-box {
    /* background-color: pink; */
    display: flex;
    width: 532px;
    height: 205px;
    background-color: #f4f4f4;
    border-bottom: 1px solid white;
  }

  .solution3-box-hover {
    display: flex;
    width: 536px;
    height: 210px;
    background-color: white;
    border-bottom: 2px solid #90c31d;
  }

  .solution3-order {
    height: 100%;
    width: 24.6%;
    /* color: #D72523; */
    color: #7b7b7b;
    font-size: 50px;
    font-weight: bold;
    line-height: 210px;
    text-align: center;
  }

  .solution3-order-hover {
    height: 100%;
    width: 24.6%;
    color: #90c31d;
    font-size: 50px;
    font-weight: bold;
    line-height: 210px;
    text-align: center;
  }

  .box-right {
    flex: 1;
    position: relative;
  }

  .solution3-title {
    position: absolute;
    top: 60px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }

  .solution3-content2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: absolute;
    top: 95px;
    font-size: 14px;
    color: #7b7b7b;
  }

  .solution3-time {
    position: absolute;
    top: 150px;
    font-size: 13px;
    color: #7b7b7b;
  }

  .solution3-right2 {
    position: absolute;
    bottom: 0px;
    right: 30px;
  }
}

/* 小于1700时样式 */
@media screen and (max-width: 1700px) {
  .content-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title1 {
    letter-spacing: 3px;
    font-size: 15px;
    color: #7b7b7b;
  }

  .title2 {
    font-size: 33px;
    color: #333333;
  }

  .title3 {
    letter-spacing: 0px;
    line-height: 30px;
    font-size: 30px;
    color: #333333;
  }

  .swiper1 {
    width: 100%;
    height: 100%;
  }

  .content {
    margin: 0 auto;
    width: 1200px;
  }

  .content-video {
    width: 100%;
    height: 627.75px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-video-son {
    width: 1200px;
    height: 483.75px;
    display: flex;
  }

  .content-video-left {
    height: 483.75px;
    width: 766.5px;
    object-fit: cover;
    opacity: 0.92;
  }

  .content-video-right {
    position: relative;
    width: 500.25px;
    height: 100%;
    background-color: white;
  }

  .video-text1 {
    position: absolute;
    font-size: 27px;
    top: 82.5px;
    left: 30px;
    color: #333333;
  }

  .video-text2 {
    position: absolute;
    font-size: 13.5px;
    width: 384.225px;
    letter-spacing: 0.675px;
    top: 135px;
    left: 30px;
    color: #7b7b7b;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .video-text3 {
    position: absolute;
    top: 232.5px;
    left: 30px;
    color: #7b7b7b;
    display: flex;
  }

  .text-son {
    margin-top: 22.5px;
    position: relative;
    width: 127.5px;
    height: 30px;
  }

  .text-son-img {
    position: absolute;
    left: 0px;
    width: 24.75px;
    height: 24.75px;
  }

  .text-son-text1 {
    position: absolute;
    /* font-size: ; */
    top: -6px;
    left: 37.5px;
    font-size: 18.75px;
    color: #333333;
  }

  .text-son-text2 {
    position: absolute;
    top: 15px;
    left: 37.5px;
    font-size: 9px;
    color: #7b7b7b;
  }

  .video-text4 {
    position: absolute;
    font-size: 13.5px;
    bottom: 99.75px;
    left: 30px;
    color: #7b7b7b;
  }

  .video-text5 {
    position: absolute;
    font-size: 13.5px;
    bottom: 69px;
    left: 30px;
    color: #7b7b7b;
  }

  .content-sale {
    width: 100%;
    height: 897px;
  }

  .content-title {
    text-align: center;
    padding-top: 18px;
    width: 100%;
    height: 128.25px;


  }

  .content-sale-goods {
    width: 100%;
    height: 678.75px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
  }

  .sale-goods-left {
    height: 100%;
    width: 295.5px;
    display: flex;
    flex-wrap: wrap;
  }

  .sale-goods-right {
    width: 898.5px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
  }

  .goods-box1:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .goods-box2:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .goods-box3:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .goods-box4:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .goods-box1 {
    width: 295.5px;
    height: 516.75px;
  }

  .goods-box2 {
    width: 295.5px;
    height: 162px;
  }

  .goods-box3 {
    width: 597px;
    height: 336.37px;
  }

  .goods-box4 {
    width: 295.5px;
    height: 336.37px;
  }

  .content-solution1 {
    background-color: #f5f5f5;
    width: 100%;
    height: 593.25px;
  }

  .solution1-content {
    width: 100%;
    height: 375px;
    display: flex;
  }

  .solution1-left {
    position: relative;
    width: 401.25px;
    height: 375px;
  }

  .solution1-left:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .solution1-box {
    position: absolute;
    text-align: left;
    bottom: 0;
    height: 109.5px;
    width: 400.5px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .solution1-box-title {
    padding: 0 26.25px 0 26.25px;
    position: absolute;
    top: 6px;
    font-size: 16.5px;
    color: #fff;
    letter-spacing: 0;
    font-weight: 400;
    width: 349.5px;
    overflow: hidden;
    height: 22.5px;
    width: 400.5px;
  }

  .solution1-box-content {
    padding: 0 26.25px 0 26.25px;
    position: absolute;
    top: 40.5px;
    line-height: 16.5px;
    font-size: 10.5px;
    color: #fff;
    opacity: 0.9;
    letter-spacing: 0;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 363px;
  }

  .solution1-top-box {
    position: absolute;
    top: 52.5px;
    width: 330px;
    margin-left: 22.5px;
  }

  .solution1-box-title2 {
    text-align: left;
    font-size: 16.5px;
    color: #fff;
    height: 21.75px;
    overflow: hidden;
  }

  .solution1-box-content2 {
    text-align: left;
    margin-top: 13.5px;
    font-size: 10.5px;
    color: #f8f8f8;
    opacity: 0.9;
    letter-spacing: 0;
    line-height: 16.5px;
    font-weight: 400;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .solution1-down-box {
    position: absolute;
    top: 30.75px;
    padding: 0 27px;
  }

  .solution1-box-title3 {
    text-align: left;
    font-size: 16.5px;
    color: #333333;
    font-weight: 400;
    height: 21.75px;
    overflow: hidden;
  }

  .solution1-box-content3 {
    margin-top: 13.5px;
    font-size: 10.5px;
    color: #333333;
    opacity: 0.8;
    text-align: justify;
    line-height: 15px;
    font-weight: 400;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .solution1-right {
    width: 799.5px;
    height: 375px;
    display: flex;
    flex-wrap: wrap;
  }

  .solution1-box1 {
    position: relative;
    width: 799.5px;
    height: 187.5px;
  }

  .solution1-box1:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .solution1-box2:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .solution1-box3:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .solution1-box2 {
    position: relative;
    width: 399.75px;
    height: 187.5px;
  }

  .solution1-box3 {
    position: relative;
    width: 399.75px;
    height: 187.5px;
  }

  .content-solution2 {
    background-color: #f5f5f5;
    width: 100%;
    height: 613.5px;
  }

  .content-solution3 {
    background-color: #f5f5f5;
    width: 100%;
    height: 690.75px;
  }

  .solution2-content {
    width: 100%;
    height: 396px;
    display: flex;
    justify-content: space-between;
  }

  .solution2-box {
    width: 396.75px;
    height: 395.25px;
    background-color: white;
  }

  .solution2-box:hover {
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }

  .solution2-img {
    height: 296.25px;
    width: 100%;
  }

  .solution2-text {
    margin-left: 20.5px;
  }

  .solution2-text-title {
    margin: 11.25px 0 11.25px 0;
    font-size: 16.5px;
    color: #333333;
    /* margin-bottom: 10px; */
  }

  .solution2-text-content {
    font-size: 10.5px;
    letter-spacing: 0;
    line-height: 16.5px;
    color: #7b7b7b;
  }

  .solution3-content {
    display: flex;
    width: 100%;
    height: 472.5px;
  }


  .solution3-left {
    width: 801px;
    height: 472.5px;
  }

  .solution3-right {
    width: 402px;
    height: 472.5px;
    display: flex;
    flex-wrap: wrap;

  }

  .solution3-box {
    display: flex;
    width: 399px;
    height: 157.5px;
    border-bottom: 1px solid white;
    background-color: #f4f4f4;
  }

  .solution3-box-hover {
    display: flex;
    width: 402px;
    height: 157.5px;
    background-color: white;
    border-bottom: 2px solid #90c31d;
  }

  .solution3-order {
    height: 100%;
    width: 24.6%;
    /* color: #D72523; */
    color: #7b7b7b;
    font-size: 37.5px;
    font-weight: bold;
    line-height: 157.5px;
    text-align: center;
  }

  .solution3-order-hover {
    height: 100%;
    width: 24.6%;
    color: #90c31d;
    /* color: #7b7b7b; */
    font-size: 37.5px;
    font-weight: bold;
    line-height: 157.5px;
    text-align: center;
  }

  .box-right {
    flex: 1;
    position: relative;
  }

  .solution3-title {
    position: absolute;
    top: 45px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }

  .solution3-content2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: absolute;
    top: 71.25px;
    font-size: 10.5px;
    color: #7b7b7b;
  }

  .solution3-time {
    position: absolute;
    top: 112.5px;
    font-size: 9.75px;
    color: #7b7b7b;
  }

  .solution3-right2 {
    position: absolute;
    bottom: 0px;
    right: 22.5px;
  }
}

/* 小于1200px时的样式 */
@media screen and (max-width: 1200px) {
  .swiper-box {
    width: 1200px;
  }

  .swiper1 {
    width: 1200px;
    height: 100%;
  }
  .video_content{
    width: 1200px;
  }
}
</style>
