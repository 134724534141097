<template>
    <div>
        <!-- 头部导航 -->
        <nav-header></nav-header>
        <!-- 轮播图 -->
        <div v-if="rotationList.length > 0" class="swiper"
            :style="{ 'background-image': 'url(' + 'https://api.hi-wooya.com/storage/' + rotationList[0]['img'] + ')' }">
        </div>
        <!-- 图片展示与简介 -->
        <div class="content-box">
            <!-- 左边图片部分 -->
            <div class="left-box">
                <!-- 大图片 -->
                <div class="content-img">
                    <img class="img" :src="activeSrc">
                </div>
                <!-- 小图片 -->
                <div class="content-img2">
                    <img :class="{ 'img1-hover': imgid === item.id }" @mouseenter="changeimg(item.src, item.id)"
                        class="img1" :src="item.src" v-for="item in imgList" :key="item.id">
                </div>
            </div>
            <!-- 右边介绍部分 -->
            <div class="right-box">
                <div class="right-box2">
                    <!-- 标题 -->
                    <div class="title">
                        {{ productList.title }} &nbsp; {{ productList.model }}
                    </div>
                    <!-- 功能 -->
                    <div class="content">
                        {{ productList.features }}
                    </div>
                    <!-- 简介 -->
                    <div class="desc">
                        {{ productList.desc }}
                    </div>
                </div>
                <!-- 按钮 -->
                <div class="btn-box">
                    <div style="cursor: pointer;" @click="openpages('./ServiceSupport', 3)" class="btn">技术支持</div>
                    <div style="cursor: pointer;" @click="getContent('data_download', 3)" class="btn">资料下载</div>
                    <div @click="getContent('purchase', 4)" style="background-color: #90c31d;cursor: pointer;"
                        class="btn">在线购买</div>
                </div>
            </div>
        </div>
        <!-- 商品介绍部分 -->
        <!-- 导航部分 -->
        <div class="nav" :class="{ 'nav-fixed': isfixed == 1 }">
            <div class="nav-box1">
                <div :class="{ 'nav-hover': navid == item.id }" @click="getContent(item.type, item.id)" class="nav-box2"
                    v-for="(item, index) in menuList" :key="index">
                    {{ item.title }}
                </div>
            </div>
        </div>
        <!-- 占位 -->
        <div v-if="isfixed == 1" class="nav"></div>
        <div class="content-box2">
            <!-- 展示部分 -->
            <div>
                <!-- 概述 -->
                <div class="test" v-if="shwo_type == 'synopsis'" v-html="detail_content"></div>
                <!-- 规格参数 -->
                <div class="test" v-else-if="shwo_type == 'specifications'" v-html="detail_content"></div>
                <!-- 资料下载 -->
                <div v-else-if="shwo_type == 'data_download'" class="download-box">
                    <div class="download-box2">
                        <div class="download-box2-title">资料下载</div>
                        <div style="flex: 1;background-color: #EDEDED;height: 22px;"></div>
                    </div>
                    <div v-for="(item, index) in dataDownloadList" :key="index" class="download"
                        :style="{ backgroundColor: isEven(index) ? '' : '#FCFCFC' }">
                        <div style="display: flex;align-items: center;flex: 1;">
                            <img class="download-img" v-if="item.title.endsWith('f')" src="../assets/img/Pdf.png"
                                alt="">
                            <img class="download-img" v-else src="../assets/img/Zip.png">
                            <div class="hover" @click="download(item.download_url)"><span class="hover1">{{ item.title
                                    }}</span></div>
                        </div>
                        <div style="color: #7b7b7b;flex: 0.8;"> {{ deteFormat(item.create_time) }}</div>
                        <div class="downclick" @click="download(item.download_url)">
                            <i class="iconfont icon-xiazai"></i>
                            点击下载
                        </div>
                    </div>
                </div>
                <!-- 购买渠道 -->
                <div v-else class="download-box">
                    <div class="download-box2">
                        <div class="download-box2-title">在线购买</div>
                        <div style="flex: 1;background-color: #EDEDED;height: 22px;"></div>
                    </div>
                    <div v-for="(item, index) in purchaseList" :key="index" class="purchase"
                        :style="{ backgroundColor: isEven(index) ? '' : '#FCFCFC' }">
                        <div style="display: flex;align-items: center;">
                            <img class="download-img" :src="'https://api.hi-wooya.com/storage/' + item.icon" alt="">
                            <div>{{ item.title }}</div>
                        </div>
                        <div @click="goPurchase(item.purchase_url)" style="color: #7b7b7b;"> {{ item.purchase_url }}
                        </div>
                        <!-- <div @click="goPurchase(item.purchase_url)" class="downclick">点击购买</div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 页脚 -->
        <nav-footer></nav-footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            navid: '1',
            // 轮播图
            rotationList: [],
            // 图片
            activeSrc: [],
            imgid: 1,
            imgList: [],
            // 产品详情
            productList: [],
            // 下载
            dataDownloadList: [],
            // 详情部分
            detail_content: '',
            shwo_type: 'synopsis',
            // 导航
            menuList: [{
                id: 1,
                type: 'synopsis',
                title: '概述'
            }, {
                id: 2,
                type: 'specifications',
                title: '规格参数'
            }, {
                id: 3,
                type: 'data_download',
                title: '资料下载'
            }, {
                id: 4,
                type: 'purchase',
                title: '购买通道'
            }],
            purchaseList: [],
            isfixed: 0,
        }
    },
    created() {
        this.getswiper()
        this.productdetail(this.$route.query.id)
        this.getdownload(this.$route.query.id)
        this.getpurchaseList(this.$route.query.id)
    },
    mounted() {
        window.addEventListener("scroll", this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleResize);
    },
    methods: {
        //判断双数
        isEven(index) {
            return index % 2 === 0;
        },
        // 页面跳转
        openpages(path, id) {
            this.$js.openpages(path, id);
        },
        //判断滚动距离
        handleResize() {
            const scrolly = window.scrollY;
            console.log(scrolly);
            if (scrolly > 1251.7) {
                // 滚动距离大于0时的操作
                this.isfixed = 1;
            } else {
                // 滚动距离小于等于0时的操作
                this.isfixed = 0;
            }
        },
        // 获取轮播图
        async getswiper() {
            let res = await this.$apiFun.rotationList({
                classify_id: 8
            });
            this.rotationList = res.result;
        },
        // 产品详情
        async productdetail(id) {
            let res = await this.$apiFun.productDetail({
                id: id
            });
            this.productList = res.result;
            this.getImgList();
            this.getContent()
        },
        // 获取产品下载
        async getdownload(id) {
            let res = await this.$apiFun.dataDownloadList({
                classify_id: id
            });
            this.dataDownloadList = res.result;
        },
        // 获取产品购买
        async getpurchaseList(id) {
            let res = await this.$apiFun.purchaseList({
                classify_id: id
            });
            this.purchaseList = res.result.data;
        },
        //跳转下载界面
        download(e) {
            window.open('https://api.hi-wooya.com/storage/' + e, '_blank')
        },
        // 购买页面
        goPurchase(e) {
            window.open(e)
        },
        // 产品图片
        getImgList() {
            this.imgList = [{
                id: 1,
                src: 'https://api.hi-wooya.com/storage/' + this.productList.img_one,
            },
            {
                id: 2,
                src: 'https://api.hi-wooya.com/storage/' + this.productList.img_two,
            },
            {
                id: 3,
                src: 'https://api.hi-wooya.com/storage/' + this.productList.img_three,
            },
            {
                id: 4,
                src: 'https://api.hi-wooya.com/storage/' + this.productList.img_four,
            },
            ]
            this.activeSrc = 'https://api.hi-wooya.com/storage/' + this.productList.img_one
        },
        // 改变大图片
        changeimg(src, id) {
            this.activeSrc = src
            this.imgid = id
        },
        // 详情展示
        getContent(type = 'synopsis', id = 1) {
            this.shwo_type = type
            this.navid = id
            if (type == 'data_download') {

            } else {
                this.detail_content = this.productList[type]
            }
        },
        //裁剪日期
        deteFormat(value) {
            return value.substring(0, 10);
        }
    },
    watch: {
        '$route.query.id'(newValue, oldValue) {
            this.productdetail(newValue)
            this.getdownload(newValue)
            this.getpurchaseList(newValue)
        }
    },
}
</script>

<style scoped>
.swiper {
    width: 100%;
    height: 442px;
}

.downclick {
    color: #7b7b7b;
}

.hover1:hover {
    color: #90c31d;
}

@media screen and (min-width: 1700px) {
    .hover {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        width: 558.2px;
    }

    .content-box {
        width: 1600px;
        margin: 80px auto;
        display: flex;
        justify-content: space-between;
    }

    .content-box2 {
        width: 1600px;
        margin: 0px auto;
    }

    .left-box {
        width: 800px;
        height: 651px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .content-img {
        margin: 0 auto;
        width: 800px;
        height: 496px;
    }

    .content-img2 {
        height: 115px;
        width: 100%;
        display: flex;
        justify-content: space-between;

    }

    .img {
        width: 100%;
        height: 100%;
    }

    .img1 {
        width: 185px;
        height: 100%;
        border: 1px solid #dcdcdc;
    }

    .img1-hover {
        border: 1px solid #012F55;
    }

    .right-box {
        width: 734px;
        height: 650px;
    }

    .title {
        font-size: 36px;
        font-weight: bold;
    }

    .content {
        letter-spacing: 0.675px;
        font-size: 18px;
        color: #012F55;
        padding: 20px 0 20px 0;
        border-bottom: 1px solid #dcdcdc;
    }

    .desc {
        letter-spacing: 0.675px;
        font-size: 18px;
        color: #7b7b7b;
        margin-top: 20px;
    }

    .btn-box {
        width: 100%;
        display: flex;
        /* gap: 30px; */
    }

    .right-box2 {
        width: 100%;
        height: 536px;
    }

    .btn {
        width: fit-content;
        padding: 10px 48px;
        background-color: #012F55;
        border-radius: 3px;
        color: white;
        font-size: 16px;
        font-weight: bold;
        margin-right: 30px;
    }

    .nav {
        width: 100%;
        height: 60px;
        background-color: #ededed;
    }

    .nav-fixed {
        position: fixed;
        top: 80px;
    }

    .nav-box1 {
        width: 1600px;
        margin: 0 auto;
        display: flex;
        height: 100%;
    }

    .nav-box2 {
        font-size: 18px;
        line-height: 60px;
        margin-right: 20px;
        cursor: pointer;
        height: 100%;
        width: fit-content;
    }

    .nav-hover {
        color: #90c31d;
        box-sizing: border-box;
        border-bottom: 2px solid #90c31d;
    }

    .nav-box2:hover {
        color: #90c31d;
        box-sizing: border-box;
        border-bottom: 2px solid #90c31d;
    }

    .download-box {
        width: 100%;
        /* height: 300px; */
        margin: 50px 0 80px 0;
    }

    .download-box2 {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 10px;
    }

    .downclick:hover {
        color: #90c31d;
    }

    .download-box2-title {
        font-size: 25px;
        font-weight: bold;
    }

    .download {
        width: 100%;
        height: 55px;
        line-height: 40px;
        flex-direction: row;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .purchase {
        /* width: 100%; */
        padding-left: 121px;
        height: 55px;
        line-height: 40px;
        flex-direction: row;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 35px;
        /* justify-content: space-between; */
    }

    .download-img {
        width: 20px;
        height: 20px;
        /* margin-right: 4px; */
    }

    .test {
        /* width: 837px; */
        /* width: fit-content; */
        margin: 0px auto;
    }

    .test:deep(p) {
        display: block;
        width: 1600px;
        margin-block-start: 0px;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    .test:deep(img) {
        width: 100%;
    }
}

@media screen and (max-width: 1700px) {
    .content-box {
        width: 1200px;
        margin: 60px auto;
        display: flex;
        justify-content: space-between;
    }

    .content-box2 {
        width: 1200px;
        margin: 0px auto;
    }

    .left-box {
        width: 600px;
        height: 488.25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .content-img {
        margin: 0 auto;
        width: 600px;
        height: 372px;
    }

    .content-img2 {
        height: 86.25px;
        width: 100%;
        display: flex;
        justify-content: space-between;

    }

    .img {
        width: 100%;
        height: 100%;
    }

    .img1 {
        width: 138.75px;
        height: 100%;
        border: 1px solid #dcdcdc;
    }

    .img1-hover {
        border: 1px solid #012F55;
    }

    .right-box {
        width: 550.5px;
        height: 487.5px;
    }

    .title {
        font-size: 27px;
        font-weight: bold;
    }

    .content {
        letter-spacing: 0.675px;
        font-size: 13.5px;
        color: #012F55;
        padding: 15px 0 15px 0;
        border-bottom: 1px solid #dcdcdc;
    }

    .desc {
        letter-spacing: 0.675px;
        font-size: 13.4px;
        color: #7b7b7b;
        margin-top: 15px;
    }

    .btn-box {
        width: 100%;
        display: flex;
        /* gap: 22.5px; */
    }

    .right-box2 {
        width: 100%;
        height: 402px;
    }

    .btn {
        width: fit-content;
        padding: 7.5px 36px;
        background-color: #012F55;
        border-radius: 3px;
        color: white;
        font-size: 12px;
        font-weight: bold;
        margin-right: 22.5px;
    }

    .nav {
        width: 100%;
        height: 45px;
        background-color: #ededed;
    }

    .nav-fixed {
        position: fixed;
        top: 80px;
    }

    .nav-box1 {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        height: 100%;
    }

    .nav-box2 {
        font-size: 13.5px;
        line-height: 45px;
        margin-right: 15px;
        cursor: pointer;
        height: 100%;
        width: fit-content;
    }

    .nav-hover {
        color: #90c31d;
        box-sizing: border-box;
        border-bottom: 2px solid #90c31d;
    }

    .nav-box2:hover {
        color: #90c31d;
        box-sizing: border-box;
        border-bottom: 2px solid #90c31d;
    }

    .download-box {
        width: 100%;
        /* height: 225px; */
        margin: 37.5px 0 60px 0;
    }

    .download-box2 {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 7.5px;
    }

    .downclick:hover {
        color: #90c31d;
    }

    .download-box2-title {
        font-size: 18.75px;
        font-weight: bold;
    }

    .download {
        width: 100%;
        height: 41.25px;
        line-height: 30px;
        flex-direction: row;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .purchase {
        /* width: 100%; */
        padding-left: 90.75px;
        height: 41.25px;
        line-height: 30px;
        flex-direction: row;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 35px;
        /* justify-content: space-between; */
    }

    .download-img {
        width: 15px;
        height: 15px;
        /* margin-right: 3px; */
    }

    .test {
        /* width: 837px; */
        /* width: fit-content; */
        margin: 0px auto;
    }

    .test:deep(p) {
        display: block;
        width: 1200px;
        margin-block-start: 0px;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    .test:deep(img) {
        width: 100%;
    }

}

@media screen and (max-width: 1200px) {
    .swiper {
        width: 1200px;
        background-position: center;
    }

    .nav {
        width: 1200px;
    }
    .nav-fixed {
        position: fixed;
        top: 0px;
    }
}
</style>