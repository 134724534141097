<template>
  <div class="bg">
    <!-- 菜单内容 -->
    <!-- :class="isfixed ? 'menu-fixed' : 'menu'" -->
    <div :class="ismenu2 != 0 ? 'menu-fixed' : 'menu'">
      <!-- <div class="menu-fixed" v-if="ismenu2 != 0"> -->
      <div class="menu2">
        <div class="menu-flex">
          <!-- logo -->
          <div class="logo">
            <a href="http://WWW.hi-wooya.com/" title="无涯智能官网--无涯智能集研发、生产、销售于一体，主营无线联网通讯模块和设备">
              <img class="logo-img" src="../assets/img/Logo.png" alt="无涯智能官网--无涯智能集研发、生产、销售于一体，主营无线联网通讯模块和设备"
                border="0" />
            </a>
          </div>
          <!-- 菜单 -->
          <div class="box-menu" v-if="ismenu === 0">
            <div @click="openpages(item.path)" v-for="(item, index) in navList" :key="item.id"
              @mouseleave="removehover(index, $event)" @mouseover="menuhover(item.id, index)" class="menu-box"
              :class="{ 'menuhovered': item.isMenuHovered }">{{ item.name }}</div>
          </div>
          <!-- 右侧功能栏 -->
          <div>
            <!-- 搜索框 -->
            <div class="search">
              <input v-if="ismenu === 1" type="text" v-model="search_value" placeholder="请输入您要搜索的内容"
                class="search-input" />
              <!-- <img v-if="ismenu === 1" class="search-img" src="../assets/img/Search.png" alt /> -->
              <div v-if="ismenu === 1" @click="openpages('/Search', search_value)"
                class="iconfont icon-sousuo1 search-img" style="font-size:23px"></div>
              <div class="icon-style" @click="changemenu()">
                <!-- <span v-if="ismenu === 0" class="iconfont icon-sousuoxiao"></span> -->
                <div v-if="ismenu === 0" class="iconfont icon-sousuo1" style="font-size:23px"></div>
                <div v-if="ismenu === 1" class="iconfont icon-chacha" style="font-size:20px"></div>
              </div>
            </div>
            <!-- 翻译框 -->
            <div class="translation" @mouseover="translationover" @mouseleave="translationleave">
              <div class="icon-style">
                <div :class="{ 'iconfont-hover': istranslation === 1 }" class="iconfont icon-EN" style="font-size:28px">
                </div>
                <!-- 翻译下拉框 -->
                <div @click="translation()" class="combobox-right" v-if="istranslation === 1">Engelish</div>
                <!-- <span class="iconfont icon-tubiaozhizuomoban"></span> -->
                <!-- <img src="../assets/img/EN.png" alt=""> -->
              </div>
            </div>
            <!-- 购物车 -->
            <div class="shop" @mouseover="shopover" @mouseleave="shopleave">
              <div class="icon-style">
                <div :class="{ 'iconfont-hover': isshop === 1 }" class="iconfont icon-gouwuche" style="font-size:27px">
                </div>
                <!-- 购物车下拉框 -->
                <div class="combobox-right-shop" v-if="isshop === 1">
                  <a :href="item.link" class="shop-a" v-for="(item, index) in shopList" :key="index">
                    <img class="shop-img" :src="item.src" alt />
                    {{ item.name }}
                  </a>
                </div>
              </div>
            </div>
            <!-- 无涯云 -->
            <a href="http://cloud.hi-wooya.com/">
              <div class="cloud">无涯云</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 占位 -->
    <div v-if="ismenu2 != 0" style="height: 80px;"></div>
    <!-- 遮罩层 -->
    <div v-if="isshow === 1 && navid != 1 && ismenu2 != 0" @mouseenter="enteroverlay()" class="overlay"></div>
    <!-- 下拉框内容 -->
    <div v-if="isshow === 1 && navid != 1 && ismenu2 != 0" @mouseover="show()" @mouseleave="leavecombobox(navindex)">
      <!-- 产品中心下拉内容 -->
      <div class="combobox-product-fixed" v-if="navid == 2">
        <!-- 右上角叉 -->
        <div @click="changeisshow(navindex)" class="iconfont icon-chacha fork"></div>
        <div class="product-box">
          <div class="product-style">
            <div class="product-title">产品分类</div>
            <div class="product-content-box">
              <!-- 第一个 -->
              <div class="combobox-first">
                <div :class="{ 'content-first-hover': item.id === firstId }" class="content-first"
                  v-for="item in productClassifyList" :key="item.id" @click="openpages('/ProductCenter')"
                  @mouseover="getChildren(item.children, item.id)">
                  {{ item.title }}
                  <img src="../assets/img/Right.png" class="img" />
                </div>
              </div>
              <!-- 第二个 -->
              <div class="combobox-second">
                <div :class="{ 'content-second-hover': item.id === secondId }" class="content-second"
                  @mouseover="getProductList(item.id)" @click="openpages('/ProductCenter')" v-for="item in children"
                  :key="item.id">
                  {{ item.title }}
                  <img src="../assets/img/Right.png" class="img" />
                </div>
              </div>
              <!-- 第三个 -->
              <div class="combobox-third">
                <div @click="openpages('/ProductDetail', item.id)" class="content-third" v-for="item in productList"
                  :key="item.id">
                  {{ item.title }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.model }}
                  <div class="content-third-title">{{ item.desc }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 左下角联系我们 -->
          <div class="info-box" @click="openpages('/DownLoad')">
            <img class="info-img" src="../assets/img/Info.png" />
            <p class="info-text">资料下载</p>
          </div>
        </div>
      </div>
      <!-- PCBA服务下拉框 -->
      <div class="combobox-pcba-fixed" v-if="navid == 3">
        <!-- PCBA服务内容 -->
        <div v-if="navid === 3" class="pcba-style">
          <div @click="openpages(item.path, item.id, item.type, item.name)" v-for="(item, index) in pcbaList"
            :key="index" class="content-pcba">
            <div style="position: relative;width:fit-content;">
              {{ item.name }}
              <div class="iconfont icon-jinrujiantou img2"></div>
            </div>
            <!-- <img src="../assets/img/Right.png" class="img2" /> -->
            <img :src="item.src" class="pcba-img" />
          </div>
        </div>
      </div>
      <!-- 服务支持下拉内容 -->
      <div class="combobox-service-fixed" v-if="navid == 4">
        <div class="service-style">
          <!-- 右边图片内容 -->
          <div class="img-service">
            <div @click="openpages(item.path, item.id, item.type, item.name)" class="img-service-img"
              v-for="(item, index) in serviceListImg" :key="index">
              <div style="position: relative;width:fit-content;">
                {{ item.name }}
                <div class="iconfont icon-jinrujiantou img3"></div>
              </div>
              <!-- <img src="../assets/img/Right.png" class="content-service-img" /> -->
              <img class="service-img" :src="item.src" />
            </div>
          </div>
          <!-- 左边文字内容 -->
          <div class="content-service">
            <div style="height: 193px;margin-top: 25px;">
              <div @click="openpages(item.path, item.query, item.type, item.name)" class="content-service-font2"
                v-for="(item, index) in serviceListFont" :key="index">
                {{ item.name }}
                <div class="iconfont icon-jinrujiantou content-service-img"></div>
                <!-- <img src="../assets/img/Right.png" class="content-service-img" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 新闻下拉框 -->
      <div class="combobox-pcba-fixed" v-if="navid == 5">
        <!-- 新闻内容 -->
        <div v-if="navid === 5" class="pcba-style">
          <div @click="openpages(item.path, item.query, item.type, item.name)" v-for="(item, index) in newsImg"
            :key="index" class="content-pcba">
            <div style="position: relative;width:fit-content;">
              {{ item.name }}
              <div class="iconfont icon-jinrujiantou img2"></div>
            </div>

            <!-- <img src="../assets/img/Right.png" class="img2" /> -->
            <img :src="item.src" class="pcba-img" />
          </div>
        </div>
      </div>
      <!-- 联系我们下拉内容 -->
      <div class="combobox-service-fixed" v-if="navid == 6">
        <div class="service-style">
          <!-- 右边图片内容 -->
          <div class="img-service">
            <div @click="openpages(item.path, item.query, item.type, item.name)" class="img-service-img"
              v-for="(item, index) in aboutListImg" :key="index">
              <div style="position: relative;width:fit-content;">
                {{ item.name }}
                <div class="iconfont icon-jinrujiantou img3"></div>
              </div>
              <!-- <img src="../assets/img/Right.png" class="content-service-img" /> -->
              <img class="service-img" :src="item.src" />
            </div>
          </div>
          <!-- 左边文字内容 -->
          <div class="content-service">
            <div @click="openpages(item.path, item.id, item.name)" class="content-service-font"
              v-for="(item, index) in aboutListFont" :key="index">
              {{ item.name }}
              <div class="iconfont icon-jinrujiantou content-service-img"></div>
              <!-- <img src="../assets/img/Right.png" class="content-service-img" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  data() {
    return {
      // 菜单栏
      navList: [
        {
          id: 1,
          name: "首页",
          //控制菜单样式
          isMenuHovered: 0,
          path: "/"
        },
        {
          id: 2,
          name: "产品中心",
          //控制菜单样式
          isMenuHovered: 0,
          path: "/ProductCenter"
        },
        {
          id: 3,
          name: "PCBA服务",
          //控制菜单样式
          isMenuHovered: 0,
          path: "/PCBA"
        },
        {
          id: 4,
          name: "服务支持",
          //控制菜单样式
          isMenuHovered: 0,
          path: "/ServiceSupport"
        },
        {
          id: 5,
          name: "新闻中心",
          //控制菜单样式
          isMenuHovered: 0,
          path: "/NewsCenter"
        },
        {
          id: 6,
          name: "联系我们",
          //控制菜单样式
          isMenuHovered: 0,
          path: "/ContactUs"
        }
      ],
      //判断横向
      ismenu2: 1,
      firstId: 145,
      secondId: 191,
      //控制翻译下拉框
      istranslation: 0,
      //控制商店下拉框
      isshop: 0,
      // 控制第几个下拉框显示
      navindex: 0,
      navid: 0,
      // 控制下拉框是否显示
      isshow: 0,
      //控制菜单和搜索框
      ismenu: 0,
      //商店种类
      shopList: [
        {
          name: "天猫商城",
          src: require("../assets/img/TmShop.jpg"),
          link: "https://hiwooya.tmall.com/"
        },
        {
          name: "京东商城",
          src: require("../assets/img/JdShop.jpg"),
          link: "https://hiwooya.tmall.com/"
        },
        {
          name: "淘宝商城",
          src: require("../assets/img/TbShop.jpg"),
          link: "https://hi-wooya.taobao.com/"
        }
      ],
      //产品分类
      productClassifyList: [],
      //服务支持文字内容
      serviceListFont: [
        { name: "售后服务", path: '/ServiceSupport', query: '4' },
        { name: "产品定制", path: '/ServiceSupport', query: '5' },
        { name: "贴片加工", path: '/ServiceSupport', query: '7' },
        { name: "软件定制", path: '/ServiceSupport', query: '6' },
      ],
      //服务支持图片内容
      serviceListImg: [
        { name: "解决方案", src: require("../assets/img/Application.jpg"), path: '/ServiceSupport', id: 2 },
        { name: "资料下载", src: require("../assets/img/Information.jpg"), path: '/ServiceSupport', id: 1 },
        { name: "技术支持", src: require("../assets/img/Technology.jpg"), path: '/ServiceSupport', id: 3 }
      ],
      //关于我们文字内容
      aboutListFont: [
        {
          id: 4,
          name: "企业资质",
          path: '/ContactUs',
          type: 'qualifications'
        },
        {
          id: 5,
          name: "无涯团队",
          path: '/ContactUs',
          type: 'team'
        },
        {
          id: 6,
          name: "加入无涯",
          path: '/ContactUs',
        },
        {
          id: 7,
          name: "意见反馈",
          path: '/ContactUs'
        },
        {
          id: 8,
          name: "代理申请",
          path: '/ContactUs'
        }
      ],
      //关于我们图片内容
      aboutListImg: [
        {
          name: "关于无涯",
          src: require("../assets/img/CompanyIntroduction.jpg"),
          path: '/ContactUs',
          query: '1',
          type: 'wooya_real_scene'
        },
        {
          name: "关于品质", src: require("../assets/img/AboutQuality.jpg"),
          path: '/ContactUs',
          query: '2',
          type: 'quality'
        },
        {
          name: "联系我们", src: require("../assets/img/AboutUs.jpg"),
          path: '/ContactUs',
          query: '3',
          type: 'contactUs'
        }
      ],
      //新闻图片
      newsImg: [
        {
          name: "行业资讯",
          src: require("../assets/img/IndustryInformation.jpg"),
          path: '/NewsCenter',
          query: '229'
        },
        {
          name: "常见问题",
          src: require("../assets/img/Knowledge.jpg")
          ,
          path: '/NewsCenter',
          query: '231'
        },
        {
          name: "新品发布",
          src: require("../assets/img/NewProduct.jpg")
          ,
          path: '/NewsCenter',
          query: '230'
        },
        {
          name: "公司新闻",
          src: require("../assets/img/CorporateNews.jpg")
          ,
          path: '/NewsCenter',
          query: '228'
        }
      ],
      //pcba内容
      pcbaList: [
        {
          id: '1',
          name: "电子产品设计",
          src: require("../assets/img/Design.jpg"),
          path: '/PCBA'
        },
        {
          id: '2',
          name: "电子物料代购",
          src: require("../assets/img/Material.jpg"),
          path: '/PCBA'
        },
        {
          id: '3',
          name: "PCBA加工",
          src: require("../assets/img/Pcba.jpg"),
          path: '/PCBA'
        },
        {
          id: '4',
          name: "组装测试",
          src: require("../assets/img/Test.jpg"),
          path: '/PCBA'
        }
      ],
      //子类产品
      children: [],
      //产品详情
      productList: [],
      //是否固定
      isfixed: 0,
      search_value: '',
    };
  },
  created() {
    const modifiedData = this.navList.map((obj) => {
        if (obj.path === this.$route.path) {
          obj.isMenuHovered = 1;
        }
        console.log('1');
        return obj;
      });
    const productClassifyList = new Promise((resolve, reject) => {
      // 产品分类列表
      this.$apiFun.productClassifyList({}).then(res => {
        this.productClassifyList = res.result;
        this.children = this.productClassifyList[0].children;
        this.$apiFun
          .productList({
            classify_id: this.children[0].id
          })
          .then(res => {
            this.productList = res.result.data;
          });
        resolve();
      });
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleResize);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleResize);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //翻译
    translation() {
      alert('暂未开放翻译功能')
    },
    //跳转页面
    openpages(path, id, type, name) {
      this.$js.openpages(path, id, type, name);
    },
    //判断滚动距离
    handleResize() {
      const scrolly = window.scrollY;
      const screenWidth = window.innerWidth;
      if (scrolly > 0) {
        // 滚动距离大于0时的操作
        this.isfixed = 1;
      } else {
        // 滚动距离小于等于0时的操作
        this.isfixed = 0;
      }
      if (screenWidth < 1200) {
        this.ismenu2 = 0;
      } else {
        this.ismenu2 = 1;
      }
    },
    // 鼠标停留在菜单时
    menuhover(id, index) {
      this.navid = id;
      this.navindex = index;
      this.navList[index].isMenuHovered = 1;
      this.isshow = 1;
    },
    //改变菜单栏
    changemenu() {
      if (this.ismenu === 0) {
        this.ismenu = 1;
      } else {
        this.ismenu = 0;
      }
    },
    //得到子类
    getChildren(children, id) {
      this.children = children;
      this.firstId = id;
      this.$apiFun
        .productList({
          classify_id: this.children[0].id
        })
        .then(res => {
          this.productList = res.result.data;
        });
    },
    //获取商品
    getProductList(classify_id) {
      this.secondId = classify_id;
      this.$apiFun
        .productList({
          classify_id: classify_id
        })
        .then(res => {
          this.productList = res.result.data;
        });
    },
    show() {
      this.navList[this.navindex].isMenuHovered = 1;
      this.isMenuHovered = 1;
    },
    //样式控制
    removehover(index, event) {
      this.navList[index].isMenuHovered = 0;
      // this.isshow = 0
      const mouseY = event.clientY;
      if (mouseY < 79) {
        this.isshow = 0;
      }
      const modifiedData = this.navList.map((obj) => {
        if (obj.path === this.$route.path) {
          obj.isMenuHovered = 1;
        }
        return obj;
      });
    },
    //离开下拉框
    leavecombobox(index) {
      this.navList[index].isMenuHovered = 0;
      this.isshow = 0;
      const modifiedData = this.navList.map((obj) => {
        if (obj.path === this.$route.path) {
          obj.isMenuHovered = 1;
        }
        console.log('1');
        return obj;
      });
    },
    // 进入遮罩层
    enteroverlay() {
      this.isshow = 0;
    },
    //控制商店下拉框
    shopover() {
      this.isshop = 1;
    },
    shopleave() {
      this.isshop = 0;
    },
    //控制翻译下拉框
    translationover() {
      this.istranslation = 1;
    },
    translationleave() {
      this.istranslation = 0;
    },
    changeisshow(index) {
      this.navList[index].isMenuHovered = 0;
      this.isshow = 0;
    }
  }
};
</script>

<style scoped>
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.fork {
  position: absolute;
  top: 55px;
  right: 7.9vw;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  z-index: 999;
  /* 确保在上层 */
}

.fork:hover {
  color: #0e38a8;
}

.icon-style {
  width: 50px;
  height: 58px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  color: #333333;
}

.icon-sousuo1:hover {
  color: #0e38a8;
}

.iconfont-hover {
  color: #0e38a8;
}

.combobox-product-fixed {
  animation-name: slideDown;
  animation-duration: 0.8s;
  position: fixed;
  top: 80px;
  width: 100%;
  height: 690px;
  background-color: white;
  z-index: 999;
  /* 确保在上层 */
}

.product-box {
  position: relative;
  margin: auto;
  /* width: 1600px; */
  max-width: 1600px;
  width: 85%;
  /* min-width: 1200px; */
  height: 580px;
  border-bottom: 1px solid #dcdcdc;
}

.combobox-pcba {
  animation-name: slideDown;
  animation-duration: 0.8s;
  position: relative;
  background-color: white;
  width: 100%;
  height: 295px;
  z-index: 999;
  /* 确保在最上层 */
}

.combobox-pcba-fixed {
  animation-name: slideDown;
  animation-duration: 0.8s;
  position: fixed;
  top: 80px;
  width: 100%;
  background-color: white;
  height: 295px;
  z-index: 999;
  /* 确保在最上层 */
}

.combobox-service {
  animation-name: slideDown;
  animation-duration: 0.8s;
  position: relative;
  background-color: white;
  height: 295px;
  z-index: 999;
  /* 确保在最上层 */
}

.combobox-service-fixed {
  animation-name: slideDown;
  animation-duration: 0.8s;
  position: fixed;
  top: 80px;
  background-color: white;
  height: 295px;
  width: 100%;
  margin: 0 auto;
  z-index: 999;
  /* 确保在最上层 */
}

.content-service {
  position: relative;
  height: 268px;
  width: 10%;
}

.content-service-font {
  position: relative;
  /* width: 100%; */
  width: fit-content;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 400;
}

.content-service-font2 {
  position: relative;
  /* width: 100%; */
  width: fit-content;
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  font-weight: 400;
}

.content-service-font2:hover {
  color: #90c31d;
}

.content-service-font:hover {
  color: #90c31d;
}

.service-style {
  margin-top: 27px;
  margin: auto;
  /* right: 90px; */
  /* position: absolute; */
  /* height: 100%; */
  margin: 0 auto;
  height: 268px;
  width: 90%;
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
  justify-content: center;
}

.content-service-img {
  position: absolute;
  width: 20px;
  top: 0px;
  /* left: 70px; */
  right: -25px;
}

.img-service {
  /* background-color: blanchedalmond; */
  margin-top: 0px;
  height: 285px;
  /* width: 63%; */
  display: flex;
  gap: 2.45vw;
  /* gap: 35px; */
}

.img-service-img {
  flex-shrink: 0;
  width: 315px;
  height: 195px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.img-service-img:hover {
  color: #90c31d;
}

.service-img {
  position: absolute;
  top: 46px;
  left: 0px;
  width: 315px;
  height: 193px;
}

.product-title {
  height: 80px;
  padding-left: 30px;
  line-height: 130px;
  font-size: 22.94px;
  color: #333333;
}

.product-style {
  position: relative;
  margin: auto;
  width: 80%;
  height: 570px;
}

.pcba-style {
  position: relative;
  margin: auto;
  height: 100%;
  width: 80%;
  display: flex;
  gap: 2.45vw;
  justify-content: center;
}

.img2 {
  /* color: #333333; */
  width: 20px;
  position: absolute;
  top: 4px;
  /* left: -10px; */
  right: -25px;
}

.img3 {
  /* color: #333333; */
  width: 20px;
  position: absolute;
  top: 0px;
  /* left: -10px; */
  right: -25px;
}

.img2:hover {
  color: #90c31d;
}

.img3:hover {
  color: #90c31d;
}

.shop-img {
  /* vertical-align: middle; */
  width: 20px;
  height: 20px;
}

.shop-a {
  width: 70%;
  /* height: 20px; */
  line-height: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #333333;
  margin: 0px auto;
  padding: 10px 0;
  /* padding: 10px 0; */
  text-decoration: none;
  box-sizing: border-box;
}

.shop-a:hover {
  color: #0e38a8;
}

.content-pcba {
  position: relative;
  width: 315px;
  height: 260px;
  margin-top: 37px;
  font-size: 16px;
  font-weight: 400;

}

.content-pcba:hover {
  color: #90c31d;
}

.pcba-img {
  /* width: 100%; */
  width: 315px;
  height: 193px;
  /* height: 195px; */
  margin-top: 12px;
}

.product-content-box {
  /* background-color: royalblue; */
  width: 100%;
  height: 440px;
  margin-top: 20px;
  display: flex;
  /* justify-content: space-between; */
}

.combobox-first {
  max-width: 320px;
  width: 25%;
  height: 100%;
  font-size: 20px;
  border-right: 1px solid #dcdcdc;

}

.img {
  width: 20px;
  position: absolute;
  top: 15px;
  right: 36px;
}

.combobox-second {
  margin-left: 30px;
  margin-right: 30px;
  max-width: 420px;
  width: 33%;
  height: 100%;

}

.combobox-third {
  overflow: auto;
  /* overflow-x: hidden; */
  /* background-color: purple; */
  /* display: flex;  */
  /* flex-direction: column; */
  /* width: 425px; */
  max-width: 425px;
  /* width: 30%; */
  /* height: 100px; */

}

.content-first {
  position: relative;
  max-width: 290px;
  width: 87%;
  height: 55px;
  padding-left: 30px;
  line-height: 55px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}

.content-first-hover {
  background-color: #f5f5f5;
  font-weight: bold;
}

.content-second {
  position: relative;
  padding-left: 30px;
  max-width: 390px;
  width: 93%;
  height: 55px;
  line-height: 55px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.content-second-hover {
  background-color: #f5f5f5;
  font-weight: bold;
}

.info-box {
  position: absolute;
  bottom: -80px;
  left: 50px;
  display: flex;
  /* background-color: royalblue; */
  align-items: center;
}

.info-img {
  /* background-color: red; */
  width: 28px;
  height: 25px;
  margin-right: 26px;
}

.info-text {
  font-size: 19px;
  color: #333333;
  font-weight: bold;
}

.content-third {
  position: relative;
  padding-left: 30px;
  /* background-color: red; */
  /* max-width: 385px; */
  width: 385px;
  height: 80px;
  line-height: 40px;
  font-size: 16px;
  margin-top: 10px;
  color: #333333;
  border-bottom: 2px dotted #dcdcdc;
}

.content-third-title {
  /* background-color: rosybrown; */
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
  position: absolute;
  top: 35px;
  font-size: 13px;
  width: 365px;
  color: #7b7b7b;
}

.content-third:hover {
  background-color: #f5f5f5;
}

.company-box {
  flex: 1;
  height: 100%;
  width: 400px;
  margin-left: 30px;
  position: relative;
}

.menu {
  position: relative;
  height: 80px;
  width: 100%;
  margin: auto;
  background-color: #fff;
  display: flex;
  gap: 20px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.08);
  z-index: 9999;
  /* 确保在上层 */
}

.menu2 {
  width: 100%;
  height: 80px;
  /* margin-top: 10px; */
  /* padding-top: 10px; */
  background-color: #fff;
}

.menu-fixed {
  position: fixed;
  height: 80px;
  width: 100%;
  margin: auto;
  background-color: #fff;
  display: flex;
  gap: 20px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.08);
  z-index: 9999;
  /* 确保在上层 */
}

.combobox-fix {
  position: fixed;
  top: 80px;
  z-index: 9999;
  /* 确保在上层 */
}

.menu-flex {
  position: relative;
  margin: auto;
  height: 100%;
  width: 90%;
  display: flex;
}

.logo {
  position: absolute;
  height: 80px;
  left: 54px;
}

.logo-img {
  height: 80px;
}

.box-menu {
  margin: 0 auto;
  /* background-color: greenyellow; */
  width: 40%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  /* line-height: 100%; */
  /* gap: 10px; */
}

.menu-box {
  /* flex: 1; */
  height: 100%;
  /* width: 75px; */
  line-height: 80px;
  text-align: center;
  margin-left: 10px;
  white-space: nowrap;
  font-size: 18px;
  color: #333333;

}

@media screen and (max-width: 1400px) {
  .menu-box {
    height: 100%;
    line-height: 80px;
    text-align: center;
    margin-left: 10px;
    white-space: nowrap;
    font-size: 16px;
    color: #333333;

  }
}

.menuhovered {
  box-sizing: border-box;
  border-bottom: 2px solid #90c31d;
  color: #90c31d;
  cursor: pointer;
}

.search {
  position: absolute;
  right: 275px;
  top: 22px;
  color: #3333;
}

.translation {
  position: absolute;
  right: 217px;
  top: 21px;
  color: #333333;
}

.shop {
  position: absolute;
  right: 159px;
  top: 21px;
  color: #333333;
}

.combobox-right:hover {
  color: #0e38a8;
}

.combobox-right {
  position: absolute;
  font-size: 16px;
  line-height: 58px;
  top: 58px;
  left: -46px;
  background-color: white;
  width: 150px;
  height: 58px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.06);
}

.combobox-right-shop {
  position: absolute;
  font-size: 16px;
  line-height: 58px;
  padding: 10px 0;
  top: 58px;
  left: -46px;
  background-color: white;
  width: 150px;
  height: auto;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.06);
}

.cloud {
  position: absolute;
  right: 52px;
  top: 22px;
  width: 54px;
  height: 18px;
  background-color: #012f55;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  padding: 10px 12px;
  border-radius: 3px;
}

.search-input {
  position: absolute;
  right: 50px;
  top: -10px;
  height: 50px;
  border: none;
  width: 475px;
  font-size: 14px;
  padding-left: 25px;
  /* border-radius: 15px; */
  color: #aeaebb;
  border: 1px solid #b6b5b5;
}

.search-img {
  color: #333333;
  position: absolute;
  height: 20px;
  top: 8px;
  right: 70px;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* 半透明黑色背景 */
  z-index: 998;
  /* 确保在上层 */
}
</style>
