//引入刚才的http.js文件
import https from './http.js';

//设置个对象，就不用一个个暴露了，直接暴露对象
let apiFun = {};

// 产品分类列表
apiFun.productClassifyList = (params) => {
	return https.get('/productClassifyList', params)
}

// 根据型号条件查询产品信息
apiFun.getProductByModel = (params) => {
	return https.get('/getProductByModel', params)
}

// 产品列表
apiFun.productList = (params) => {
	return https.get('/productList', params)
}

// 产品详情
apiFun.productDetail = (params) => {
	return https.get('/productDetail', params)
}

// 资料下载列表
apiFun.dataDownloadList = (params) => {
	return https.get('/dataDownloadList', params)
}

// 资料下载列表 所有
apiFun.allDataDownloadList = (params) => {
	return https.get('/allDataDownloadList', params)
}

// 资料下载分类列表
apiFun.dataDownloadClassifyList = (params) => {
	return https.get('/dataDownloadClassifyList', params)
}

// 购买通道列表
apiFun.purchaseList = (params) => {
	return https.get('/purchaseList', params)
}

// 购买通道分类列表
apiFun.purchaseClassifyList = (params) => {
	return https.get('/purchaseClassifyList', params)
}

// 推荐产品分类列表
apiFun.recommendClassifyList = (params) => {
	return https.get('/recommendClassifyList', params)
}
// 根据分类id获取推荐列表
apiFun.getRecommendListByClassify = (params) => {
	return https.get('/getRecommendListByClassify', params)
}
// 首页轮播列表
apiFun.rotationList = (params) => {
	return https.get('/rotationList', params)
}
// 公司信息
apiFun.companyInfo = (params) => {
	return https.get('/companyInfo', params)
}

// 分页新闻列表
apiFun.newsList = (params) => {
	return https.get('/newsList', params)
}

// 新闻分类列表
apiFun.newsClassifyList = (params) => {
	return https.get('/newsClassifyList', params)
}

// 新闻详情
apiFun.newsDetail = (params) => {
	return https.get('/newsDetail', params)
}

// 方案列表
apiFun.schemeList = (params) => {
	return https.get('/schemeList', params)
}

// 方案详情
apiFun.schemeDetail = (params) => {
	return https.get('/schemeDetail', params)
}

// 方案分类列表
apiFun.schemeClassifyList = (params) => {
	return https.get('/schemeClassifyList', params)
}

// 获取联系我们内容
apiFun.contactUsContent = (params) => {
	return https.get('/contactUsContent', params)
}

// 获取招聘列表
apiFun.recruitList = (params) => {
	return https.get('/recruitList', params)
}

// 首页推荐新闻列表
apiFun.recommendNewsList = (params) => {
	return https.get('/recommendNewsList', params)
}

// 全局搜索
apiFun.searchAll = (params) => {
	return https.get('/searchAll', params)
}

// 全局搜索
apiFun.homeRecommends = (params) => {
	return https.get('/homeRecommends', params)
}

//暴露出这个对象
export default apiFun;
