<template>
  <div :style="contentStyle" id="test">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        this.contentStyle = {
          width: '1200px',
        };
      } else {
        this.contentStyle = {
          width: '100%',
        };
      }
    }
  },
  data() {
    return {
      contentStyle: {
        width: "100%"
      }
    };
  }
};
</script>