<template>
  <div>
    <!-- 头部导航 -->
    <nav-header></nav-header>
    <!-- 图片 -->
    <div v-if="rotationList.length > 0" class="swiper"
      :style="{ 'background-image': 'url(' + 'https://api.hi-wooya.com/storage/' + rotationList[0]['img'] + ')' }">
      <div class="swiper-title">
        联系我们
      </div>
      <div class="swiper-content">
        电话：万先生 18576787029（微信同号）
      </div>
      <div class="swiper-content">
        Email：wanliyong@hi-wooya.com
      </div>
      <!-- 导航 -->
      <div class="nav-box">
        <div  class="content-box3">
          <div style="display: flex;">
          <div class="nav" :class="{ 'nav-hover': navid == item.id }" @click="changeId(item.id, item.type,item.title)"
            v-for="item in nav_list" :key="item.id">
            {{ item.title }}
          </div>
        </div>
        <div class="nav-box3">
          <a href="/">首页</a>>联系我们>{{ this.navname }}
        </div>
        </div>
      </div>
    </div>

    <!-- 内容 -->
    <!-- 关于无涯 -->
    <div v-if="navid == 1">
      <div style="border-bottom: 1px solid #EAEAEA;">
        <div class="nav-box2">
          <div :class="{ 'box2-content-hover': navid2 == item.id }" @click="changeId2(item.id, item.type)"
            class="box2-content" v-for="item in aboutus_nav_list" :key="item.id">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div v-if="navid2 == 1">
        <div class="content-box">
          <div class="box1-title">
            工业自动化网络，联网设备的供应商
          </div>
          <div class="box1-div"></div>
          <div class="box1-content">
            无涯智能集研发、生产、销售于一体，主营无线联网通讯模块和设备，通过了 ISO9001 质量体系认证，拥有 20
            余项专利、著作权证书、认证，包括：<br>
            4G/5G工业路由网关、ProfiNet/EtherCAT/EtherNet网关、串口服务器、以太网模块、工业级以太网交换机、 WiFi/4G DTU及模组、边缘计算网关、网络IO控制器等产品。
          </div>
        </div>
      </div>
      <div v-if="navid2 == 2">
        <div class="content-box">
          <div class="box1-title">
            无涯品牌含义
          </div>
          <div class="box1-div"></div>
          <div class="box1-content">
            《庄子·内篇·养生主第三》“吾生也有涯，而知也无涯。以有涯随无涯，殆已！已而为知者，殆而已矣！为善无近名，为恶无近刑，缘督以为经，可以保身，可以全生，可以养亲，可以尽年。”<br>
            四川无涯智能科技有限公司全体成员励志愿为智慧物联网事业奋斗终生，决心在物联网的联网领域做出成绩。
          </div>
        </div>
      </div>
      <div v-if="navid2 == 3">
        <div class="content-box">
          <div class="box1-title">
            自有SMT产线
          </div>
          <div class="box1-div"></div>
          <div class="box1-content">
            国外先进设备，满足高精度生产， <br>
            设备针对中高端质量要求的产品批量生产而量身定制，突破行业技术，可贴01005，BGA间距0.2MM、QFN、CSP、CON等元器件。
          </div>
        </div>
      </div>
      <div v-html="content">
      </div>
    </div>
    <!-- 关于品质 -->
    <div v-if="navid == 2">
      <div class="content-box">
        <div class="box1-title">
          创新的思维，务实的精神开发产品
        </div>
        <div class="box1-div"></div>
        <div class="box1-content">
          雷击浪涌B等级，符合IEC61000-45，差工模2KV;<br>
          静电放电测试A等级，符合IEC61000-4-2，接触放电6KV，气隙放电8KV;<br>
          EFT电快速脉冲群测试B等级，符合IEC61000-4-4冲击电压2KV;<br>
          严格的硬件电气设计。
        </div>
      </div>
      <div v-html="content"></div>
    </div>
    <!-- 联系我们 -->
    <div v-if="navid == 3">
      <div class="content-box">
        <div class="box1-title">
          无涯能为您做什么?
        </div>
        <div class="box1-div"></div>
        <div class="box1-content">
          公司乘持“稳定可靠、简单易用、高性价比”的产品理念，<br>
          陆续推出4G/5G工业路由网关、ProfiNet/EtherCAT/EtherNet网关、串口服务器、以太网模块、工业级以太网交换机、 WiFi/4G DTU及模组、边缘计算网关、网络IO控制器等。<br>
          用联网的软件和硬件技术，助力客户实现物联网化升级，满足各种联网应用场景。
        </div>
      </div>
      <div v-html="content">
      </div>
    </div>
    <!-- 企业资质 -->
    <div v-if="navid == 4">
      <div class="content-box">
        <div class="box1-title">
          无涯品牌值得信赖
        </div>
        <div class="box1-div"></div>
        <div class="box1-content">
          公司产品通过ISO9001质量管理体系认证，拥有各类专利著作权近百项，更有工信部入网许可、CCC、CE、FCC、ROHS、KCC、WEEE、TELEC、IC、SRRC等多项权威认证。
        </div>
      </div>
      <div v-html="content">
      </div>

    </div>
    <!-- 无涯团队 -->
    <div v-if="navid == 5">
      <div class="content-box">
        <div class="box1-title">
          无涯核心团队
        </div>
        <div class="box1-div"></div>
        <div class="box1-content">
          每一天，我们都希望做得更好，不断前行，永不止步。我们相信团队协作是通向成功的保证，专注的态度则让我们更加优秀。<br>
          我们有着从业超过十年经验的软硬件工程师，也有年轻而具有活力的新生代力量。<br>
          当业界顶尖的工程师同聚一堂，那一定可以创造奇迹我们乐于接受新的挑战，我们明天会一定更好。
        </div>
      </div>
      <div v-html="content">
      </div>

    </div>
    <!-- 招聘信息 -->
    <div v-if="navid == 6">
      <div class="content-box">
        <div class="box1-title">
          热招岗位
        </div>
        <div class="box1-div"></div>
        <div class="box1-content">
          如果你：<br>
          正直诚信，勤学善思，认真创新，追求卓越，勇于担当，敢想敢拼！<br>
          请加入无涯吧！
        </div>
        <!-- 占位 -->
        <div class="whitebox"></div>
        <div class="box6-content">
          <div class="content-box2">
            <div class="son-box" style="background-color: #CED8E4;">职位名称</div>
            <div class="son-box" style="background-color: #F5F5F5;flex: 2;">岗位要求</div>
            <div class="son-box" style="background-color: #F5F5F5;">工作地点</div>
          </div>
          <div class="content-box2" v-for="item in recruit_list" :key="item.id">
            <div class="son-box2">
              <div style="background-color: #CED8E4;">{{ item.position }}</div>
            </div>
            <div class="son-box2" style="background-color: #F5F5F5;flex: 2;">{{ item.education }} , {{ item.major }} , {{
              item.date_desc }}
            </div>
            <div class="son-box2" style="background-color: #F5F5F5;">{{ item.address }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 意见反馈 -->
    <div v-if="navid == 7">
      <div class="content-box">
        <div>
          <div class="title2">尊敬的无涯物联用户：</div>
          <div class="content2">
            您好！感谢您一如既往的支持我们。<br>
            如我们有什么不足之处，提出您的宝贵建议。
          </div>
        </div>
        <div class="content2">
          <div>
            建议邮箱：support@hi-wooya.com
          </div>
        </div>
      </div>
    </div>
    <!-- 代理申请 -->
    <div v-if="navid == 8">
      <div class="content-box">
        <div>
          <div class="title2">尊敬的无涯物联用户：</div>
          <div class="content2">
            您好！如果您对我们的代理项目感兴趣，欢迎联系我们获取更多详情和合作方案。让我们携手共创美好未来，让好的产品服务更多的客户。
          </div>
        </div>
        <div class="content2">
          <a style="color: #90c31d;" href="/dl.doc" download="dl.doc"><strong>&gt;&gt;下载代理申请表单</strong></a>
        </div>
        <div class="content2">
          <div>
            渠道管理
          </div>
          <div>
            电话：18576787029 028-62485172
          </div>
          <div>
            邮箱：wanliyong@hi-wooya.com
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <nav-footer></nav-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rotationList: [],
      // 导航
      nav_list: [{
        id: 1,
        type: 'wooya_real_scene',
        title: '关于无涯'
      }, {
        id: 2,
        type: 'quality',
        title: '关于品质'
      }, {
        id: 3,
        type: 'contactUs',
        title: '联系我们'
      }, {
        id: 4,
        type: 'qualifications',
        title: '企业资质'
      }, {
        id: 5,
        type: 'team',
        title: '无涯团队',
      }, {
        id: 6,
        type: '',
        title: '加入无涯'
      },
      {
        id: 7,
        title: '意见反馈'
      }, {
        id: 8,
        title: '代理申请'
      }],
      // 导航id
      navid: '1',
      navname:'关于无涯',
      navid2: '1',
      // 关于无涯下方导航
      aboutus_nav_list: [{
        id: 1,
        title: '公司实景',
        type: 'wooya_real_scene'
      }, {
        id: 2,
        title: '员工风采',
        type: 'wooya_features'
      }, {
        id: 3,
        title: '生产环境',
        type: 'wooya_environment'
      }],
      // 富文本内容
      content: '',
      // 招聘信息
      recruit_list: []
    }
  },
  created() {
    this.getswiper();
    this.getContent('wooya_real_scene');
    this.getPeople();
    this.navid = this.$route.query.id || 1
    this.changeId(this.$route.query.id, this.$route.query.type,this.$route.query.name)
  },
  methods: {
    // 获取相应富文本内容
    async getContent(type) {
      let res = await this.$apiFun.contactUsContent({ type });
      this.content = res.result.content;
    },
    // 获取招聘信息
    async getPeople() {
      let res = await this.$apiFun.recruitList({});
      this.recruit_list = res.result;
    },
    // 获取轮播图
    async getswiper() {
      let res = await this.$apiFun.rotationList({
        classify_id: 6
      });
      this.rotationList = res.result;
    },
    changeId(id, type,name) {
      this.navid = id ? id : 1
      this.navname = name ? name:'关于无涯'
      if (this.navid2 != 1) {
        this.navid2 = 1
      }
      if (type) {
        this.getContent(type)
      }
    },
    changeId2(id, type) {
      this.navid2 = id
      this.getContent(type)
    }
  },
  watch: {
    '$route.query.id'(newValue, oldValue) {
      this.changeId(newValue, this.$route.query.type)
      this.navname =this.$route.query.name
    }
  },
};
</script>

<style scoped>

a{
  color: white;
}
.swiper {
  position: relative;
  width: 100%;
  height: 442px;
  /* padding-left: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}


.swiper-title {
  margin-left: 300px;
  width: fit-content;
  font-size: 32px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

.swiper-content {
  margin-left: 300px;
  width: fit-content;
  font-size: 15px;
  color: white;
  margin-bottom: 10px;
}

.nav-box {
  background-color: rgba(0, 0, 0, 0.337);
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
}

.content-box3 {
  display: flex;
  width: 1600px;
  margin: 0 auto;
  justify-content: space-between;
}

.nav {
  height: 60px;
  color: white;
  font-size: 18px;
  line-height: 60px;
  padding: 0 25px;
  cursor: pointer;
  /* width: 72px; */
}

.nav-box3 {
  color: white !important;
  font-size: 18px;
  line-height: 60px;
  height: 100%;
  padding-right: 10px;
}

.nav-hover {
  background-color: #90c31d;
}

.nav:hover {
  background-color: #90c31d;
}

/* 大于1700时样式 */
@media screen and (min-width: 1700px) {
  
  .content-box {
  width: 1600px;
  margin: 80px auto;
}
  .box1-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.9px;
}

.box1-div {
  width: 100px;
  height: 3px;
  margin: 40px 0;
  background-color: #90c31d;
}

.box1-content {
  font-size: 16px;
  color: #7b7b7b;
  /* width: 1095px; */
  letter-spacing: 0.9px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.nav-box2 {
  width: 1600px;
  height: 80px;
  display: flex;
  margin: 0 auto;
}

.box2-content {
  height: 100%;
  line-height: 80px;
  padding: 0px 25px;
  cursor: pointer;
  box-sizing: border-box;
}

.box2-content:hover {
  color: #90c31d;
  border-bottom: 2px solid #90c31d;
}

.box2-content-hover {
  color: #90c31d;
  border-bottom: 2px solid #90c31d;
}

.box6-title1 {
  font-size: 20px;
  color: #7b7b7b;
  text-align: center;
  letter-spacing: 3px;
}

.box6-title2 {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}

.box6-title3 {
  letter-spacing: 0px;
  line-height: 40px;
  font-size: 40px;
  text-align: center;
}

.box6-content {
  width: 100%;
}

.content-box2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5px 0 5px 0px;
  gap: 5px;
}

.son-box {
  flex: 1;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.son-box2 {
  flex: 1;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  text-align: center;
}

.whitebox {
  width: 100%;
  height: 60px;
}

.title2 {
  font-size: 18px;
  font-weight: bold;
}

.content2 {
  width: 1000px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #7b7b7b;
}

:deep(p) {
  display: block;
  width: 1600px;
  margin: 0 auto;
  margin-bottom: 80px;
}

:deep(img) {
  width: 100%;
}
}

/* 小于1700时样式 */
@media screen and (max-width: 1700px) {
  .content-box {
  width: 1200px;
  margin: 60px auto;
}

.box1-title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.675px;
}

.box1-div {
  width: 75px;
  height: 2.25px;
  margin: 30px 0;
  background-color: #90c31d;
}

.box1-content {
  font-size: 12px;
  color: #7b7b7b;
  letter-spacing: 0.675px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.nav-box2 {
  width: 1200px;
  height: 60px;
  display: flex;
  margin: 0 auto;
}

.box2-content {
  height: 100%;
  line-height: 60px;
  padding: 0px 18.75px;
  cursor: pointer;
  box-sizing: border-box;
}

.box2-content:hover {
  color: #90c31d;
  border-bottom: 2px solid #90c31d;
}

.box2-content-hover {
  color: #90c31d;
  border-bottom: 2px solid #90c31d;
}

.box6-title1 {
  font-size: 15px;
  color: #7b7b7b;
  text-align: center;
  letter-spacing: 2.25px;
}

.box6-title2 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.box6-title3 {
  letter-spacing: 0px;
  line-height: 30px;
  font-size: 30px;
  text-align: center;
}

.box6-content {
  width: 100%;
}

.content-box2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 3.75px 0 3.75px 0px;
  gap: 3.75px;
}

.son-box {
  flex: 1;
  height: 45px;
  line-height: 45px;
  font-weight: bold;
  font-size: 13.5px;
  text-align: center;
}

.son-box2 {
  flex: 1;
  height: 45px;
  line-height: 45px;
  font-size: 12px;
  text-align: center;
}

.whitebox {
  width: 100%;
  height: 45px;
}

.title2 {
  font-size: 13.5px;
  font-weight: bold;
}

.content2 {
  width: 750px;
  margin-top: 7.5px;
  margin-bottom: 15px;
  font-size: 13.5px;
  color: #7b7b7b;
}

:deep(p) {
  display: block;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 60px;
}

:deep(img) {
  width: 100%;
}
}
@media screen and (max-width: 1200px) {
    .swiper {
        width: 1200px;
        background-position: center;
    }
}

</style>


