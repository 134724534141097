import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router
Vue.use(Router)
import index from '../pages/index.vue'
import ProductCenter from '../pages/ProductCenter.vue'
import PCBA from '../pages/PCBA.vue'
import ServiceSupport from '../pages/ServiceSupport.vue'
import NewsCenter from '../pages/NewsCenter.vue'
import ContactUs from '../pages/ContactUs.vue'
import ProductDetail from '../pages/ProductDetail.vue'
import Download from '../pages/Download.vue'
import NewsDetail from '../pages/NewsDetail.vue'
import Search from '../pages/Search.vue'
import SolutionDetail from '../pages/SolutionDetail.vue'

// 路由重复点击报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default new Router({
	mode: 'history',
    routes: [ // 定义路由信息
        {
            path: '/',
            name: 'index',
            component: index,
        },
        {
            path: '/ProductCenter',
            name: 'ProductCenter',
            component: ProductCenter,
        },
        {
            path: '/PCBA',
            name: 'PCBA',
            component: PCBA,
        },
        {
            path: '/ServiceSupport',
            name: 'ServiceSupport',
            component: ServiceSupport,
        },
        {
            path: '/NewsCenter',
            name: 'NewsCenter',
            component: NewsCenter,
        },
        {
            path: '/ContactUs',
            name: 'ContactUs',
            component: ContactUs,
        },
        {
            path: '/ProductDetail',
            name: 'ProductDetail',
            component: ProductDetail,
        },
        {
            path: '/Download',
            name: 'Download',
            component: Download,
        },
        {
            path: '/NewsDetail',
            name: 'NewsDetail',
            component: NewsDetail,
        },
        {
            path: '/Search',
            name: 'Search',
            component: Search,
        },
        {
            path: '/SolutionDetail',
            name: 'SolutionDetail',
            component: SolutionDetail,
        },
    ],
	//配置滚动行为，跳转到新的路由界面滚动条的位置
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	}
})