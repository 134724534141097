<template>
  <div class="bg">
    <!-- 头部导航 -->
    <nav-header></nav-header>
    <!-- 轮播图 -->
    <div class="swiper-box">
      <el-carousel :interval="3000" arrow="hover" :height="440 + 'px'">
        <!-- :height="bannerHeight + 'px'" -->
        <el-carousel-item v-for="item in recommend_list" :key="item.id">
          <a :href="item.link">
            <div class="swiper-box2"
              :style="{ 'background-image': 'url(' + 'https://api.hi-wooya.com/storage/' + item.img + ')' }"></div>
            <!-- <img style="width: 100%;height: 100%;" :src="'https://api.hi-wooya.com/storage/' + item.img" /> -->
          </a>
        </el-carousel-item>
      </el-carousel>
      <!-- 产品分类内容 -->
      <div class="classification1">
        <div class="classification" @mouseleave="mouseleave()">
          <!-- 第一部分 -->
          <div class="classification-box">

            <div @mouseenter="getChildren(item.children, item.id)" v-for="item in productClassifyList" :key="item.id"
              :class="{ 'title-hover': item.id == istitle }" class="title">
              <div style="margin-left: 25px;">{{ item.title }}</div>
              <div class="iconfont icon-jinrujiantou img3"></div>
            </div>
          </div>
          <!-- 第二部分 -->
          <div v-if="isbox2 === 1" class='allclassification'>
            <div @mouseenter="getproduct(item.id)" @click="getproduct3(), getproduct2(item.id)" class="content"
              v-for="item in children" :key="item.id">
              <div style="margin-left: 25px;">
                {{ item.title }}
              </div>
              <div class="iconfont icon-jinrujiantou img4"></div>
            </div>
          </div>
          <!-- 第三部分 -->
          <div v-if="isbox2 === 1" class="productList">
            <div @click="openpages('/ProductDetail', item.id)" style="width: fit-content;" class="content3"
              v-for="item in productList2" :key="item.id">
              <div style="margin-left: 25px;">
                {{ item.title }}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.model }}
                <div class="third-desc">{{ item.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品具体内容 -->
    <div class="product">
      <div @click="openpages('/ProductDetail', item.id)" class="product-box" v-for="item in displayedBoxes"
        :key="item.id">
        <img :src="'https://api.hi-wooya.com/storage/' + item.img_cover" class="img" />
        <div class="detail">
          <div class="detail-name">{{ item.title }}</div>
          <div class="detail-type">型号：{{ item.model }}</div>
        </div>
      </div>
    </div>
    <!-- 分页按钮 -->
    <div class="pagination-box">
      <button class="pagination" @click="goToPreviousPage" :disabled="currentPage === 1">
        上一页
      </button>
      <span v-for="page in pages" :key="page">
        <button @mouseleave="changepage2()" @mouseenter="changepage(page)" @click="goToPage(page)" class="pagination2"
          :class="{ 'pagination-active': page === currentPage, 'pagination2-hover': page == pages2 }">{{ page
          }}</button>
      </span>
      <button class="pagination" @click="goToNextPage" :disabled="currentPage === totalPages">
        下一页
      </button>
    </div>
    <!-- 页脚 -->
    <nav-footer></nav-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //轮播图高度
      bannerHeight: 0,
      //屏幕高度
      screenWidth: 0,
      recommend_list: [],
      // 产品分类
      productClassifyList: [],
      Children1: [],
      Children2: [],
      //产品
      productList: [],
      productList2: [],
      children: [],
      boxWidth: 0,
      isclass: 0,
      isbox2: 0,
      istitle: 0,
      // 分页内容
      // 当前页数
      currentPage: 1,
      pages2: 0,
      // 一页展示几个
      itemsPerPage: 20
    };
  },
  created() {
    this.getswpier();
    this.getproductclassification();
    this.getproduct();
  },
  mounted() {
  },
  computed: {
    // 分页内容
    totalPages() {
      return Math.ceil(this.productList.length / this.itemsPerPage);
    },
    displayedBoxes() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.productList.slice(startIndex, endIndex);
    },
    pages() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    // 页数加
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    // 页数减
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    mouseleave() {
      this.isbox2 = 0
      this.istitle = 0
    },
    // 页面跳转
    openpages(path, id) {
      this.$js.openpages(path, id);
    },
    async getswpier() {
      //获取轮播图
      let res = await this.$apiFun.rotationList({
        classify_id: 2
      });
      this.recommend_list = res.result;
    },
    async getproductclassification() {
      //获取产品分类
      let res = await this.$apiFun.productClassifyList({});
      this.productClassifyList = res.result;
      this.children = this.productClassifyList.reduce(
        (accumulator, currentValue) => {
          return accumulator.concat(currentValue.children);
        },
        []
      );
    },
    async getproduct(classify_id) {
      //获取产品
      let res = await this.$apiFun.productList({
        classify_id: classify_id ? classify_id : ""
      });
      this.productList2 = res.result.data;
      if (this.productList.length < 1) {
        this.productList = res.result.data;
      }
    },
    getproduct3() {
      this.productList = this.productList2
    },
    async getproduct2(classify_id) {
      //获取产品
      let res = await this.$apiFun.productList({
        classify_id: classify_id
      });
      this.productList2 = res.result.data;
    },
    //得到子类
    async getChildren(children, id) {
      this.children = children;
      this.isbox2 = 1;
      this.istitle = id;
      let res = await this.$apiFun.productList({
        classify_id: this.children[0].id
      });
      this.productList2 = res.result.data;
      // this.$apiFun
      //   .productList({
      //     classify_id: this.children[0].id
      //   })
      //   .then(res => {
      //     this.productList2 = res.result.data;
      //   });
    },
    changepage(page) {
      this.pages2 = page
    },
    changepage2() {
      this.pages2 = 0
    },
    // getAllChildren() {
    //   this.children = this.productClassifyList.reduce(
    //     (accumulator, currentValue) => {
    //       return accumulator.concat(currentValue.children);
    //     },
    //     []
    //   );
    //   this.getproduct();
    //   this.isbox2 = 1
    // }
  }
};
</script>

<style scoped>
.swiper-box2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.img3 {
  width: 20px;
  position: absolute;
  right: 25px;
  top: 0px;
}

.img4 {
  width: 20px;
  position: absolute;
  right: 0px;
  top: 14px;
}

.bg {
  background-color: #f5f5f5;
  /* height: 100%; */
}

.swiper-box {
  position: relative;
  width: 100%;
  height: auto;
}



/* 大于1700时样式 */

@media screen and (min-width: 1700px) {
  .content3 {
    position: relative;
    padding: 10px 0 10px 0;
    /* width: 135px; */
    font-size: 16px;
    color: #333333;
    margin-left: 25px;
    /* margin: 0 auto; */
    cursor: pointer;
    border-bottom: 2px dotted #dcdcdc;
  }

  .content3:hover {
    color: #90c31d;
  }

  .third-desc {
    font-size: 13px;
    width: 820px;
    color: #7b7b7b;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .classification1 {
    position: absolute;
    top: 47px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    z-index: 997;
  }

  .classification {
    /* position: absolute; */
    /* top: 47px; */
    /* left: 7.81vw; */
    margin: 0 auto;
    /* float: left; */
    display: flex;
    width: 1600px;
    /* width: 84.4%; */
  }

  .classification-box {
    width: 230px;
    height: 345px;
    display: flex;
    flex-direction: column;
    background-color: #EDEDED;
  }

  .allclassification {
    padding-top: 5px;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 9;
    overflow: auto;
    background-color: white;
  }

  .allclassification2 {
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 0px;
    overflow: hidden;
  }

  .title {
    position: relative;
    width: 100%;
    height: 57.5px;
    font-size: 18px;
    color: #333333;
    line-height: 57.5px;
    margin: 0 auto;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 1px solid white;
  }

  .title:hover {
    color: #90c31d;
  }

  .productList {
    display: flex;
    height: 345px;
    overflow: auto;
    flex: 2;
    flex-direction: column;
    /* flex-wrap: wrap; */
    background-color: white;
  }

  .content {
    position: relative;
    padding: 10px 0 10px 0;
    width: 230px;
    font-size: 16px;
    color: #333333;
    margin: 0 auto;
    cursor: pointer;
  }


  .content:hover {
    color: #90c31d;
    background-color: #ffffff;
  }

  .product {
    display: flex;
    flex-wrap: wrap;
    margin: 20px auto;
    width: 1600px;
    cursor: pointer;
  }

  .product-box {
    width: 304px;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .product-box:nth-child(5n) {
    margin-right: 0;
  }

  .img {
    width: 100%;
    height: auto;
  }

  .detail-name {
    color: #333333;
    text-align: center;
    font-size: 11.25;
  }

  .detail-type {
    color: #7b7b7b;
    text-align: center;
    font-size: 9.75;
  }

}

/* 小于1700时样式 */
@media screen and (max-width: 1700px) {

  .classification1 {
    position: absolute;
    top: 47px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    z-index: 997;
  }

  .classification {
    /* position: absolute; */
    /* top: 47px; */
    /* left: 7.81vw; */
    margin: 0 auto;
    /* float: left; */
    display: flex;
    width: 1200px;
    /* width: 84.4%; */
  }

  .classification-box {
    width: 230px;
    height: 345px;
    display: flex;
    flex-direction: column;
    background-color: #EDEDED;
  }

  .allclassification {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 9;
    overflow: auto;
    background-color: white;
  }

  .allclassification2 {
    margin-left: 7.5px;
    display: flex;
    flex-wrap: wrap;
    width: 0px;
    overflow: hidden;
  }

  .title {
    position: relative;
    width: 100%;
    height: 57.5px;
    font-size: 18px;
    color: #333333;
    line-height: 57.5px;
    margin: 0 auto;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 1px solid white;
  }

  .title-hover {
    color: #90c31d;
    background-color: #ffffff;
  }

  .content {
    position: relative;
    padding: 10px 0 10px 0;
    width: 230px;
    font-size: 16px;
    color: #333333;
    margin: 0 auto;
    margin-top: 5px;
    cursor: pointer;
    overflow: auto;
  }

  .content3 {
    position: relative;
    padding: 10px 0 10px 0;
    /* width: 135px; */
    font-size: 16px;
    color: #333333;
    margin-left: 25px;
    /* margin: 0 auto; */
    cursor: pointer;
    border-bottom: 2px dotted #dcdcdc;
  }

  .content3:hover {
    color: #90c31d;
  }

  .productList {
    display: flex;
    height: 345px;
    flex: 2;
    flex-direction: column;
    /* flex-wrap: wrap; */
    overflow: auto;
    background-color: white;
  }

  .third-desc {
    font-size: 13px;
    width: 545px;
    color: #7b7b7b;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .content:hover {
    color: #90c31d;
  }



  .product {
    display: flex;
    flex-wrap: wrap;
    margin: 15px auto;
    width: 1200px;
    cursor: pointer;
  }

  .product-box {
    width: 228px;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .product-box:nth-child(5n) {
    margin-right: 0;
  }

  .img {
    width: 100%;
    height: auto;
  }

  .detail-name {
    font-size: 15px;
    color: #333333;
    text-align: center;
  }

  .detail-type {
    font-size: 13px;
    color: #7b7b7b;
    text-align: center;
  }
}
@media screen and (max-width: 1200px) {
  .swiper-box {
    width: 1200px;
  }
  .bg {
    width: 1200px;
}
}
</style>
