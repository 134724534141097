import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
// 引入全局样式
import "../src/assets/css/common.css"
// api接口内容
import apiFun from "./assets/js/api";
//全局js
import method from "./assets/js/method";
//阿里图标
import  "./assets/icon/iconfont.css";


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


// 公共組件
import NavHeader from './components/NavHeader.vue'
import NavFooter from './components/NavFooter.vue'
Vue.component('nav-header', NavHeader)
Vue.component('nav-footer', NavFooter)


Vue.prototype.$apiFun = apiFun; //请求接口 api
Vue.prototype.$js = method; //公共工具类


Vue.config.productionTip = false

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
